import * as React from 'react';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../../Utilities/ResponsiveTable';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import authService from '../../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../../Contexts/TokenRefreshContext';
import TableHead from '@mui/material/TableHead';
import ChangeHistoryTableRow from './ChangeHistoryTableRow';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ChangeLogViewmodel from '../Viewmodels/ChangeLogViewmodel';
 
interface IProps {
    assetId: number;
    open: boolean;
    onClose: () => void;
}

export default function ChangeHistoryTable(props: IProps) {
    const { assetId, open, onClose } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [results, setResults] = React.useState<ChangeLogViewmodel[]>([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (assetId > 0) {
            getData();
        }
    }, [assetId]);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        await crabFetch(`Asset/GetChangeLogs?id=${assetId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setResults(data);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle sx={{ m: 0, p: 2 }}>{"Change Log"}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TableContainer>
                            <ResponsiveTable
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label="change history table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Changed By</TableCell>
                                        <TableCell>Given Description/Reason</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(!loading && results) && results.map((row: ChangeLogViewmodel) => {
                                        return (<ChangeHistoryTableRow key={row.id} row={row} />);
                                    })}
                                    {loading &&
                                        <TableRow sx={{ height: 53 }}>
                                            <TableCell colSpan={2} align="center"><CircularProgress color="primary" /></TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </ResponsiveTable>
                        </TableContainer>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}