import AmmentmentAndDocumentHistoryViewModel from "./AmmentmentAndDocumentHistoryViewModel";
import CredibleDamageViewModel from "./CredibleDamageViewModel";
import FileViewModel from "./FileViewModel";
import SubmitInspectionsAndPreparationsViewModel from "./SubmitInspectionsAndPreparationsViewModel";

export default class UpdateWseViewModel {
    public wseId: number |null = null;
    public assetId: number = 0;
    public history: AmmentmentAndDocumentHistoryViewModel[] = []
    public inspection: SubmitInspectionsAndPreparationsViewModel = new SubmitInspectionsAndPreparationsViewModel();
    public damageMechanisms: CredibleDamageViewModel[] = [];
    public files: FileViewModel[] = [];

}