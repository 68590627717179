import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import { AlertContext } from '../Contexts/AlertContext';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';
import CloseDialogConfirmation from '../Utilities/CloseDialogConfimation';
import TextField from '@mui/material/TextField';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    id: number;
}

export default function CreatePlant(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose, id } = props;
    const [loading, setLoading] = React.useState(false);
    const [clients, setClients] = React.useState<DropdownViewModel[]>([]);
    const [clientId, setClientId] = React.useState<number>(id);
    const [idIndex, setIdIndex] = React.useState<number>(0);
    const [clientUsers, setClientUsers] = React.useState<DropdownViewModel[]>([]);
    const [openConfimation, setOpenConfimation] = React.useState(false);

    React.useEffect(() => {
        getData();
        setClientId(id);
    }, [id]);

    React.useEffect(() => {
        if (clientId !== 0) {
            getClientUsers();
        }
    }, [clientId]);

    const getClientUsers = async () => {
        const token = await authService.getAccessToken();
        crabFetch(`User/GetClientsDropDown?clientId=${clientId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setClientUsers(data);
            },
            () => {
                setLoading(false);
            });
    }

    const getData = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Client/GetClientsDropDown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setIdIndex(data.findIndex(w => w.id === id) + 1);
                setLoading(false);
                setClients(data);
            },
            () => {
                setLoading(false);
            });
    }

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();
        let url = 'Plant/CreatePlant';

        crabFetch(url, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully created plant");
                    onClose(true);
                }
            },
            (error: any) => {
                show('error', error);
            }
        );
    }

    const closeDialogs = (refresh: boolean) => {
        if (refresh)
            onClose(false);
        setOpenConfimation(false);
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="sm"
            fullWidth
        >
            <FormContainer
                onSuccess={submit}
                defaultValues={{
                    clientId: idIndex
                }}
            >
                <DialogTitle>{"Create Plant"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="clientId" shrink>Client</InputLabel>
                                <SelectElement defaultValue={clientId} options={clients} onChange={(e) => setClientId(e)} name="clientId" required fullWidth size="small" />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="name" shrink>Plant Name</InputLabel>
                                <TextFieldElement name="name" required fullWidth size="small" />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="address" shrink>Plant Address</InputLabel>
                                <TextFieldElement name="address" required fullWidth size="small" />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="siteName" shrink>Site Name</InputLabel>
                                <TextFieldElement name="siteName" required fullWidth size="small" />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="siteAddress" shrink>Site Address</InputLabel>
                                <TextFieldElement name="siteAddress" required fullWidth size="small" />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>

                            <Grid item xs={6}>
                                <InputLabel htmlFor="nominatedEngineerId" shrink>Client Nominated Engineer</InputLabel>
                                {clientId === 0 || clientUsers.length! > 0 ?
                                    <SelectElement disabled={clientId === 0} options={clientUsers} name="nominatedEngineerId" fullWidth size="small" />
                                    :
                                    <TextField disabled={true} name="nominatedEngineerId" defaultValue="No Engineers Assigned To Client" fullWidth size="small" />
                                }
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="deputyEngineerId" shrink>Client Deputy Engineer</InputLabel>
                                {clientId === 0 || clientUsers.length !> 0 ?
                                    <SelectElement disabled={clientId === 0} options={clientUsers} name="deputyEngineerId" fullWidth size="small" />
                                    :
                                    <TextField disabled={true} name="deputyEngineerId" defaultValue="No Engineers Assigned To Client" fullWidth size="small" />
                                }

                            </Grid>
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setOpenConfimation(true)} color="error">Cancel</Button>
                        <Button disabled={loading} variant="contained" type="submit">Save Plant</Button>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
            <CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'Are you sure you want to discard any changes?'} open={openConfimation} onClose={closeDialogs}></CloseDialogConfirmation>

        </Dialog>
    );
}