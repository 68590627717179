import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../../Utilities/ResponsiveTable';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import authService from '../../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../../Contexts/TokenRefreshContext';
import PermissionCheck from '../../Utilities/PermissionCheck';
import { Permission } from '../../Permissions/Permissions';
import TableHead from '@mui/material/TableHead';
import ComponentViewmodel from '../Viewmodels/ComponentViewmodel';
import ComponentTableRow from './ComponentTableRow';
import CreateComponent from './CreateComponent';
import EditComponent from './EditComponent';

interface IProps {
    assetDetailId: number;
    assetType: number;
    showbuttons: boolean;
    refreshData: () => void;
}

export default function ComponentTable(props: IProps) {
    const { assetDetailId, assetType, showbuttons, refreshData } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [results, setResults] = React.useState<ComponentViewmodel[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [openAdd, setOpenAdd] = React.useState(false);
    const [edit, setEdit] = React.useState<number | null>(null);

    React.useEffect(() => {
        if (assetDetailId > 0) {
            getData();
        }
    }, [assetDetailId]);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        await crabFetch(`Component/GetComponentsTable?assetDetailId=${assetDetailId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setResults(data);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const closeAdd = (refresh: boolean) => {
        setOpenAdd(false);

        if (refresh) {
            getData();
            refreshData();
        }
    }

    const closeEdit = (refresh: boolean) => {
        setEdit(null);

        if (refresh) {
            getData();
            refreshData();
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>

                <TableContainer>
                    <ResponsiveTable
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="component table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Component Name</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(!loading && results) && results.map((row: ComponentViewmodel) => {
                                return (<ComponentTableRow key={row.id} row={row} edit={setEdit} refresh={getData} showButtons={showbuttons} />);
                            })}
                            {loading &&
                                <TableRow sx={{ height: 53 }}>
                                    <TableCell colSpan={2} align="center"><CircularProgress color="primary" /></TableCell>
                                </TableRow>
                            }
                            {results.length <= 0 && !loading &&
                                <TableRow
                                    sx={{
                                        height: 53
                                    }}
                                >
                                    <TableCell colSpan={3} align="center">No Components Added</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </ResponsiveTable>
                </TableContainer>
            </Grid>
            <Grid item>
                <PermissionCheck permission={Permission.CreateAssets}>
                    {showbuttons &&
                        <Grid item>
                            <Button aria-label={"Add Component"} onClick={() => setOpenAdd(true)} variant="contained" color="primary">Add Component</Button>
                        </Grid>
                    }
                </PermissionCheck>
            </Grid>
            {openAdd &&
                <PermissionCheck permission={Permission.CreateAssets}>
                    <CreateComponent open={openAdd} onClose={closeAdd} assetDetailId={assetDetailId} assetType={assetType} />
                </PermissionCheck>
            }
            {edit &&
                <PermissionCheck permission={Permission.CreateAssets}>
                    <EditComponent open={edit > 0} onClose={closeEdit} id={edit} assetType={assetType} />
                </PermissionCheck>
            }
        </Grid>
    );
}