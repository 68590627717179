import * as React from 'react';
import { Control, Controller } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import InspectionReportViewmodel from './Viewmodels/InspectionReportViewmodel';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { formats, modules } from '../Utilities/RichTextConfig';

interface IProps {
    editing: boolean;
    control: Control<InspectionReportViewmodel, any>;
}

export default function ExtentLimitations(props: IProps) {
    const { editing, control } = props;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <InputLabel htmlFor="description" shrink>Description of Inspection</InputLabel>
                <InputLabel htmlFor="description" shrink>(Type, supporting NDT etc)</InputLabel>
                <Controller
                    control={control}
                    name="description"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactQuill
                            modules={modules}
                            formats={formats}
                            onChange={onChange} // send value to hook form
                            onBlur={onBlur} // notify when input is touched/blur
                            readOnly={!editing}
                            value={value}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <InputLabel htmlFor="limitations" shrink>Limitations to the Inspection</InputLabel>
                <InputLabel htmlFor="limitations" shrink>State reasons (areas inaccessible, etc)</InputLabel>
                <Controller
                    control={control}
                    name="limitations"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactQuill
                            modules={modules}
                            formats={formats}
                            onChange={onChange} // send value to hook form
                            onBlur={onBlur} // notify when input is touched/blur
                            readOnly={!editing}
                            value={value}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
}