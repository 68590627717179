import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import TextField from '@mui/material/TextField';
import { InputLabel } from '@mui/material';
import WseTopperViewModel from './Viewmodels/WseTopperViewModel';

interface IProps {
    wseId: number;
}

export default function WseTopper(props: IProps) {
    const { wseId } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [wseTopper, setWseTopper] = React.useState<WseTopperViewModel>(new WseTopperViewModel());

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();
        await crabFetch(`Wse/GetWseTopper?wseId=${wseId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setWseTopper(data);
            },
            () => {
                //fail
            }
        );
    }

    return (
        <Paper sx={{ padding: 2 }}>
            <Grid container spacing={2}>
                <Grid item container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <InputLabel>Plant Item Number</InputLabel>
                        <TextField disabled={true} size="small" fullWidth value={wseTopper.plantItemNumber}></TextField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputLabel>WSE Number</InputLabel>
                        <TextField disabled={true} size="small" value={wseTopper.wseNumber } fullWidth></TextField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputLabel>Revision Number</InputLabel>
                        <TextField disabled={true} size="small" value={wseTopper.revisionNumber} fullWidth></TextField>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}