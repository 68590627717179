import * as React from 'react';
import SortableTableHead, { HeadCell, Order } from '../Utilities/SortableTableHead';
import TechLibViewModel from './Viewmodels/TechLibViewModel';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TechLibRow from './TechLibraryTableRow';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import PermissionCheck from '../Utilities/PermissionCheck';
import { Permission } from '../Permissions/Permissions';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';
import MenuItem from '@mui/material/MenuItem';
import UploadTechLibFile from './UploadTechLibFile';
import SearchIcon from '@mui/icons-material/Search';

const headCells: HeadCell<TechLibViewModel>[] = [
	{ id: 'fileContent', property: 'FileContent', align: "left", disablePadding: false, label: 'File Content' },
	{ id: 'fileName', property: 'FileName', align: "left", disablePadding: false, label: 'File Name' },
	{ id: 'dateAdded', property: 'DateAdded', align: "left", disablePadding: false, label: 'Added' },
	{ id: 'liveFile', property: 'liveFile', align: "left", disablePadding: false, label: 'Live File' },
];

interface IProps {
	id: number;
}

export default function TechLibraryTable(props: IProps) {
	const { id } = props;
	const { crabFetch } = React.useContext(TokenRefreshContext);
	const [order, setOrder] = React.useState<Order>('asc');
	const [orderBy, setOrderBy] = React.useState<keyof TechLibViewModel>('fileContent');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [results, setResults] = React.useState<TechLibViewModel[]>([]);
	const [search, setSearch] = React.useState('');
	const [count, setCount] = React.useState(0);
	const [loading, setLoading] = React.useState(false);
	const [openAdd, setOpenAdd] = React.useState(false);
	const [dropdown, setDropdown] = React.useState<DropdownViewModel[]>([])
	const [type, setType] = React.useState(0);
	const [activeFiles, setActiveFiles] = React.useState<number>(0);


	React.useEffect(() => {
		getData();
	}, [page, rowsPerPage, order, orderBy, activeFiles]);

	const getData = async () => {
		setLoading(true);
		const token = await authService.getAccessToken();
		const orderByProp = headCells.find(f => f.id === orderBy)!.property;

		await crabFetch(`Asset/GetTechnicalLibraryTable?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}&assetId=${id}&active=${activeFiles === 2 ? '' : activeFiles === 0 ? false : true}`, {
			headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
		}, ResponseType.JSON,
			(data: any) => {
				setResults(data.rows);
				setCount(data.count);
				setLoading(false);
			},
			() => {
				setLoading(false);
			}
		);

		await crabFetch(`Asset/GetFileTypesDropDown`, {
			headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
		}, ResponseType.JSON,
			(data: any) => {
				setDropdown(data);
			}
		);
	}

	const closeDialog = (refresh: boolean) => {
		if (refresh)
			getData();

		setOpenAdd(false);
	}

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof TechLibViewModel) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setSearch(event.target.value);
	}

	const keyPress = (event: any) => {
		if (event.keyCode === 13) {
			getData();
		}
	}

	const handleChangePlantType = (event: React.ChangeEvent<HTMLInputElement>) => {
		setActiveFiles(parseInt(event.target.value))
	}

	const emptyRows = page > 0 ? Math.max(0, rowsPerPage - results.length) : 0;

	return (
		<Paper sx={{ padding: 2 }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Grid container alignItems="center" justifyContent="space-between">
						<Grid item xs={12} pb={1}>
							<Typography variant="h2">Technical Library</Typography>
						</Grid>
						<Grid item>
							<Grid container spacing={1} alignItems="center">
								<Grid item>
									<TextField
										color="primary"
										size="small"
										variant="outlined"
										select
										onChange={(value) => setType(parseInt(value.target.value))}
										value={type}
									>
										<MenuItem value={0}>All Types</MenuItem>
										{dropdown.map(item =>
											<MenuItem key={'type' + item.id} value={item.id}>{item.label}</MenuItem>
										)}
									</TextField>
								</Grid>
								<Grid item>
									<TextField
										color="primary"
										size="small"
										variant="outlined"
										select
										onChange={handleChangePlantType}
										value={activeFiles}
									>
										<MenuItem value={2}>All Files</MenuItem>
										<MenuItem value={0}>Active Files</MenuItem>
										<MenuItem value={1}>Archived Files</MenuItem>
									</TextField>
								</Grid>
								<Grid item>
									<TextField
										sx={{ minWidth: 300 }}
										color="primary"
										size="small"
										variant="outlined"
										onChange={handleSearch}
										value={search}
										placeholder="Search..."
										InputProps={{ endAdornment: <SearchIcon /> }}
										onKeyDown={keyPress}
									/>
								</Grid>
							</Grid>
						</Grid>
						<PermissionCheck permission={Permission.CreateAssets}>
							<Grid item>
								<Button aria-label={"Upload file"} onClick={() => setOpenAdd(true)} variant="contained">Upload File</Button>
							</Grid>
						</PermissionCheck>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<TableContainer>
						<ResponsiveTable
							aria-labelledby="tableTitle"
							size={'medium'}
							aria-label="user table"
						>
							<SortableTableHead
								order={order}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
								headCells={headCells}
								lastCells={<TableCell colSpan={2} />}
							/>
							<TableBody>
								{(!loading && results) && results.map((row: TechLibViewModel) => {
									return (<TechLibRow key={row.id} row={row} refresh={getData} />);
								})
								}
								{count <= 0 && !loading &&
									<TableRow
										sx={{
											height: 53 * rowsPerPage,
										}}
									>
										<TableCell colSpan={headCells.length + 1} align="center">No Results Found</TableCell>
									</TableRow>
								}
								{count > 0 && emptyRows > 0 && (
									<TableRow
										style={{
											height: 53 * emptyRows,
										}}
									>
										<TableCell colSpan={headCells.length + 1} />
									</TableRow>
								)}
								{loading &&
									<TableRow
										sx={{
											height: 53 * rowsPerPage,
										}}
									>
										<TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="primary" /></TableCell>
									</TableRow>
								}
							</TableBody>
						</ResponsiveTable>
					</TableContainer>
					<Grid container justifyContent="space-between" alignItems="center">
						<Grid item>
							<TablePagination
								rowsPerPageOptions={[5, 10, 25]}
								component="div"
								count={count}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<UploadTechLibFile open={openAdd} onClose={closeDialog} assetId={id} />
		</Paper>
	);
}