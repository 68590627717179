import * as React from 'react';
import Grid from '@mui/material/Grid';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import { useForm } from 'react-hook-form';
import 'moment/locale/en-gb';
import AssetViewmodel from '../Viewmodels/AssetViewmodel';
import AssetDetailViewmodel from '../Viewmodels/AssetDetailViewmodel';
import CustomCollapse from '../../Utilities/CustomCollapse';
import ComponentTable from '../Components/ComponentTable';
import SafetyDeviceTable from '../SafetyDevices/SafetyDeviceTable';
import Paper from '@mui/material/Paper';


interface IProps {
    asset: AssetViewmodel;
    showButtons: boolean;
    assetDetailId?: number;
}

export default function EqupimentData(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { asset, assetDetailId, showButtons } = props;
    const [assetDetails, setAssetDetails] = React.useState(new AssetDetailViewmodel());

    const [showMore, setShowMore] = React.useState(false);

    const formContext = useForm<AssetDetailViewmodel>({
        defaultValues: new AssetDetailViewmodel()
    });

    const { reset, watch } = formContext;
    const watchAssetType = watch('assetTypeId', assetDetails.assetTypeId);

    React.useEffect(() => {
        if (asset.id > 0) {
            getAsset();
        }
    }, [asset]);

    React.useEffect(() => {
        if (assetDetailId && assetDetailId > 0)
            getAsset();
    }, [assetDetailId])

    React.useEffect(() => {
        const details = { ...assetDetails };

        // Deal with null value in case of bad data
        if (details.assetTypeId === 6)
            details.equipmentType = details.equipmentType ?? 0;

        reset(details);
    }, [assetDetails]);

    const getAsset = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Asset/GetAssetDetails?asset=${asset.id}&assetType=${asset.assetType}&assetDetailsId=${assetDetailId ? assetDetailId : 0}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: AssetDetailViewmodel) => {
                setAssetDetails(data);
            },
            () => {
            }
        );
    }

    const showComponants = (asset.assetType === 1 || asset.assetType === 2 || asset.assetType === 6);
    const showSafty = ((watchAssetType === 1 || watchAssetType === 2) && assetDetails.associatedReliefDevices);
    var name = '';
    if (showComponants) {
        name = name.concat("Components");
    }
    if (showSafty) {
        name = name.concat(name.length > 0 ? ' & Safety Devices' : 'Safety Devices');
    }

    if (name.length > 0) {
        return (
            <Grid item xs={12}>
                <Paper>
                    <CustomCollapse showMore={showMore} setShowMore={setShowMore} title={name} >
                        <Grid container spacing={2}>
                            {(watchAssetType === 1 || watchAssetType === 2 || watchAssetType === 6) &&
                                <Grid item xs={12}>
                                    <ComponentTable assetDetailId={assetDetails.id} assetType={watchAssetType} showbuttons={showButtons} refreshData={getAsset} />
                                </Grid>
                            }
                            {((watchAssetType === 1 || watchAssetType === 2) && assetDetails.associatedReliefDevices) &&
                                <Grid item xs={12}>
                                    <SafetyDeviceTable assetDetailId={assetDetails.id} assetType={watchAssetType} showButtons={showButtons} refreshData={getAsset} />
                                </Grid>
                            }
                        </Grid>
                    </CustomCollapse>
                </Paper>
            </Grid>

        );
    }
    else {
        return (
            <></>
        );
    }
}