import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { Badge, ButtonBase, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import CredibleDamageViewModel from './Viewmodels/CredibleDamageViewModel';
import Checkbox from '@mui/material/Checkbox';
import CustomCollapse from '../Utilities/CustomCollapse';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';

interface IProps {
    wseId: number;
    setDamageMechanisms: (history: CredibleDamageViewModel[]) => void;
    damageMechanisms: CredibleDamageViewModel[];
    disableFields: boolean;
}

export default function CredibleDamageMechanisms(props: IProps) {
    const { wseId, damageMechanisms, setDamageMechanisms, disableFields } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [loading, setLoading] = React.useState(false);
    const [collapse, setCollapse] = React.useState(false);
    const [disabledBoxes, setDisableBoxes] = React.useState(false);
    const [newDamageMechanismName, setNewDamageMechanismName] = React.useState('');

    React.useEffect(() => {
        getData();
        setDisableBoxes(false);
    }, []);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        await crabFetch(`Wse/GetCredibleDamageMechanisms?wseId=${wseId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setDamageMechanisms(data);
            }
        );
        setLoading(false);
    }

    const updateCheckBox = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        var newArr = [...damageMechanisms];
        newArr[index].status = e.target.checked;
        setDamageMechanisms(newArr);
    }

    const addDamageMechanism = () => {
        if (newDamageMechanismName === '') {
            return;
        }
        var newDm = new CredibleDamageViewModel();
        newDm.name = newDamageMechanismName;
        newDm.custom = true;

        var newArr = [...damageMechanisms];
        newArr.push(newDm);
        setDamageMechanisms(newArr);

        setNewDamageMechanismName('');
    }

    const removeCustomDamageMechanism = (index: number) => {
        var newArr = [...damageMechanisms];
        newArr.splice(index, 1);
        setDamageMechanisms(newArr);
    }

    const handleInputChange = (e: any) => {
        setNewDamageMechanismName(e.target.value);
    }

    return (
        <Paper sx={{ padding: 2 }}>
            <CustomCollapse showMore={collapse} setShowMore={setCollapse} title={'Credible Damage Mechanisms'}>
                <Grid container spacing={1} sx={{ pb: 2 }}>
                    <Grid item xs={12}>
                        <Typography variant='h2'>Potential Mechanisms</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h5'>Identify all potential deterioration mechanisms</Typography>
                    </Grid>
                </Grid>
                {!loading &&
                    <Grid container spacing={1}>
                        {damageMechanisms.map((credibleDamage: CredibleDamageViewModel, index: number) => (
                            <Grid item key={credibleDamage.name} xs={3}>
                                <Badge
                                    sx={{ width: '100%' }}
                                    invisible={!credibleDamage.custom}
                                    badgeContent={!(disableFields || disabledBoxes) ?
                                        <ButtonBase onClick={() => removeCustomDamageMechanism(index)} disabled={disableFields || disabledBoxes}>
                                            <CancelIcon color="error" />
                                        </ButtonBase> : undefined
                                    }>
                                    <Grid container justifyContent='space-between' alignItems="center" sx={{ backgroundColor: 'background.default', borderRadius: '10px' }}>
                                        <Grid item sx={{ pl: 2, overflow: 'hidden' }} xs={10}>
                                            <Typography>{credibleDamage.name}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Checkbox disabled={disableFields || disabledBoxes} onChange={(e) => updateCheckBox(index, e)} checked={credibleDamage.status} name={credibleDamage.name} />
                                        </Grid>
                                    </Grid>
                                </Badge>

                            </Grid>
                        ))}
                        <Grid item xs={3}>
                            <Grid container justifyContent='space-between' alignItems="center">
                                <Grid item xs={12} >
                                    <TextField
                                        fullWidth={true}
                                        onChange={(e) => handleInputChange(e)}
                                        value={newDamageMechanismName}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end"><IconButton onClick={addDamageMechanism} size="small"><AddIcon /></IconButton></InputAdornment>,
                                        }}
                                        size="small">
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }

            </CustomCollapse>
        </Paper >
    );
}