import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import UserViewmodel from './Viewmodels/UserViewmodel';
import Button from '@mui/material/Button';
import { AlertContext } from '../Contexts/AlertContext';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import ButtonGroup from '@mui/material/ButtonGroup/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList/MenuList';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Popper from '@mui/material/Popper/Popper';
import Paper from '@mui/material/Paper/Paper';
import Grow from '@mui/material/Grow/Grow';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseDialogConfirmation from '../Utilities/CloseDialogConfimation';


interface IProps {
	user: UserViewmodel;
	editRow: (id: string) => void;
	refresh: () => void;
}

export default function UserManagementTableRow(props: IProps) {
	const { user, editRow, refresh } = props;
	const { crabFetch } = React.useContext(TokenRefreshContext);
	const { show } = React.useContext(AlertContext);
	const [loading, setLoading] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLDivElement>(null);
	const date = new Date(user.date);
	const [openConfirmation, setOpenConfirmation] = React.useState(false);

	const toggleArchive = async () => {
		const token = await authService.getAccessToken();
		setLoading(true);

		const method = user.archived ? 'UnArchiveUser' : 'ArchiveUser';

		crabFetch(`User/${method}?id=${user.id}`, {
			method: 'POST',
			headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
		}, ResponseType.Text,
			(data: any) => {
				if (data.length > 0) show('error', data);
				else {
					show('success', `Successfully ${user.archived ? 're-enabled' : 'archived'} User`);
					refresh();
				}
				setLoading(false);
			},
			(error: any) => {
				setLoading(false);
				show('error', error);
			}
		);
	}

	const reset = async () => {
		const token = await authService.getAccessToken();
		setLoading(true);
		crabFetch(`User/ResetPassword?userId=${user.id}`, {
			method: 'POST',
			headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
		}, ResponseType.Text,
			(data: any) => {
				if (data.length > 0) show('error', data);
				else {
					show('success', `Successfully sent reset Email`);
					refresh();
				}
				setLoading(false);
			},
			(error: any) => {
				setLoading(false);
				show('error', error);
			}
		);
	}

	const confirm = async () => {
		const token = await authService.getAccessToken();
		setLoading(true);
		crabFetch(`User/ResendEmailConfirmation?userId=${user.id}`, {
			method: 'POST',
			headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
		}, ResponseType.Text,
			(data: any) => {
				if (data.length > 0) show('error', data);
				else {
					show('success', `Successfully sent confimation Email`);
					refresh();
				}
				setLoading(false);
			},
			(error: any) => {
				setLoading(false);
				show('error', error);
			}
		);
	}

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: Event) => {
		setOpen(false);
	};

	const close = (refresh: boolean) => {
		setOpenConfirmation(false);
		if (refresh) {
			toggleArchive();
		}
	}

	return (
		<TableRow>
			<TableCell >{user.firstName}</TableCell>
			<TableCell>{user.lastName}</TableCell>
			<TableCell>{user.email}</TableCell>
			<TableCell>{user.role}</TableCell>
			<TableCell>{user.clientName}</TableCell>
			<TableCell>{user.status}</TableCell>
			<TableCell>{date.toLocaleString('en-gb', { year: 'numeric', month: 'numeric', day: 'numeric' })}</TableCell>
			<TableCell align="right">
				{user.role !== "Admin" &&
					<>
						<ButtonGroup variant="contained" ref={anchorRef} aria-label="User actions dropdown">
							<Button
								size="small"
								aria-controls={open ? 'split-button-menu' : undefined}
								aria-expanded={open ? 'true' : undefined}
								aria-label="select merge strategy"
								aria-haspopup="menu"
								onClick={handleToggle}
							>
								Options<ArrowDropDownIcon />
							</Button>
						</ButtonGroup>
						<Popper
							sx={{
								zIndex: 1,
							}}
							open={open}
							anchorEl={anchorRef.current}
							transition
							disablePortal
						>
							{({ TransitionProps, placement }) => (
								<Grow
									{...TransitionProps}
									style={{
										transformOrigin:
											placement === 'bottom' ? 'center top' : 'center bottom',
									}}
								>
									<Paper>
										<ClickAwayListener onClickAway={handleClose}>
											<MenuList id="split-button-menu" autoFocusItem>
												<MenuItem
													onClick={() => editRow(user.id)}
													aria-label={"Edit"}
													disabled={loading}
												>
													Edit
												</MenuItem>
												<MenuItem
													onClick={() => reset()}
													aria-label="Reset Password"
													disabled={loading}
												>
													Reset Password
												</MenuItem>
												<MenuItem
													onClick={() => setOpenConfirmation(true)}
													aria-label={user.archived ? "Unarchive" : "Archive User"}
													disabled={loading}
												>
													{user.archived ? "Unarchive" : "Archive"}
												</MenuItem>
												{user.status === "Invited" &&
													<MenuItem
														onClick={() => confirm()}
														aria-label={"Re-send Confirmation Email"}
														disabled={loading}
													>
														Re-send Confirmation Email
													</MenuItem>
												}
											</MenuList>
										</ClickAwayListener>
									</Paper>
								</Grow>
							)}
						</Popper>
					</>
				}
			</TableCell>
			<CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'Are you sure you want to' + ` ${user.status ? 're-enabled' : 'archive'} ${user.firstName} ${user.lastName}?` + ' ?'} open={openConfirmation} onClose={close}></CloseDialogConfirmation>
		</TableRow>
	);
}