import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { SmallTableCell } from './ResponsiveTable';
import styled from '@emotion/styled';

const HiddenSpan = styled("span")(() => ({
	border: 0,
	clip: 'rect(0 0 0 0)',
	height: 1,
	margin: -1,
	overflow: 'hidden',
	padding: 0,
	position: 'absolute',
	top: 20,
	width: 1
}));

export type Order = 'asc' | 'desc';

export interface HeadCell<ObjectType> {
	disablePadding: boolean;
	id: keyof ObjectType;
	label: string;
	property: string;
	align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
	disableSort?: boolean;
}

interface SortableTableProps<ObjectType> {
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ObjectType) => void;
	order: Order;
	orderBy: string;
	headCells: HeadCell<ObjectType>[];
	lastCells?: JSX.Element;
	firstCells?: JSX.Element;
	small?: boolean;
}

export default function SortableTableHead<ObjectType>(props: SortableTableProps<ObjectType>) {
	const { order, orderBy, onRequestSort, headCells, lastCells, firstCells, small } = props;

	const createSortHandler = (property: keyof ObjectType) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow sx={{ '& th': { padding: '8px' } }}>
				{firstCells}
				{headCells.map((headCell) => {
					if (small) {
						if (headCell.disableSort) {
							return <SmallTableCell
								key={headCell.id as string}
								align={headCell.align}
								padding={headCell.disablePadding ? 'none' : 'normal'}
							>
								{headCell.label}
							</SmallTableCell>;
						} else {
							return <SmallTableCell
								key={headCell.id as string}
								align={headCell.align}
								padding={headCell.disablePadding ? 'none' : 'normal'}
								sortDirection={orderBy === headCell.id ? order : false}
							>
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={createSortHandler(headCell.id)}
								>
									{headCell.label}
									{orderBy === headCell.id ? (
										<HiddenSpan>
											{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
										</HiddenSpan>
									) : null}
								</TableSortLabel>
							</SmallTableCell>;
						}
					} else {
						if (headCell.disableSort) {
							return <TableCell
								key={headCell.id as string}
								align={headCell.align}
								padding={headCell.disablePadding ? 'none' : 'normal'}
							>
								{headCell.label}
							</TableCell>;
						} else {
							return <TableCell
								key={headCell.id as string}
								align={headCell.align}
								padding={headCell.disablePadding ? 'none' : 'normal'}
								sortDirection={orderBy === headCell.id ? order : false}
							>
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={createSortHandler(headCell.id)}
								>
									{headCell.label}
									{orderBy === headCell.id ? (
										<HiddenSpan>
											{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
										</HiddenSpan>
									) : null}
								</TableSortLabel>
							</TableCell>;
						}
					}
				})}
				{lastCells}
			</TableRow>
		</TableHead>
	);
}