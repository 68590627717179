import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import { AlertContext } from '../../Contexts/AlertContext';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import CreateUserViewmodel from '../Viewmodels/CreateUserViewmodel';
import DropdownViewModel from '../../Utilities/ViewModels/DropdownViewModel';
import CircularProgress from '@mui/material/CircularProgress';
import PermissionCheck from '../../Utilities/PermissionCheck';
import CloseDialogConfirmation from '../../Utilities/CloseDialogConfimation';
import { Permission } from '../../Permissions/Permissions';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
}

export default function CreateUser(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose } = props;
    const [loading, setLoading] = React.useState(false);
    const [userTypes, setUserTypes] = React.useState<DropdownViewModel[]>([]);
    const [clients, setClients] = React.useState<DropdownViewModel[]>([]);
    const [openConfimation, setOpenConfimation] = React.useState(false);


    React.useEffect(() => {
        getData();
    }, []);

    const formContext = useForm<CreateUserViewmodel>({
        defaultValues: new CreateUserViewmodel()
    });

    const { reset, watch } = formContext;

    const watchUserType = watch('userTypeId', 0);

    React.useEffect(() => {
        reset(new CreateUserViewmodel());
    }, [open]);

    const getData = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Permissions/GetUserTypesDropdown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setUserTypes(data);
            },
            () => {
                setLoading(false);
            }
        );

        crabFetch(`Client/GetClientsDropdown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setClients(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();
        setLoading(true);

        if (form.userTypeId !== 4) {
            form.clientId = null;
        }

        crabFetch('User/CreateUser', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully created User");
                    onClose(true);
                }

                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    const closeDialogs = (refresh: boolean) => {
        if (refresh)
            onClose(false);
        setOpenConfimation(false);
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="sm"
            fullWidth
        >
            <FormContainer
                formContext={formContext}
                onSuccess={submit}
            >
                <DialogTitle>{"Create User"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid container spacing={2} item xs={12}>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="firstName" shrink>Forename</InputLabel>
                                <TextFieldElement name="firstName" required fullWidth size="small" />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="lastName" shrink>Surname</InputLabel>
                                <TextFieldElement name="lastName" required fullWidth size="small" />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} item xs={12}>
                            <Grid item xs={6}>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="email" shrink>Email</InputLabel>
                                    <TextFieldElement parseError={() => "Please enter a valid email address"} type={'email'} name="email" required fullWidth size="small" />
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="phoneNumber" shrink>Phone Number</InputLabel>
                                <TextFieldElement name="phoneNumber" required fullWidth size="small" />
                            </Grid>

                        </Grid>
                        <PermissionCheck permission={Permission.ManageUsers}>
                            <Grid container spacing={2} item xs={12}>
                                <Grid item xs={6}>
                                    <InputLabel htmlFor="userTypeId" shrink>User Level</InputLabel>
                                    <SelectElement
                                        name="userTypeId"
                                        required
                                        fullWidth
                                        size="small"
                                        options={userTypes}
                                        InputProps={{
                                            disabled: loading,
                                            startAdornment: (
                                                !loading ? null : <CircularProgress size={20} />
                                            )
                                        }}
                                    />
                                </Grid>
                                {(watchUserType === 4) &&
                                    <Grid item xs={6}>
                                        <InputLabel htmlFor="clientId" shrink>Client</InputLabel>
                                        <SelectElement
                                            name="clientId"
                                            required
                                            fullWidth
                                            size="small"
                                            options={clients}
                                            InputProps={{
                                                disabled: loading,
                                                startAdornment: (
                                                    !loading ? null : <CircularProgress size={20} />
                                                )
                                            }}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </PermissionCheck>
                    </Grid>
                    <DialogActions>
                        <Button onClick={() => setOpenConfimation(true)} color="error" disabled={loading}>Cancel</Button>
                        <LoadingButton variant="contained" type="submit" disabled={loading} loading={loading}>{"Create"}</LoadingButton>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
            <CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'Are you sure you want to discard any changes?'} open={openConfimation} onClose={closeDialogs}></CloseDialogConfirmation>

        </Dialog>
    );
}