import * as React from 'react';
import Grid from '@mui/material/Grid';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { useLocation, useNavigate } from 'react-router';
import AssetViewmodel from './Viewmodels/AssetViewmodel';
import AssetDetails from './AssetDetails/AssetDetails';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBackIcon from '@mui/icons-material/NavigateBefore';
import { FilterContext } from '../Contexts/FilterContext';
import Typography from '@mui/material/Typography';
import TechLibraryTable from './TechLibraryTable';
import WseStatus from '../Wse/WseStatus';
import InspectionsTable from './Inspections/InspectionsTable';
import Paper from '@mui/material/Paper';
import DraftsTable from './Drafts/DraftsTable';
import ActionsTable from './Actions/ActionsTable';
import DocHistoryTable from './DocHistory/DocHistoryTable';
import EqupimentData from './AssetDetails/EquipmentData';
import PermissionCheck from '../Utilities/PermissionCheck';
import { Permission } from '../Permissions/Permissions';

interface LocationState {
    id: number;
}

export default function Asset() {
    const navigate = useNavigate();
    const location = useLocation();
    const { updateClient, client, updatePlant, plant } = React.useContext(FilterContext);
    const { id } = location.state as LocationState || {
        id: 0
    }
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [asset, setAsset] = React.useState(new AssetViewmodel());
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (id > 0)
            getData();
    }, [id]);

    const getData = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Asset/GetAsset?id=${id}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: AssetViewmodel) => {
                setAsset(data);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const viewClient = () => {
        updateClient(asset.clientId);

        if (asset.clientId === client)
            navigate('/Plants');
    }

    const viewPlant = () => {
        updatePlant(asset.plantId);

        if (asset.plantId === plant)
            navigate('/Assets');
    }

    React.useEffect(() => {
        if (client > 0 && !loading)
            navigate('/Plants');
    }, [client]);

    React.useEffect(() => {
        if (plant > 0 && !loading)
            navigate('/Assets');
    }, [plant]);

    return (
        <Grid container spacing={2}>
            <Grid item>
                <Button variant="outlined" onClick={() => navigate('/Assets')} startIcon={<NavigateBackIcon />}>Back to Search</Button>
            </Grid>
            <Grid item>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <PermissionCheck permission={Permission.ViewClients}>
                        <Button onClick={viewClient}>{asset.client}</Button>
                    </PermissionCheck>
                    <PermissionCheck permission={Permission.ViewPlants}>
                        <Button onClick={viewPlant}>{asset.plant}</Button>
                    </PermissionCheck>
                    <Typography sx={{ color: 'text.primary' }}>{asset.plantItemNumber}</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
                {!loading &&
                    <Paper>
                        <AssetDetails asset={asset} showButtons={true} />
                    </Paper>
                }
            </Grid>
            {!loading &&
                <EqupimentData asset={asset} showButtons={true} />
            }
            <Grid item xs={12}>
                <WseStatus assetId={id} />
            </Grid>
            <Grid item xs={12}>
                <InspectionsTable assetId={id} clientId={asset.clientId} />
            </Grid>
            <Grid item xs={12}>
                <TechLibraryTable id={id} />
            </Grid>
            <Grid item xs={12}>
                <DraftsTable assetId={id} />
            </Grid>
            <Grid item xs={12}>
                <ActionsTable assetId={id} />
            </Grid>
            <Grid item xs={12}>
                <DocHistoryTable assetId={id} />
            </Grid>
        </Grid>
    );
}

