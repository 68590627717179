import * as React from 'react';
import { DatePickerElement, RadioButtonGroup, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import InputAdornment from '@mui/material/InputAdornment';

interface IProps {
	editing: boolean;
}

const reviewOptions = [
	{
		id: 1,
		label: 'Yes'
	},
	{
		id: 2,
		label: 'No'
	},
	{
		id: 3,
		label: 'N/A'
	},
	{
		id: 4,
		label: 'First Inspection'
	},
];

export default function SummaryStatement(props: IProps) {
	const { editing } = props;
	const { crabFetch } = React.useContext(TokenRefreshContext);
	const [intervalChanges, setIntervalChanges] = React.useState<DropdownViewModel[]>([]);

	React.useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		const token = await authService.getAccessToken();

		crabFetch(`InspectionReport/GetIntervalChangeOptions`, {
			headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
		}, ResponseType.JSON,
			(data: DropdownViewModel[]) => {
				setIntervalChanges(data);
			},
			() => {
			}
		);
	}

	return (
		<Grid container alignItems="flex-end" spacing={2}>
			<Grid item xs={12}>
				<InputLabel htmlFor="previousReportReviewed" shrink>Has the previous inspection report been reviewed?</InputLabel>
				<RadioButtonGroup name="previousReportReviewed" row type="number" disabled={!editing} options={reviewOptions} />
			</Grid>
			<Grid item xs={12}>
				<InputLabel htmlFor="equipmentSuitable" shrink>Is this equipment suitable for its design duties specified in Part 1?</InputLabel>
				<RadioButtonGroup name="equipmentSuitable" row disabled={!editing}
					options={[{
						id: 'true',
						label: 'Yes'
					}, {
						id: 'false',
						label: 'No'
					}]} />
			</Grid>
			<Grid item xs={12}>
				<InputLabel htmlFor="wseRequireAmending" shrink>Does the Scheme of Examination require amending as a result of this Examination?</InputLabel>
				<RadioButtonGroup name="wseRequireAmending" row disabled={!editing}
					options={[{
						id: 'true',
						label: 'Yes'
					}, {
						id: 'false',
						label: 'No'
					}]} />
			</Grid>
			<Grid container item>
				<Grid item xs={12}>
					<InputLabel htmlFor="carriedOutOn" shrink>I certify that this document is a true report of the Examination specified in Part 1 and carried out on:</InputLabel>
				</Grid>
				<Grid item xs={12} lg={3}>
					<DatePickerElement name="carriedOutOn" inputProps={{ fullWidth: true, size: "small" }} disabled={!editing} readOnly={!editing} />
				</Grid>
			</Grid>
			<Grid item xs={12} lg={9}>
				<InputLabel htmlFor="thoroughIntervalChange" shrink>The interval to the next Thorough Examination has been reviewed and:</InputLabel>
				<SelectElement
					name="thoroughIntervalChange"
					fullWidth
					size="small"
					options={intervalChanges}
					InputProps={{
						disabled: !editing
					}}
				/>
			</Grid>
			<Grid item xs={12} lg={3}>
				<TextFieldElement name="thoroughInterval" fullWidth size="small" disabled={!editing}
					InputProps={{
						endAdornment: <InputAdornment position="end">Months</InputAdornment>
					}}
				/>
			</Grid>
			<Grid container item>
				<Grid item xs={12}>
					<InputLabel htmlFor="thoroughDueBefore" shrink>This Thorough Examination is due before</InputLabel>
				</Grid>
				<Grid item xs={12} lg={3}>
					<DatePickerElement name="thoroughDueBefore" inputProps={{ fullWidth: true, size: "small" }} disabled={!editing} readOnly={!editing} />
				</Grid>
			</Grid>
			<Grid container item>
				<Grid item xs={12}>
					<InputLabel htmlFor="intermediateAInterval" shrink>Interval between Intermediate Examinations (Type A)</InputLabel>
				</Grid>
				<Grid item xs={12} lg={3}>
					<TextFieldElement name="intermediateAInterval" fullWidth size="small" disabled={!editing}
						InputProps={{
							endAdornment: <InputAdornment position="end">Months</InputAdornment>
						}}
					/>
				</Grid>
			</Grid>
			<Grid container item>
				<Grid item xs={12}>
					<InputLabel htmlFor="intermediateADueBefore" shrink>This Type A Examination is due before</InputLabel>
				</Grid>
				<Grid item xs={12} lg={3}>
					<DatePickerElement name="intermediateADueBefore" inputProps={{ fullWidth: true, size: "small" }} disabled={!editing} readOnly={!editing} />
				</Grid>
			</Grid>
			<Grid container item>
				<Grid item xs={12}>
					<InputLabel htmlFor="intermediateBInterval" shrink>Interval between Intermediate Examinations (Type B)</InputLabel>
				</Grid>
				<Grid item xs={12} lg={3}>
					<TextFieldElement name="intermediateBInterval" fullWidth size="small" disabled={!editing}
						InputProps={{
							endAdornment: <InputAdornment position="end">Months</InputAdornment>
						}}
					/>
				</Grid>
			</Grid>
			<Grid container item>
				<Grid item xs={12}>
					<InputLabel htmlFor="intermediateBDueBefore" shrink>This Type B Examination is due before</InputLabel>
				</Grid>
				<Grid item xs={12} lg={3}>
					<DatePickerElement name="intermediateBDueBefore" inputProps={{ fullWidth: true, size: "small" }} disabled={!editing} readOnly={!editing} />
				</Grid>
			</Grid>
		</Grid>
	);
}