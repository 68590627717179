import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import Typography from '@mui/material/Typography';
import authService from '../api-authorization/AuthorizeService';
import Bugsnag from '@bugsnag/js';
import { useIdleTimer } from 'react-idle-timer'
export enum ResponseType {
    JSON,
    Text,
    Blob,
    Response
}

type ContextType = {
    crabFetch: (url: string, options: any, type: ResponseType, responseFunc?: (data: any) => void, catchFunc?: (error?: any) => void, returnResponse?: boolean) => Promise<any>;
    save: boolean;
};

type TokenRefreshProviderProps = { children: React.ReactNode };

export const TokenRefreshContext = React.createContext<ContextType>({
    save: false,
    crabFetch: () => {
        throw Error('Cannot use a context without a provider');
    },
});

// https://codesandbox.io/p/sandbox/confirm-prompt-y8ew9s?file=%2Fsrc%2FApp.tsx%3A31%2C14&from-embed=
// 45 mins till logout 
const timeout = 2700000;
// propt 15 mins before
const promptBeforeIdle = 900000;
const startManually = true;

export default function TokenRefreshProvider({ children }: TokenRefreshProviderProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = React.useState<boolean>(false);
    const [save, setSave] = React.useState<boolean>(false);
    const [canEdit, setCanEdit] = React.useState<boolean>(false);

    React.useEffect(() => {
        checkIsAuth();
        setInterval(() => {
            refreshToken();
        }, 3000000);
    }, []);

    React.useEffect(() => {
        if ((location.pathname.includes("InspectionReport") || location.pathname.includes("Wse"))) {
            checkPermission();
        }
        else {
            setCanEdit(false);
        }
    }, [location]);

    const checkPermission = async () => {
        const type = location.pathname.includes("Wse") ? "Wse" : "InspectionReport";
        const token = await authService.getAccessToken();

        const split = location.pathname.split('/');
        const id = split.length === 4 ? split.pop() : 0;

        crabFetch(`${type}/CheckAmendability?id=${id}`, {
            headers: !token ? { 'Content-Type': 'text/plain; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain; charset=utf-8' },
        }, ResponseType.JSON,
            ((data: boolean) => {
                setCanEdit(data);
            })
        );
    }

    const onIdle = () => {
        setOpen(false);
        logOut();
    }

    const onActive = async () => {
        setOpen(false);
    }

    const onPrompt = () => {
        setOpen(true)
    }

    const logOut = () => {
        setOpen(false);
        navigate(`${ApplicationPaths.LogOut}`, {
            state: {
                local: true
            }
        });
    }

    // https://idletimer.dev/docs/api/use-idle-timer
    const { activate, start } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        startManually,
    })

    const saveReport = () => {
        activate();
        setOpen(false);
        setSave(true);
        setTimeout(() => {
            setSave(false);
        }, 5000);
    }

    const checkIsAuth = async () => {
        const isAuth = await authService.isAuthenticated();
        if (isAuth) {
            start();
        }
    }

    const refreshToken = async () => {
        const isAuth = await authService.isAuthenticated();
        if (isAuth) {
            await authService.refreshAccessToken();
        }
    }

    const preventClose = (event: any, reason: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
    }

    const crabFetch = async (url: string, options: any, type: ResponseType, responseFunc?: (data: any) => void, catchFunc?: (error?: any) => void, returnResponse?: boolean) => {
        let accessDenied = false;

        try {
            return fetch(url, options)
                .then(response => {
                    accessDenied = response.url.includes("AccessDenied");
                    //if (response.redirected) window.location.href = response.url;
                    if (type === ResponseType.JSON) return response.json()
                    if (type === ResponseType.Text) return response.text()
                    if (type === ResponseType.Blob) return response.blob()

                    return response;
                })
                .then(data => {
                    if (returnResponse) {
                        if (responseFunc) {
                            return responseFunc(data);
                        } else {
                            return data;
                        }
                    } else if (responseFunc) {
                        responseFunc(data);
                    }
                });
        }
        catch (error: any) {

            if (catchFunc) catchFunc(error);
            if (!accessDenied) Bugsnag.notify(error);
        };
    }

    return (
        <TokenRefreshContext.Provider value={{ crabFetch: crabFetch, save: save }}>
            <Dialog
                open={open}
                onClose={preventClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Inactivity Warning</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">You have been inactive for 30 minutes, please press continue to show you are active or you will be logged out in 15 minutes.</Typography>
                    <DialogActions>
                        <Button variant="contained" onClick={activate}>Continue</Button>
                        {(location.pathname.includes("InspectionReport") || location.pathname.includes("Wse")) && canEdit === true &&
                            <Button variant="contained" onClick={saveReport}>Save and Continue</Button>
                        }
                        <Button variant="contained" onClick={logOut}>Sign out</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            {children}
        </TokenRefreshContext.Provider>
    );
}