import * as React from 'react';
import Grid from '@mui/material/Grid';
import PermissionCheck from '../Utilities/PermissionCheck';
import { Permission } from '../Permissions/Permissions';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import SignOffViewmodel, { ApprovalStatus } from './Viewmodels/SignOffViewmodel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { AlertContext } from '../Contexts/AlertContext';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import CloseDialogConfirmation from '../Utilities/CloseDialogConfimation';

interface IProps {
    signOff: SignOffViewmodel;
    refresh: () => void;
    saveReport: () => void;
    canCompile: boolean;
}

export default function Compiler(props: IProps) {
    const { signOff, refresh, saveReport, canCompile } = props;
    const { show } = React.useContext(AlertContext);
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [level, setLevel] = React.useState<string | number>('');
    const [user, setUser] = React.useState<string>('');
    const [users, setUsers] = React.useState<DropdownViewModel[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);


    React.useEffect(() => {
        if (level !== '') {
            setUser('');
            getUsers();
        }
    }, [level]);

    const getUsers = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`User/GetSignOffDropDown?type=${level === 0 && signOff.docType === 'Wse' ? 'wse' : level === 0 ? 'report' : 'escalate'}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setUsers(data);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const submit = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`${signOff.docType}/Compile?id=${signOff.id}&escalate=${level === 1}&certifier=${user}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) {
                    show('error', data);
                }
                else {
                    show('success', `Successfully compiled ${signOff.docType}`);
                    refresh();
                }

                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    const onClose = async (refresh: boolean) => {
        if (refresh) {
            await saveReport();
            submit();
        }
    }

    return (
        <>
            {!loading &&
                <Grid container spacing={2}>
                    {signOff.compiledBy.length === 0 || signOff.certifiedStatus === ApprovalStatus.Rejected ?
                        <PermissionCheck permission={Permission.CreateReport || Permission.CreateWse}>
                            {canCompile &&
                                <>
                                    {signOff.certifiedStatus === ApprovalStatus.Rejected &&
                                        <React.Fragment>
                                            <Grid item xs={12}>
                                                <Alert severity="warning">{signOff.certifiedBy} has rejected this document. Please see the following comments and resubmit for approval.</Alert>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>Rejection Response</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField variant="outlined" size="small" fullWidth multiline rows={4} value={signOff.reason} disabled />
                                            </Grid>
                                        </React.Fragment>
                                    }
                                    <Grid item xs={12}>
                                        <Typography>Select Approval Level</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <TextField variant="outlined" size="small" fullWidth select value={level} onChange={(e) => setLevel(e.target.value)}>
                                            <MenuItem value={0}>Standard</MenuItem>
                                            <MenuItem value={1}>Escalate to Category 1</MenuItem>
                                        </TextField>
                                    </Grid>
                                    {level !== '' &&
                                        <Grid item xs={6} sm={4}>
                                            <TextField variant="outlined" size="small" fullWidth select value={user} onChange={(e) => setUser(e.target.value)}>
                                                {users.map(item =>
                                                    <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                                                )}
                                            </TextField>
                                        </Grid>
                                    }
                                    <Grid item>
                                        <LoadingButton variant="contained" disabled={level === '' || user === ''} loading={loading} loadingIndicator="Compiling..." onClick={() => setOpen(true)}>Submit For Approval</LoadingButton>
                                    </Grid>
                                </>
                            }
                            {!canCompile && 
                                <Grid item>
                                    <Typography>Report not compiled</Typography>
                                </Grid>
                            }
                        </PermissionCheck>
                        :
                        <>
                            <Grid item xs={4}>
                                <InputLabel htmlFor="compiledBy" shrink>Compiled By</InputLabel>
                                <TextField variant="outlined" size="small" disabled fullWidth value={signOff.compiledBy} />
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel htmlFor="compiledDate" shrink>Compiled Date</InputLabel>
                                <TextField variant="outlined" size="small" disabled fullWidth value={new Date(signOff.compiledDate).toLocaleDateString('en-gb')} />
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel htmlFor="compiledSig" shrink>Signature</InputLabel>
                                <Box sx={{ width: '100%', height: '37px', backgroundColor: '#F2F4F8', borderRadius: '10px' }}>
                                    <img aria-label="compiled by signature" style={{ height: '100%' }} src={signOff.compiledSig} />
                                </Box>
                            </Grid>
                        </>
                    }
                </Grid>
            }
            {loading &&
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Alert variant="outlined" severity="warning">
                            Document is saving. Do not refresh the page.
                        </Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3">Compiling... Uploading Attachments... Generating Document PDF..</Typography>
                    </Grid>
                </Grid>
            }
            <CloseDialogConfirmation confirmClosureMessage={'Ok'} preventClosureMessage={'Close'} title={'Compiling this report will save all unsaved changes.'} open={open} onClose={onClose} />
        </>
    );
}