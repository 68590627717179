import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TechViewModel from './Viewmodels/TechLibViewModel';
import Button from '@mui/material/Button';
import { AlertContext } from '../Contexts/AlertContext';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import Grid from '@mui/material/Grid';
import PermissionCheck from '../Utilities/PermissionCheck';
import { Permission } from '../Permissions/Permissions';
import CloseDialogConfirmation from '../Utilities/CloseDialogConfimation';

interface IProps {
    row: TechViewModel;
    /*  download: (id: number) => void;*/
    refresh: () => void;
}

export default function TechLiberaryTableRow(props: IProps) {
    const { row, refresh } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);

    const date = new Date(row.dateAdded);

    const toggleArchive = async () => {
        const token = await authService.getAccessToken();
        const method = row.archived ? 'UnArchiveFile' : 'ArchiveFile';

        crabFetch(`Asset/${method}?id=${row.id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully ${row.archived ? 're-enabled' : 'archived'} file`);
                    refresh();
                }
            },
            (error: any) => {
                show('error', error);
            }
        );
    }

    const download = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Asset/DownloadFile?id=${row.id}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Blob,
            ((blob: Blob) => {
                const url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.download = row.fileName;
                a.href = url;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            })
        );
    }

    const close = (refresh: boolean) => {
        setOpenConfirmation(false);
        if (refresh) {
            toggleArchive();
        }
    }

    return (
        <TableRow>
            <TableCell>{row.fileContent}</TableCell>
            <TableCell>{row.fileName}</TableCell>
            <TableCell>{date.toLocaleString('en-gb', { year: 'numeric', month: 'numeric', day: 'numeric' })}</TableCell>
            <TableCell>{row.liveFile ? "LIVE WSE" : ""}</TableCell>
            <TableCell align="right">
                <Grid container spacing={1} justifyContent="flex-end">
                    <PermissionCheck permission={Permission.DeleteDocs}>
                        <Grid item>
                            <Button variant="contained" size="small" onClick={() =>setOpenConfirmation(true)}>{!row.archived ? "Archive" : "Unarchive"}</Button>
                        </Grid>
                    </PermissionCheck>
                    <PermissionCheck permission={Permission.ViewDocs}>
                        <Grid item>
                            <Button variant="contained" onClick={download} size="small">Download</Button>
                        </Grid>
                    </PermissionCheck>
                </Grid>
            </TableCell>
            <CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'Are you sure you want to discard ' + row.fileName + ' ?'} open={openConfirmation} onClose={close}></CloseDialogConfirmation>

        </TableRow>
    );
}