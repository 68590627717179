import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import LiveWseViewModel from './Viewmodels/WseStatusViewModel';
import TextField from '@mui/material/TextField';
import { AlertContext } from '../Contexts/AlertContext';
import CloseDialogConfirmation from '../Utilities/CloseDialogConfimation';
import PermissionCheck from '../Utilities/PermissionCheck';
import { Permission } from '../Permissions/Permissions';
import LoadingButton from '@mui/lab/LoadingButton';

interface IProps {
    assetId: number;
}

export default function WseStatus(props: IProps) {
    const { assetId } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const navigate = useNavigate();
    const [status, setStatus] = React.useState<LiveWseViewModel>(new LiveWseViewModel());
    const { show } = React.useContext(AlertContext);
    const [openConfimation, setOpenConfimation] = React.useState(false);
    const [viewPermission, setViewPermission] = React.useState(false);
    const [discardPermission, setDiscardPermission] = React.useState(false);

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        getData();
        checkWsePermissions();
    }, []);

    const checkWsePermissions = async () => {
        var p1 = await CheckHasPermission(Permission.CreateWse);
        var p2 = await CheckHasPermission(Permission.SignOffWse);
        var p3 = await CheckHasPermission(Permission.SignOffEscalatedDocument);
        var p4 = await CheckHasPermission(Permission.AcknowledgeDocument);

        setViewPermission(p1 || p2 || p3 || p4);
    }

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        await crabFetch(`Wse/WseStatus?assetId=${assetId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setStatus(data);
                setLoading(false);
                setDiscardPermission(data.draftedById === user.sub);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const discard = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Wse/RemoveWorkingRevision?assetId=${assetId}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: ''
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) {
                    show('error', data);
                }
                else {
                    show('success', "Sucessfully removed working revision");
                    getData();
                }
                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    const viewWse = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Wse/CheckForDraft?assetId=${assetId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            ((data: any) => {
                if (data === 'false') {
                    navigate(
                        `/Wse/${assetId}/${status.workingRevisionId}`
                    );
                }
                else if (data === 'true') {
                    show('error', "A new WSE has been started. Refresh your page and try again");
                }
                else {
                    show('error', data)
                }
            })
        );

    }

    const downloadLive = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Wse/GetLivePdf?assetId=${assetId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Blob,
            ((blob: Blob) => {
                const url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.download = "Wse";
                a.href = url;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
                setLoading(false);
            })
        );
    }

    const closeDialogs = (refresh: boolean) => {
        if (refresh) {
            discard();
        }
        setOpenConfimation(false);
    }

    var date = new Date(status.approvalDate);

    return (
        <Paper sx={{ padding: 2 }}>
            <Grid container spacing={2}>
                <Grid item>
                    <Typography variant="h2">Written Schemes of Examination Status</Typography>
                </Grid>
                {status.revisionNumber === '' && status.status === '' &&
                    <Grid item container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>No Wse available. Please upload a Live document or Start a new WSE</Typography>
                        </Grid>
                        <PermissionCheck permission={Permission.CreateWse}>
                            <Grid item >
                                <Button variant="contained" onClick={viewWse}>Start New WSE</Button>
                            </Grid>
                        </PermissionCheck>
                    </Grid>
                }
                {status.revisionNumber !== '' &&
                    <>
                        <Grid item container spacing={2}>
                            <Grid item sx={{ flexGrow: 1 }}>
                                <Typography variant="h3">Live WSE</Typography>
                            </Grid>
                            <PermissionCheck permission={Permission.ViewDocs}>
                                <Grid item>
                                    <LoadingButton variant='contained' onClick={downloadLive} loading={loading} loadingIndicator="Downloading...">Download Live WSE</LoadingButton>
                                </Grid>
                            </PermissionCheck>
                        </Grid>
                        <Grid item container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <InputLabel shrink>Revision Number</InputLabel>
                                <TextField disabled={true} size="small" fullWidth name={'number'} value={status.revisionNumber}>{status?.revisionNumber}</TextField>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <InputLabel shrink>AIMS Generated or External</InputLabel>
                                <TextField disabled={true} size="small" fullWidth name={'aims'} value={status.aimsStatus}></TextField>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <InputLabel shrink>Approval Date</InputLabel>
                                <TextField disabled={true} size="small" fullWidth name={'date'} value={date.toLocaleString('en-gb', { year: 'numeric', month: 'numeric', day: 'numeric' })}></TextField>
                            </Grid>
                        </Grid>
                    </>
                }
                {status.status !== '' && status.status !== "Live & Acknowledged" && status.status !== "Live" &&
                    <>
                        <Grid item container spacing={2}>
                            <Grid item sx={{ flexGrow: 1 }}>
                                <Typography variant="h2">WSE Working Revision</Typography>
                            </Grid>
                            {viewPermission &&
                                <Grid item>
                                    <Button variant='contained' disabled={loading} onClick={viewWse}>Continue To Revision</Button>
                                </Grid>
                            }
                            <PermissionCheck permission={Permission.DeleteDrafts}>
                                {discardPermission &&
                                    < Grid item>
                                        <Button variant='outlined' onClick={() => setOpenConfimation(true)} disabled={loading}> Discard Revision </Button>
                                    </Grid>
                                }
                            </PermissionCheck>
                        </Grid>
                        <Grid item container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <InputLabel shrink>Status</InputLabel>
                                <TextField disabled={true} size="small" fullWidth name={'test'} value={status.status}></TextField>
                            </Grid>
                        </Grid>
                    </>
                }
                {
                    ((status.workingRevisionId === 0 && status.status === '' && status.revisionNumber !== '') || status.status === "Live") &&
                    <Grid item container spacing={2}>
                        <PermissionCheck permission={Permission.CreateWse}>
                            <Grid item xs={12}>
                                <Typography>Start a new Revision</Typography>
                            </Grid>
                            <Grid item >
                                <Button variant="contained" onClick={viewWse}>Start New WSE</Button>
                            </Grid>
                        </PermissionCheck>
                    </Grid>
                }
            </Grid >
            <CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'Are you sure you want to discard any changes?'} open={openConfimation} onClose={closeDialogs}></CloseDialogConfirmation>
        </Paper >
    );
}

export const CheckHasPermission = async (permission: string | string[]) => {
    const user = await authService.getUser();

    if ((user && user.role && user.role === "Admin")) {
        return true;
    }
    else if (user && user.permission) {
        if (Array.isArray(permission) && user.permission.some((item: string) => permission.includes(item))) {
            return true;
        } else if (user.permission.includes(permission)) {
            return true;
        } else {
            return false;
        }
    }

    return false;
}