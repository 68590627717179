import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import ClientListViewmodel from './Viewmodels/ClientListViewmodel';
import { AlertThresholds } from '../Utilities/AlertThresholds';
import PermissionCheck from '../Utilities/PermissionCheck';
import { Permission } from '../Permissions/Permissions';
import { useNavigate } from 'react-router-dom';
import CloseDialogConfirmation from '../Utilities/CloseDialogConfimation';
import Grid from '@mui/material/Grid';

interface IProps {
	row: ClientListViewmodel;
	edit: (id: number) => void;
	archive: (id: number) => void;
}

export default function ClientsTableRow({ row, edit, archive }: IProps) {
	const navigate = useNavigate();
	const [openConfirmation, setOpenConfirmation] = React.useState(false);

	const viewPlants = () => {
		navigate(
			'/Plants',
			{
				state: {
					clientId: row.id
				}
			}
		);
	}

	const close = (refresh: boolean) => {
		setOpenConfirmation(false);
		if (refresh) {
			archive(row.id);
		}
	}

	return (
		<TableRow>
			<TableCell>{row.name}</TableCell>
			<TableCell align="right">{row.plants}</TableCell>
			<TableCell align="right">{row.assets}</TableCell>
			<TableCell align="right">{row.alert > 0 ? ((row.alert / 30) as AlertThresholds).toString() + ' Months' : 'Not Configured'}</TableCell>
			<TableCell align="right">
				<Grid container spacing={1} justifyContent="flex-end">
					<PermissionCheck permission={Permission.CreateClients}>
						<Grid item>
							<Button variant="contained" size="small" onClick={() => edit(row.id)}>Edit Details</Button>
						</Grid>
					</PermissionCheck>
					<PermissionCheck permission={Permission.DeleteClients}>
						<Grid item>
							<Button variant="contained" size="small" onClick={() => setOpenConfirmation(true)}>{!row.status ? "Archive" : "Unarchive"}</Button>
						</Grid>
					</PermissionCheck>
					<Grid item>
						<Button variant="contained" size="small" onClick={viewPlants}>View Plants</Button>
					</Grid>
				</Grid>
			</TableCell>
			<CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'Are you sure you want to' + ` ${row.status ? 're-enabled' : 'archive'} ${row.name}?` + ' ?'} open={openConfirmation} onClose={close}></CloseDialogConfirmation>
		</TableRow>
	);
}