import * as React from 'react';
import { DatePickerElement, FieldArrayWithId, SelectElement, TextFieldElement, UseFieldArrayRemove, UseFieldArrayAppend } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid';
import InspectionReportViewmodel from './Viewmodels/InspectionReportViewmodel';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ActionViewmodel from './Viewmodels/ActionViewmodel';
import Button from '@mui/material/Button';
import PermissionCheck from '../Utilities/PermissionCheck';
import { Permission } from '../Permissions/Permissions';

interface IProps {
    editing: boolean;
    fields: FieldArrayWithId<InspectionReportViewmodel, 'actions', 'id'>[];
    remove: UseFieldArrayRemove;
    append: UseFieldArrayAppend<InspectionReportViewmodel, 'actions'>;
}

export default function RequiredActions(props: IProps) {
    const { editing, fields, remove, append } = props;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TableContainer>
                    <ResponsiveTable
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="actions table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fields.map((field, index) => {
                                return (
                                    <TableRow key={field.id}>
                                        <TableCell sx={{ backgroundColor: '#fff !important' }}>
                                            <TextFieldElement multiline name={`actions.${index}.description`} fullWidth size="small" disabled={!editing} />
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: '#fff !important' }}>
                                            <SelectElement fullWidth size="small"
                                                name={`actions.${index}.type`}
                                                options={[{
                                                    id: 'Mandatory',
                                                    label: 'Mandatory'
                                                },
                                                {
                                                    id: 'Recommendation',
                                                    label: 'Recommendation'
                                                }]}
                                                InputProps={{
                                                    disabled: !editing
                                                }} />
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: '#fff !important' }}>
                                            <DatePickerElement name={`actions.${index}.date`} inputProps={{ fullWidth: true, size: "small" }} disabled={!editing} readOnly={!editing} />
                                        </TableCell>
                                        <TableCell align="right" sx={{ backgroundColor: '#fff !important', width: 64 }}>
                                            <IconButton size="small" onClick={() => remove(index)} disabled={!editing}><DeleteIcon /></IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </ResponsiveTable>
                </TableContainer>
            </Grid>
            {editing &&
                <PermissionCheck permission={Permission.CreateReport}>
                    <Grid item>
                        <Button variant="contained" size="small" onClick={() => append(new ActionViewmodel())}>Add Another Action</Button>
                    </Grid>
                </PermissionCheck>
            }
        </Grid>
    );
}