import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { Button, Divider, IconButton, TextField, Typography } from '@mui/material';
import CustomCollapse from '../Utilities/CustomCollapse';
import AmmentmentAndDocumentHistoryViewModel from './Viewmodels/AmmentmentAndDocumentHistoryViewModel';
import DeleteIcon from '@mui/icons-material/Delete';
import PermissionCheck from '../Utilities/PermissionCheck';
import { Permission } from '../Permissions/Permissions';

interface IProps {
    wseId: number;
    setHistory: (history: AmmentmentAndDocumentHistoryViewModel[]) => void;
    history: AmmentmentAndDocumentHistoryViewModel[];
    disableFields: boolean;
}

export default function AmmendmentAndDocumentHistory(props: IProps) {
    const { wseId, history, setHistory, disableFields } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [loading, setLoading] = React.useState(false);
    const [collapse, setCollapse] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        await crabFetch(`Wse/GetAmmentmentAndDocumentHistory?wseId=${wseId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: AmmentmentAndDocumentHistoryViewModel[]) => {
                setHistory(data);
            }
        );
        setLoading(false);
    }

    const updateRef = (index: number, e: any) => {
        var newArr = [...history];
        newArr[index][`ammendmentRef`] = e.target.value;
        setHistory(newArr);
    }

    const updateDetails = (index: number, e: any) => {
        var newArr = [...history];
        newArr[index]['ammendmentDetails'] = e.target.value;
        setHistory(newArr);
    }

    const addRow = () => {
        setHistory([...history, new AmmentmentAndDocumentHistoryViewModel()]);
    }

    const removeRow = (index: number) => {
        const newArr = [...history];
        newArr.splice(index, 1);
        setHistory(newArr);
    }
   
    return (
        <Paper sx={{ padding: 2 }}>
            <CustomCollapse showMore={collapse} setShowMore={setCollapse} title={'Amendment & Document History'} count={disableFields ? history.length : undefined}>
                {!loading && history.length > 0 &&
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Typography>Amendment Reference/Year</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>Amendment Details</Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} sx={{ pt: 1 }}>
                            {history.map((history: AmmentmentAndDocumentHistoryViewModel, index: number) => (
                                <Grid container item spacing={2}>
                                    <Grid item xs={4}>
                                        <TextField disabled={disableFields} name="ammendmentRef" onChange={(e) => updateRef(index, e)} value={history.ammendmentRef} fullWidth size="small" />
                                    </Grid>
                                    <Grid item xs={7}>
                                        <TextField disabled={disableFields} name="ammendmentDetails" onChange={(e) => updateDetails(index, e)} value={history.ammendmentDetails} fullWidth size="small" />
                                    </Grid>
                                    {!disableFields &&
                                        <Grid item xs={1}>
                                            <IconButton onClick={() => removeRow(index)}><DeleteIcon /></IconButton>
                                        </Grid>
                                    }
                                </Grid>
                            ))}
                        </Grid>
                    </>
                }
                <PermissionCheck permission={Permission.CreateWse}>
                    {!disableFields &&
                        <Grid sx={{ pt: 1 }}>
                            <Button variant="contained" onClick={addRow}>Add Another</Button>
                        </Grid>
                    }
                </PermissionCheck>
            </CustomCollapse>
        </Paper>
    );
}