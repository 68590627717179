import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { DatePickerElement, FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import { AlertContext } from '../../Contexts/AlertContext';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import CreateAssetViewmodel from '../Viewmodels/CreateAssetViewmodel';
import DropdownViewModel from '../../Utilities/ViewModels/DropdownViewModel';
import CircularProgress from '@mui/material/CircularProgress';
import CloseDialogConfirmation from '../../Utilities/CloseDialogConfimation';
import { FilterContext } from '../../Contexts/FilterContext';
import CreateStorage from './CreateStorage';
import CreateStructure from './CreateStructure';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/en-gb';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
}

export default function CreateAsset(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { client, plant } = React.useContext(FilterContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose } = props;
    const [loading, setLoading] = React.useState(false);
    const [assetTypes, setAssetTypes] = React.useState<DropdownViewModel[]>([]);
    const [clients, setClients] = React.useState<DropdownViewModel[]>([]);
    const [plants, setPlants] = React.useState<DropdownViewModel[]>([]);
    const [equipmentTypes, setEquipmentTypes] = React.useState<DropdownViewModel[]>([]);
    const [openConfimation, setOpenConfimation] = React.useState(false);
    const [clientId, setClientId] = React.useState(client);
    const [clientValue, setClientValue] = React.useState<DropdownViewModel | null>(null);


    const formContext = useForm<CreateAssetViewmodel>({
        defaultValues: new CreateAssetViewmodel()
    });

    const { reset, watch } = formContext;

    const watchAssetType = watch('assetType', 0);

    React.useEffect(() => {
        getData();
    }, []);

    React.useEffect(() => {
        getPlants();
    }, [clientId]);

    React.useEffect(() => {
        setClientId(clientId);
        setClientValue(clients.find(f => f.id === clientId) ?? null);
    }, [client, clients]);

    React.useEffect(() => {
        const newAsset = new CreateAssetViewmodel();
        newAsset.client = client;
        newAsset.plant = plant;

        reset(newAsset);
    }, [open, client, plant]);

    const getData = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Asset/GetAssetTypesDropDown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setAssetTypes(data);
            },
            () => {
                setLoading(false);
            }
        );

        crabFetch(`Client/GetClientsDropdown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setClients(data);
            },
            () => {
                setLoading(false);
            }
        );

        crabFetch(`Base/GetDropdown?lookup=EquipmentType`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setEquipmentTypes(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const getPlants = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Plant/GetPlantsDropdown?client=${clientId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setPlants(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();
        setLoading(true);
      
        form.client = clientId;

        crabFetch('Asset/CreateAsset', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully created Asset");
                    onClose(true);
                }

                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    const closeDialogs = (refresh: boolean) => {
        if (refresh)
            onClose(false);
        setOpenConfimation(false);
    }

    const setClient = (input: string) => {
        const idString = clients.find(c => c.label === input)?.id;
        const parsedId = parseInt(idString as string ?? '0', 10);

        if (!isNaN(parsedId)) {
           
            setClientId(parsedId);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="md"
            fullWidth
        >
            <FormContainer
                formContext={formContext}
                onSuccess={submit}
            >
                <DialogTitle>{"Add New Asset"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {(watchAssetType === 3 || watchAssetType === 4) &&
                            <Grid item xs={12}>
                                <Typography variant="h6">General</Typography>
                            </Grid>
                        }
                        <Grid item xs={12} md={4}>
                            <InputLabel htmlFor="client" shrink>Client</InputLabel>
                            <Autocomplete
                                disablePortal
                                size="small"
                                options={clients}
                                value={clientValue}
                                onChange={((event: any, newValue: DropdownViewModel | null ) => setClientValue(newValue))}
                                onInputChange={(value, newInputValue) => setClient(newInputValue)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <InputLabel htmlFor="plant" shrink>Plant</InputLabel>
                            <SelectElement name={'plant'}
                                options={plants }
                                disabled={clientId === 0}
                                fullWidth
                                size="small"
                            >
                            </SelectElement>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <InputLabel htmlFor="assetType" shrink>Asset Type</InputLabel>
                            <SelectElement
                                name="assetType"
                                required
                                fullWidth
                                size="small"
                                options={assetTypes}
                                InputProps={{
                                    disabled: loading,
                                    startAdornment: (
                                        !loading ? null : <CircularProgress size={20} />
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <InputLabel htmlFor="plantItemNumber" shrink>Plant Item Number</InputLabel>
                            <TextFieldElement name="plantItemNumber" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <InputLabel htmlFor="serialNumber" shrink>Serial No.</InputLabel>
                            <TextFieldElement name="serialNumber" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <InputLabel htmlFor="designCode" shrink>Design Code</InputLabel>
                            <TextFieldElement name="designCode" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <InputLabel htmlFor="dateOfFabrication" shrink>Commissioning Date</InputLabel>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="enGB" >
                                <DatePickerElement name="dateOfFabrication" required InputProps={{ fullWidth: true, size: "small" }} />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="description" shrink>Description</InputLabel>
                            <TextFieldElement name="description" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="service" shrink>Service</InputLabel>
                            <TextFieldElement name="service" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="pids" shrink>P&ID's / Drawings</InputLabel>
                            <TextFieldElement name="pids" required fullWidth size="small" />
                        </Grid>
                        {(watchAssetType === 1 || watchAssetType === 2 || watchAssetType === 3 || watchAssetType === 6) &&
                            <Grid item xs={12} md={4}>
                                <InputLabel htmlFor="pssr" shrink>PSSR</InputLabel>
                                <SelectElement
                                    name="pssr"
                                    fullWidth
                                    size="small"
                                    options={[
                                        { id: true, label: "Yes" },
                                        { id: false, label: "No" }
                                    ]}
                                    InputProps={{
                                        disabled: loading,
                                        startAdornment: (
                                            !loading ? null : <CircularProgress size={20} />
                                        )
                                    }}
                                />
                            </Grid>
                        }
                        {(watchAssetType === 1 || watchAssetType === 2) &&
                            <Grid item xs={12} md={4}>
                                <InputLabel htmlFor="associatedReliefDevices" shrink>Associated Relief Devices</InputLabel>
                                <SelectElement
                                    name="associatedReliefDevices"
                                    fullWidth
                                    size="small"
                                    options={[
                                        { id: true, label: "Yes" },
                                        { id: false, label: "No" }
                                    ]}
                                    InputProps={{
                                        disabled: loading,
                                        startAdornment: (
                                            !loading ? null : <CircularProgress size={20} />
                                        )
                                    }}
                                />
                            </Grid>
                        }
                        {/* 3 = storage tank */}
                        {watchAssetType === 3 &&
                            <CreateStorage editing={true} />
                        }
                        {/* 4 = structure */}
                        {watchAssetType === 4 &&
                            <CreateStructure editing={true} />
                        }
                        {/* 6 = relief system */}
                        {watchAssetType === 6 &&
                            <Grid item xs={12} md={4}>
                                <InputLabel htmlFor="equipmentType" shrink>Equipment Type</InputLabel>
                                <SelectElement
                                    name="equipmentType"
                                    required
                                    fullWidth
                                    size="small"
                                    options={equipmentTypes}
                                    InputProps={{
                                        disabled: loading,
                                        startAdornment: (
                                            !loading ? null : <CircularProgress size={20} />
                                        )
                                    }}
                                />
                            </Grid>
                        }
                    </Grid>
                    <DialogActions>
                        <Button onClick={() => setOpenConfimation(true)} color="error" disabled={loading}>Cancel</Button>
                        <LoadingButton variant="contained" type="submit" disabled={loading} loading={loading}>{"Create"}</LoadingButton>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
            <CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'Are you sure you want to discard any changes?'} open={openConfimation} onClose={closeDialogs}></CloseDialogConfirmation>
        </Dialog>
    );
}