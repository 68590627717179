import * as React from 'react';
import Grid from '@mui/material/Grid';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { useParams, useNavigate } from 'react-router';
import AssetViewmodel from '../Assets/Viewmodels/AssetViewmodel';
import AssetDetails from '../Assets/AssetDetails/AssetDetails';
import Button from '@mui/material/Button';
import NavigateBackIcon from '@mui/icons-material/NavigateBefore';
import CredibleDamageMechanisms from './CredibleDamageMechanisms';
import SignOff, { SignOffDocType } from '../SignOff/SignOff';
import InspectionAndPreparation from './InspectionAndPreparation';
import Typography from '@mui/material/Typography';
import AmmendmentAndDocumentHistory from './AmmendmentAndDocumentHistory';
import AttachedFiles from './AttachedFiles';
import UpdateWseViewModel from './Viewmodels/UpdateWseViewModel';
import { UtilityMethods } from '../Utilities/UtilityMethods';
import { AlertContext } from '../Contexts/AlertContext';
import SubmitInspectionsAndPreparationsViewModel from './Viewmodels/SubmitInspectionsAndPreparationsViewModel';
import AmmentmentAndDocumentHistoryViewModel from './Viewmodels/AmmentmentAndDocumentHistoryViewModel';
import CredibleDamageViewModel from './Viewmodels/CredibleDamageViewModel';
import FileViewModel from './Viewmodels/FileViewModel';
import CustomCollapse from '../Utilities/CustomCollapse';
import Paper from '@mui/material/Paper';
import WseTopper from './WseTopper';
import PermissionCheck from '../Utilities/PermissionCheck';
import { Permission } from '../Permissions/Permissions';
import EqupimentData from '../Assets/AssetDetails/EquipmentData';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import Alert from '@mui/material/Alert/Alert';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';

export default function Wse() {
    const navigate = useNavigate();
    const { assetId, id } = useParams();
    const { show } = React.useContext(AlertContext);
    const { crabFetch, save } = React.useContext(TokenRefreshContext);
    const [asset, setAsset] = React.useState(new AssetViewmodel());
    const [loading, setLoading] = React.useState(true);
    const [canEdit, setCanEdit] = React.useState(false);
    const [viewPermission, setViewPermission] = React.useState(false);
    const [downloading, setDownloading] = React.useState(false);
    const [wseId, setWseId] = React.useState<number | null>(null);
    const [history, setHistory] = React.useState<AmmentmentAndDocumentHistoryViewModel[]>([]);
    const [inspections, setInspections] = React.useState(new SubmitInspectionsAndPreparationsViewModel());
    const [damageMechanisms, setDamageMechanisms] = React.useState<CredibleDamageViewModel[]>([]);
    const [files, setFiles] = React.useState<FileViewModel[]>([]);

    React.useEffect(() => {
        if (id)
            setWseId(parseInt(id));

        if (assetId && parseInt(assetId) > 0) {
            getData();
        }
    }, [assetId, id]);

    React.useEffect(() => {
        canEdit ? checkWsePermissions() : setViewPermission(true);
    }, [canEdit]);

    React.useEffect(() => {
        if (wseId !== null) {
            updateSetSaveButton();
        }
    }, [id]);

    React.useEffect(() => {
        if (save === true && canEdit) {
            submitForm();
        }
    }, [save]);

    const checkWsePermissions = async () => {
        var p1 = await CheckHasPermission(Permission.CreateWse);
        var p2 = await CheckHasPermission(Permission.SignOffWse);
        var p3 = await CheckHasPermission(Permission.SignOffEscalatedDocument);
        var p4 = await CheckHasPermission(Permission.AcknowledgeDocument);

        setViewPermission(p1 || p2 || p3 || p4);
    }

    const getData = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);
        crabFetch(`Asset/GetAsset?id=${assetId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: AssetViewmodel) => {
                setAsset(data);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
        updateSetSaveButton();
    }

    const updateSetSaveButton = async () => {

        if (id && parseInt(id) > 0) {
            const token = await authService.getAccessToken();
            crabFetch(`Wse/CheckAmendability?id=${id}`, {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            }, ResponseType.JSON,
                (data: boolean) => {
                    setCanEdit(data);
                    setLoading(false);
                },
                () => {
                    setLoading(false);
                }
            );
        }
        else {
            setCanEdit(true);
        }
    }

    const download = async () => {
        setDownloading(true);
        const token = await authService.getAccessToken();

        // get file name
        crabFetch(`Wse/GetReportName?wseId=${wseId}`, {
            headers: !token ? { 'Content-Type': 'text/plain; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain; charset=utf-8' },
        }, ResponseType.Text,
            ((filename: string) => {
                crabFetch(`Wse/GetPdf?wseId=${wseId}`, {
                    headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
                }, ResponseType.Blob,
                    ((blob: Blob) => {
                        const url = window.URL.createObjectURL(blob);
                        var a = document.createElement('a');
                        a.download = filename;
                        a.href = url;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(url);
                        setDownloading(false);
                    })
                );
            })
        );
    }


    const submitForm = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        var form = new UpdateWseViewModel();
        form.assetId = assetId ? parseInt(assetId) : 0;
        form.wseId = wseId;
        form.history = history;
        form.inspection = inspections;
        form.damageMechanisms = damageMechanisms
        form.files = files;

        let formData = new FormData();
        UtilityMethods.convertModelToFormData(form, formData);

        await crabFetch(`Wse/UpdateWse`, {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, },
            body: formData
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) {
                    if (isNaN(parseInt(data))) {
                        show('error', data);
                    }
                    else {
                        show('success', "Successfully saved WSE changes");
                        navigate(
                            `/Wse/${assetId}/${data}`
                        );
                    }
                }

                setLoading(false);
                getData();
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    const backToAsset = () => {
        navigate(
            '/Asset',
            {
                state: {
                    id: assetId
                }
            }
        );
    }

    return (
        <Grid container spacing={2}>
            {viewPermission &&
                <>
                    <Grid item container alignItems="center" justifyContent="space-between" spacing={2}>
                        <Grid item>
                            <Button variant="outlined" onClick={backToAsset} startIcon={<NavigateBackIcon />}>Back to Asset</Button>
                        </Grid>
                        <Grid item sx={{ flexGrow: 1 }}>
                            <Typography variant={'h1'}>Written Schemes of Examination</Typography>
                        </Grid>
                        {inspections.ownerName && inspections.ownerName.length > 0 &&
                            <Grid item>
                                <Alert severity="warning">This report is owned by {inspections ? inspections.ownerName : ''}</Alert>
                            </Grid>
                        }
                        <PermissionCheck permission={Permission.CreateWse}>
                            {(canEdit || wseId === 0) &&
                                <Grid item>
                                    <LoadingButton loading={loading} loadingIndicator="Saving..." variant="contained" onClick={submitForm}><span>Save Changes</span></LoadingButton>
                                </Grid>
                            }
                        </PermissionCheck>
                        <Grid item>
                            <LoadingButton disabled={(wseId === null || wseId === 0)} loading={loading || downloading} loadingIndicator="Generating..." variant="contained" onClick={download}><span>Download PDF</span></LoadingButton>
                        </Grid>
                    </Grid>
                    {!loading &&
                        <>
                            <Grid item xs={12}>
                                {(wseId !== null && wseId !== 0) &&
                                    <WseTopper wseId={wseId} />
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Paper>
                                    <CustomCollapse title={'Design Information'}>
                                        <AssetDetails asset={asset} showButtons={false} />
                                    </CustomCollapse>
                                </Paper>
                            </Grid>
                            {!loading &&
                                <EqupimentData asset={asset} showButtons={false} />
                            }
                            <Grid item xs={12}>
                                {wseId !== null &&
                                    <CredibleDamageMechanisms wseId={wseId} setDamageMechanisms={setDamageMechanisms} damageMechanisms={damageMechanisms} disableFields={!canEdit} />
                                }
                            </Grid>
                            <Grid item xs={12}>
                                {wseId !== null &&
                                    <InspectionAndPreparation wseId={wseId} setInspections={setInspections} inspections={inspections} setDamageMechanisms={setDamageMechanisms} damageMechanisms={damageMechanisms} disableFields={!canEdit} />
                                }
                            </Grid>
                            <Grid item xs={12}>
                                {wseId !== null &&
                                    <AmmendmentAndDocumentHistory wseId={wseId} setHistory={setHistory} history={history} disableFields={!canEdit} />
                                }
                            </Grid>
                            <Grid item xs={12}>
                                {wseId !== null &&
                                    <AttachedFiles wseId={wseId} files={files} setFiles={setFiles} disableFields={!canEdit} />
                                }
                            </Grid>
                            {(wseId !== null && wseId > 0) &&
                                <Grid item xs={12}>
                                    <SignOff id={wseId} type={SignOffDocType.Wse} updatewseSaveButton={updateSetSaveButton} saveReport={submitForm} ownerId={inspections.ownerId} />
                                </Grid>
                            }
                            <PermissionCheck permission={Permission.CreateWse}>
                                {(canEdit || wseId === 0) &&
                                    <Grid item>
                                        <LoadingButton loading={loading} loadingIndicator="Saving..." variant="contained" onClick={submitForm}><span>Save Changes</span></LoadingButton>
                                    </Grid>
                                }
                            </PermissionCheck>
                        </>
                    }
                    {loading &&
                        <Grid item xs={12}>
                            <Paper>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <Alert variant="outlined" severity="warning">
                                            Document is saving. Do not refresh the page.
                                        </Alert>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <LinearProgress />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    }
                </>
            }
        </Grid >
    );
}

export const CheckHasPermission = async (permission: string | string[]) => {
    const user = await authService.getUser();

    if ((user && user.role && user.role === "Admin")) {
        return true;
    }
    else if (user && user.permission) {
        if (Array.isArray(permission) && user.permission.some((item: string) => permission.includes(item))) {
            return true;
        } else if (user.permission.includes(permission)) {
            return true;
        } else {
            return false;
        }
    }

    return false;
}
