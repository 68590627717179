export default class CreateAssetViewmodel {
    public client: number = 0;
    public plant: number = 0;

    public assetType: number = 0;

    public designCode: string = '';
    public description: string = '';
    public plantItemNumber: string = '';
    public serialNumber: string = '';
    public service: string = '';
    public pids: string = '';
    public pssr: boolean = false;
    public associatedReliefDevices: boolean = false;
    public dateOfFabrication: Date = new Date();

    public equipmentType: number | undefined = undefined;

    // storage tank
    public designPressure: string | undefined = undefined;
    public designTemp: string | undefined = undefined;
    public fluidService: string | undefined = undefined;
    public specificGravity: string | undefined = undefined;
    public operatingTemp: string | undefined = undefined;
    public maxFillHeight: string | undefined = undefined;
    public tankClass: number | undefined = undefined;
    public shellType: number | undefined = undefined;
    public shellMaterial: string | undefined = undefined;
    public floorType: number | undefined = undefined;
    public floorMaterial: string | undefined = undefined;
    public roofType: number | undefined = undefined;
    public roofMaterial: string | undefined = undefined;
    public internalFloatingRoof: boolean | undefined = undefined;
    public diameterTank: string | undefined = undefined;
    public heightTank: string | undefined = undefined;
    public deadWeightHatch: string | undefined = undefined;
    public pvValve: string | undefined = undefined;
    public atmosphericVent: string | undefined = undefined;
    public other: string | undefined = undefined;

    // structure
    public structureType: number | undefined = undefined;
    public otherAreaType: string | undefined = undefined;
}