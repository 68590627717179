export default class SafetyDeviceViewmodel {
    public id: number | null = null;
    public assetDetailId: number = 0;
    public assetType: number = 0;
    public name: string = '';
    public type: number = 0;
    public typeName: string = '';
    public equipmentNumber: string = '';
    public coldDifferential: string = '';
    public reliefSystemWse: string = '';
    public changeDescription: string | undefined = undefined;
}