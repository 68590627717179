import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

interface IProps {
    text: string;
    buttonText: string;
    open: boolean;
    okClick: () => void;
    close: () => void;
    changeDescription: string;
    setDescription: (value: string) => void;
    placeholder?: string;
}

export default function ChangeDialog(props: IProps) {
    const { open, close, okClick, text, buttonText, changeDescription, setDescription, placeholder } = props;

    const cancel = () => {
        close();
    }

    const clickedOk = () => {
        if (okClick) {
            okClick();
        }
    }
        
    const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && okClick) {
            okClick();
        }
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={close}
            aria-labelledby="change dialog"
            onKeyDown={handleEnterKeyPress}
        >
            <DialogTitle>{text}</DialogTitle>
            <DialogContent>
                <TextField required fullWidth multiline rows={3}
                    value={changeDescription}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder={placeholder}
                />
                <DialogActions>
                    <Button onClick={cancel} variant="outlined" color="error">Cancel</Button>
                    <Button variant="contained" color="primary" onClick={clickedOk} disabled={changeDescription.length <= 0}>{buttonText}</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}