import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import authService from '../api-authorization/AuthorizeService';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ResponseType, TokenRefreshContext } from './TokenRefreshContext';
import { AlertContext } from './AlertContext';

type ContextType = {

};

type TermsCondsProviderProps = { children: React.ReactNode };

export const TermsCondsContext = React.createContext<ContextType>({});

export default function TermsCondsProvider({ children }: TermsCondsProviderProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const navigate = useNavigate();
    const [openTCs, setOpenTCs] = React.useState(false);
    const [confirmedTCs, setConfirmedTCs] = React.useState(false);
    const page = window.location.pathname;
    const authSubscription = authService.subscribe(() => checkTCs());

    React.useEffect(() => {
        if (!(page.includes('RegisterConfirmation') || page.includes('/authentication') || page.includes('/Account')))
            checkTCs();
    }, [page]);

    React.useEffect(() => {
        return () => {
            authService.unsubscribe(authSubscription);
        }
    }, []);

    const checkTCs = async () => {
        const authenticated = await authService.isAuthenticated();

        if (!authenticated) {
            navigate(ApplicationPaths.Login);
        }
        else {
            const token = await authService.getAccessToken();
            crabFetch('User/GetConfirmedTCs', {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            }, ResponseType.JSON,
                (data: any) => {
                    setOpenTCs(!data);
                    setConfirmedTCs(data);
                }
            );
        }
    }

    const closeTCs = (event: any, reason: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        else {
            setOpenTCs(false);
            navigate(`${ApplicationPaths.LogOut}`, {
                state: {
                    local: true
                }
            });
        }
    }

    const confirmTCs = async () => {
        const token = await authService.getAccessToken();
        crabFetch('User/ConfirmTCs', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    setConfirmedTCs(true);
                    setOpenTCs(false);
                }
            }
        );
    }

    return (
        <TermsCondsContext.Provider value={{}}>
            <Dialog
                open={openTCs}
                onClose={closeTCs}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>End-User License Agreement (EULA) for the TesTex Asset Integrity Management System</DialogTitle>
                <DialogContent>
                    <Typography gutterBottom><b>THIS IS A LEGAL AGREEMENT</b> between you ("User" or "You") and TesTex NDT Ltd ("Company", "We", "Us" or "Our"), the provider of the TesTex Asset Integrity Management System ("Software", "Service", or "Product").</Typography>
                    <Typography gutterBottom>By clicking the "I Agree" button or by using Our Software, You are agreeing to be bound by the terms of this End-User License Agreement (EULA). If You do not agree to the terms of this EULA, do not use the Software.</Typography>
                    <Typography variant="body1" fontWeight="bold">1. GRANT OF LICENSE</Typography>
                    <Typography gutterBottom>Subject to Your compliance with this EULA, We hereby grant You a non-exclusive, non-transferable, revocable, limited license to access and use the Software over the Internet.</Typography>
                    <Typography variant="body1" fontWeight="bold">2. USE RESTRICTIONS</Typography>
                    <Typography gutterBottom>You shall not reverse engineer, decompile, or disassemble the Software, nor attempt in any other manner to obtain the source code. You shall not make any modification, adaptation, improvement, enhancement, translation or derivative work from the Software.</Typography>
                    <Typography variant="body1" fontWeight="bold">3. INTELLECTUAL PROPERTY RIGHTS</Typography>
                    <Typography gutterBottom>The Software, and all Intellectual Property Rights therein, are and shall remain Our property. This EULA does not convey to You any interest in or to the Software, but only a limited right of use revocable in accordance with the terms of this EULA.</Typography>
                    <Typography variant="body1" fontWeight="bold">4. USER RESPONSIBILITIES</Typography>
                    <Typography gutterBottom>You are responsible for all activity occurring under Your user account and shall abide by all applicable local, state, national, and foreign laws, treaties and regulations in connection with Your use of the Software, including those related to data privacy, international communications, and the transmission of technical or personal data.</Typography>
                    <Typography variant="body1" fontWeight="bold">5. CONFIDENTIALITY</Typography>
                    <Typography gutterBottom>Both parties agree to maintain the confidentiality of the other's Confidential Information.</Typography>
                    <Typography variant="body1" fontWeight="bold">6. DATA PRIVACY</Typography>
                    <Typography gutterBottom>You agree to the terms of Our privacy policy, which is available at <a target="_blank" href="">privacy policy link</a> and is incorporated herein by reference.</Typography>
                    <Typography variant="body1" fontWeight="bold">7. TERMINATION</Typography>
                    <Typography gutterBottom>We reserve the right, in our sole discretion, to terminate or suspend Your access to all or any part of the Software at any time, with or without notice, for any reason, including, without limitation, breach of this EULA.</Typography>
                    <Typography variant="body1" fontWeight="bold">8. WARRANTY DISCLAIMER</Typography>
                    <Typography gutterBottom>The Software is provided "as is" without warranty of any kind, express or implied.</Typography>
                    <Typography variant="body1" fontWeight="bold">9. LIMITATION OF LIABILITY</Typography>
                    <Typography gutterBottom>Under no circumstances shall We be liable for any indirect, special, incidental or consequential damages of any kind, regardless of the form of action.</Typography>
                    <Typography variant="body1" fontWeight="bold">10. GOVERNING LAW</Typography>
                    <Typography gutterBottom>This EULA will be governed by and construed in accordance with the laws of the United Kingdom.</Typography>
                    <Typography variant="body1" fontWeight="bold">11. ENTIRE AGREEMENT</Typography>
                    <Typography gutterBottom>This EULA constitutes the entire agreement between You and Us with respect to the use of the Software.</Typography>
                    <Typography gutterBottom>By clicking the "I Agree" button, you are confirming that you have read and understand this EULA and agree to be bound by its terms.</Typography>
                    <Typography gutterBottom variant="body1" fontWeight="bold">Date: 08/08/2023</Typography>
                    <Typography variant="body1" fontWeight="bold">TesTex NDT Ltd</Typography>
                    <DialogActions>
                        <Button color="error" onClick={() => closeTCs(null, '')}>Decline</Button>
                        <Button variant="contained" onClick={confirmTCs}>Accept</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            {(page.includes('/authentication') || page.includes('/Account') || page.includes('/RegisterConfirmation') || confirmedTCs) && children}
        </TermsCondsContext.Provider>
    );
}