import * as React from 'react';
import Grid from '@mui/material/Grid';
import PermissionCheck from '../Utilities/PermissionCheck';
import { Permission } from '../Permissions/Permissions';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import SignOffViewmodel from './Viewmodels/SignOffViewmodel';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { AlertContext } from '../Contexts/AlertContext';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import Alert from '@mui/material/Alert/Alert';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';

interface IProps {
    signOff: SignOffViewmodel;
    refresh: () => void;
}

export default function Acknowledge(props: IProps) {
    const { signOff, refresh } = props;
    const { show } = React.useContext(AlertContext);
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [loading, setLoading] = React.useState(false);
    const [userId, setUserId] = React.useState<string>('');

    React.useEffect(() => {
        getUser();
    }, []);

    const getUser = async () => {
        const signedInUser = await authService.getUser();
        setUserId(signedInUser.sub);
    }

    const submit = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`${signOff.docType}/Acknowledge?id=${signOff.id}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) {
                    show('error', data);
                }
                else {
                    show('success', `Successfully acknowledged ${signOff.docType}`);
                    refresh();
                }

                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    return (
        <React.Fragment>
            {!loading &&
                <Grid container spacing={2}>
                    {signOff.acknowledgedBy.length === 0 ?
                        <PermissionCheck permission={Permission.AcknowledgeDocument}>
                            {signOff.ableClients.includes(userId) &&
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <Typography>Client Acknowledgement</Typography>
                                    </Grid>
                                    <Grid item>
                                        <LoadingButton variant="contained" loading={loading} loadingIndicator="Confirming..." onClick={submit}>Acknowledge Receipt</LoadingButton>
                                    </Grid>
                                </React.Fragment>
                            }
                        </PermissionCheck>
                        :
                        <React.Fragment>
                            <Grid item xs={4}>
                                <InputLabel htmlFor="acknowledgedBy" shrink>Acknowledged By</InputLabel>
                                <TextField variant="outlined" size="small" disabled fullWidth value={signOff.acknowledgedBy} />
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel htmlFor="acknowledgedDate" shrink>Acknowledged Date</InputLabel>
                                <TextField variant="outlined" size="small" disabled fullWidth value={new Date(signOff.acknowledgedDate).toLocaleDateString('en-gb')} />
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel htmlFor="acknowledgedSig" shrink>Signature</InputLabel>
                                <Box sx={{ width: '100%', height: '37px', backgroundColor: '#F2F4F8', borderRadius: '10px' }}>
                                    <img aria-label="acknowledged by signature" style={{ height: '100%' }} src={signOff.acknowledgedSig} />
                                </Box>
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            }
            {loading &&
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Alert variant="outlined" severity="warning">
                            Document is saving. Do not refresh the page.
                        </Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3">Saving Acknowledgement... Generating Document PDF...</Typography>
                    </Grid>
                </Grid>

            }
        </React.Fragment>
    );
}