import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import AssetListViewmodel from './Viewmodels/AssetListViewmodel';
import { useNavigate } from 'react-router-dom';
import { AlertContext } from '../Contexts/AlertContext';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import { AlertColours } from '../Utilities/AlertColours';
import PermissionCheck from '../Utilities/PermissionCheck';
import { Permission } from '../Permissions/Permissions';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import CloseDialogConfirmation from '../Utilities/CloseDialogConfimation';

interface IProps {
	row: AssetListViewmodel;
	refresh: () => void;
}

export default function AssetsTableRow({ row, refresh }: IProps) {
    const navigate = useNavigate();
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);

	const viewAsset = () => {
		navigate(
			'/Asset',
			{
				state: {
					id: row.id
				}
			}
		);
	}

	const toggleArchive = async () => {
		const token = await authService.getAccessToken();
		const method = row.archived ? 'UnArchiveAsset' : 'ArchiveAsset';

		crabFetch(`Asset/${method}?id=${row.id}`, {
			method: 'POST',
			headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
		}, ResponseType.Text,
			(data: any) => {
				if (data.length > 0) show('error', data);
				else {
					show('success', `Successfully ${row.archived ? 're-enabled' : 'archived'} asset`);
					refresh();
				}
			},
			(error: any) => {
				show('error', error);
			}
		);
	}

	const chipColour = React.useMemo(() => {
		if (row.nextInspectionDue) {
			var date = new Date(row.nextInspectionDue);
			var today = new Date();
			today.setHours(0, 0, 0, 0);

			var alertDate = new Date(today);
			alertDate.setDate(alertDate.getDate() + row.alertDays);

			if ((date >= today && date >= alertDate) || row.inspectionStatus === 'Reporting')
				return AlertColours.low;
			else if (date <= alertDate && date >= today)
				return AlertColours.medium;
			else
				return AlertColours.high;
		}
		else {
			return '';
		}
	}, [row.nextInspectionDue]);

	const close = (refresh: boolean) => {
		setOpenConfirmation(false);
		if (refresh) {
			toggleArchive();
		}
	}

	return (
		<TableRow>
			<TableCell>{row.plantItemNumber}</TableCell>
			<TableCell>{row.assetType}</TableCell>
			<TableCell>{row.description}</TableCell>
			<TableCell>{row.client}</TableCell>
			<TableCell>{row.plant}</TableCell>
			<TableCell>{(row.nextInspectionDue && row.inspectionStatus) ? <Chip label={new Date(row.nextInspectionDue).toLocaleDateString('en-gb')} sx={{ backgroundColor: chipColour }} /> : ''}</TableCell>
			<TableCell align="right">
				<Grid container spacing={1} justifyContent="flex-end">
					<PermissionCheck permission={Permission.DeleteClients}>
						<Grid item>
							<Button variant="contained" size="small" onClick={() => setOpenConfirmation(true)}>{!row.archived ? "Archive" : "Unarchive"}</Button>
						</Grid>
					</PermissionCheck>
					<Grid item>
						<Button variant="contained" size="small" onClick={viewAsset}>View</Button>
					</Grid>
				</Grid>
			</TableCell>
			<CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'Are you sure you want to' + ` ${row.archived ? 're-enabled' : 'archive'} ${row.plantItemNumber}?`} open={openConfirmation} onClose={close}></CloseDialogConfirmation>
		</TableRow>
	);
}