import * as React from "react";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CloudIcon from '@mui/icons-material/CloudUpload';
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
//import { AlertContext } from "../Contexts/AlertContext";

interface IProps {
    onDrop: (files: File[]) => void;
    onDelete: (index: number) => void;
    files: File[];
    acceptedTypes?: string;
}

const boxStyle = [{
    display: 'flex',
    p: 2,
    bgcolor: 'grey.200',
    width: '100%',
    height: '100%',
    border: '2px dashed grey',
    borderColor: 'grey.500',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center'
},
{
    '&:hover': {
        bgcolor: 'grey.300',
    },
}];

export default function FileUpload(props: IProps) {
    const { onDrop, onDelete, files, acceptedTypes } = props;
    //const { show } = React.useContext(AlertContext);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            let newFiles = [...event.target.files];

            //Limit file size to 4GB
            //newFiles = newFiles.filter(f => f.size <= (4 * 1024 * 1024 * 1024));

            //if (newFiles.length < event.target.files.length) {
            //    show('warning', "Files greater than 4GB have been removed.")
            //}

            onDrop(newFiles);
        }
    }

    const dragEvents = {
        onDragEnter: (event: React.DragEvent<HTMLElement>) => {
            event.stopPropagation();
            event.preventDefault();
        },
        onDragLeave: (event: React.DragEvent<HTMLElement>) => {
            event.stopPropagation();
            event.preventDefault();
        },
        onDragOver: (event: React.DragEvent<HTMLElement>) => {
            event.stopPropagation();
            event.preventDefault();
        },
        onDrop: (event: React.DragEvent<HTMLElement>) => {
            event.stopPropagation();
            event.preventDefault();
            onDrop([...event.dataTransfer.files]);
        }
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <input
                    onChange={handleChange}
                    style={{ display: 'none' }}
                    id="file-upload"
                    type="file"
                    multiple
                    accept={acceptedTypes}
                />
                <label htmlFor="file-upload" {...dragEvents} >
                    <Box sx={boxStyle}>
                        <Stack alignItems="center">
                            <CloudIcon color="action" />
                            <Typography>Click or Drag & Drop to Upload Files</Typography>
                        </Stack>
                    </Box>
                </label>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {files.map((item, index) =>
                        <Grid item key={index}>
                            <Chip label={item.name} onDelete={() => onDelete(index)} />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}