import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { Button, Divider, IconButton, TextField, Typography } from '@mui/material';
import CustomCollapse from '../Utilities/CustomCollapse';
import DeleteIcon from '@mui/icons-material/Delete';
import FileViewModel from './Viewmodels/FileViewModel';
import UploadFileDialog from './UploadFileDialog';
import Download from '@mui/icons-material/Download';
import PermissionCheck from '../Utilities/PermissionCheck';
import { Permission } from '../Permissions/Permissions';

interface IProps {
    wseId: number;
    setFiles: (history: FileViewModel[]) => void;
    files: FileViewModel[];
    disableFields: boolean;
}

export default function AttachedFiles(props: IProps) {
    const { wseId, files, setFiles, disableFields } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [loading, setLoading] = React.useState(false);
    const [collapse, setCollapse] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        await crabFetch(`Wse/GetFiles?wseId=${wseId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setFiles(data);
            }
        );
        setLoading(false);
    }

    const removeRow = (index: number) => {
        const newArr = [...files];
        newArr.splice(index, 1);
        setFiles(newArr);
    }

    const retrunFile = (newFile: FileViewModel) => {
        const newArr = [...files];
        newArr.push(newFile);
        setFiles(newArr);
    }

    const closeDialog = (refresh: boolean) => {
        setOpen(false);
    }

    const download = async (id: number, name: string) => {
        if (id === 0) {
            return;
        }
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Wse/DownloadFile?id=${id}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Blob,
            ((blob: Blob) => {
                const url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.download = name;
                a.href = url;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
                getData();
            })
        );
    }

    return (
        <Paper sx={{ padding: 2 }}>
            <CustomCollapse showMore={collapse} setShowMore={setCollapse} title={'Attached Files'} count={disableFields ? files.length : undefined}>
                {!loading && files.length > 0 &&
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={4.5}>
                                <Typography>File Name</Typography>
                            </Grid>
                            <Grid item xs={7.5}>
                                <Typography>Description</Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} sx={{ pt: 1 }}>
                            {files.map((file: FileViewModel, index: number) => (
                                <Grid container item spacing={2}>
                                    <Grid item xs={3.5}>
                                        <Typography sx={{ pl: 1 }}>{file.name !== null ? file.name : file.attachment !== null ? file.attachment.name : ""}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton disabled={disableFields} onClick={() => download(file.id === null ? 0 : file.id, file.name !== null ? file.name : file.attachment !== null ? file.attachment.name : "")}><Download /></IconButton>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <TextField disabled={disableFields} name="description" value={file.description} required fullWidth size="small" />
                                    </Grid>
                                    <PermissionCheck permission={Permission.CreateWse}>
                                        <Grid item xs={0.5}>
                                            <IconButton disabled={disableFields} onClick={() => removeRow(index)}><DeleteIcon /></IconButton>
                                        </Grid>
                                    </PermissionCheck>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                }
                <PermissionCheck permission={Permission.CreateWse}>
                    {!disableFields &&
                        <Grid sx={{ pt: 1 }}>
                            <Button onClick={() => setOpen(true)} variant="contained">Upload File</Button>
                        </Grid>
                    }
                </PermissionCheck>
            </CustomCollapse>
            <UploadFileDialog open={open} onClose={closeDialog} returnFile={retrunFile}></UploadFileDialog>
        </Paper >
    );
}