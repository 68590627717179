import * as React from 'react';
import { Control, Controller, RadioButtonGroup } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import InspectionReportViewmodel from './Viewmodels/InspectionReportViewmodel';
import { formats, modules } from '../Utilities/RichTextConfig';

interface IProps {
    editing: boolean;
    showReliefQuestions: boolean;
    control: Control<InspectionReportViewmodel, any>;
}

export default function InspectionFindings(props: IProps) {
    const { editing, showReliefQuestions, control } = props;

    return (
        <Grid container spacing={2}>
            {showReliefQuestions &&
                <React.Fragment>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="reliefSystemInspected" shrink>Was the relief system inspected as part of this examination?</InputLabel>
                        <RadioButtonGroup name="reliefSystemInspected" row disabled={!editing}
                            options={[{
                                id: 'true',
                                label: 'Yes'
                            }, {
                                id: 'false',
                                label: 'No'
                            }]} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="reliefSystemInDate" shrink>If no, confirm the relief system has been inspected and remains in date?</InputLabel>
                        <RadioButtonGroup name="reliefSystemInDate" row disabled={!editing}
                            options={[{
                                id: 'true',
                                label: 'Yes'
                            }, {
                                id: 'false',
                                label: 'No'
                            }]} />
                    </Grid>
                </React.Fragment>
            }
            <Grid item xs={12}>
                <InputLabel htmlFor="detailedReport" shrink>Detailed Report</InputLabel>
                <Controller
                    control={control}
                    name="detailedReport"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactQuill
                            modules={modules}
                            formats={formats}
                            onChange={onChange} // send value to hook form
                            onBlur={onBlur} // notify when input is touched/blur
                            readOnly={!editing}
                            value={value}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <InputLabel htmlFor="ndtSummary" shrink>NDT Summary</InputLabel>
                <Controller
                    control={control}
                    name="ndtSummary"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactQuill
                            modules={modules}
                            formats={formats}
                            onChange={onChange} // send value to hook form
                            onBlur={onBlur} // notify when input is touched/blur
                            readOnly={!editing}
                            value={value}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <InputLabel htmlFor="wseChanges" shrink>WSE Changes</InputLabel>
                <InputLabel htmlFor="wseChanges" shrink>Specify any required changes to WSE resulting from this inspection.</InputLabel>
                <Controller
                    control={control}
                    name="wseChanges"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactQuill
                            modules={modules}
                            formats={formats}
                            onChange={onChange} // send value to hook form
                            onBlur={onBlur} // notify when input is touched/blur
                            readOnly={!editing}
                            value={value}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
}