import * as React from 'react';
import { SelectElement, TextFieldElement } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import DropdownViewModel from '../../Utilities/ViewModels/DropdownViewModel';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

interface IProps {
    editing: boolean;
}

export default function CreateStructure(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { editing } = props;
    const [loading, setLoading] = React.useState(false);
    const [structureTypes, setStructureTypes] = React.useState<DropdownViewModel[]>([]);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Base/GetDropdown?lookup=StructureType`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setStructureTypes(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="h6">Design Data</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="structureType" shrink>Structure Type</InputLabel>
                <SelectElement
                    name="structureType"
                    required
                    fullWidth
                    size="small"
                    options={structureTypes}
                    InputProps={{
                        disabled: loading || !editing,
                        startAdornment: (
                            !loading ? null : <CircularProgress size={20} />
                        )
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="otherAreaType" shrink>Other/Area Inspection Type</InputLabel>
                <TextFieldElement name="otherAreaType" required fullWidth size="small" disabled={!editing} />
            </Grid>
        </React.Fragment>
    );
}