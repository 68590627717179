import * as React from 'react';
import Grid from '@mui/material/Grid';
import PermissionCheck from '../Utilities/PermissionCheck';
import { Permission } from '../Permissions/Permissions';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import SignOffViewmodel, { ApprovalStatus } from './Viewmodels/SignOffViewmodel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { AlertContext } from '../Contexts/AlertContext';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import Alert from '@mui/material/Alert/Alert';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import EngineerViewModel from './Viewmodels/EngineersViewModel';

interface IProps {
    signOff: SignOffViewmodel;
    refresh: () => void;
    wseId: number;
}

export default function Certify(props: IProps) {
    const { signOff, refresh, wseId } = props;
    const { show } = React.useContext(AlertContext);
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [level, setLevel] = React.useState<string | number>('');
    const [reason, setReason] = React.useState<string>('');
    const [user, setUser] = React.useState<string>('');
    const [users, setUsers] = React.useState<DropdownViewModel[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [userId, setUserId] = React.useState<string>('');
    const [engineers, setEngineers] = React.useState<EngineerViewModel>(new EngineerViewModel());

    React.useEffect(() => {
        getUsers();
    }, []);

    React.useEffect(() => {
        setUser('');
    }, [level]);

    const getUsers = async () => {
        const token = await authService.getAccessToken();
        const signedInUser = await authService.getUser();
        setUserId(signedInUser.sub);
        setLoading(true);

        crabFetch(`User/GetSignOffDropDown?type=${level === 0 && signOff.docType === 'Wse' ? 'wse' : level === 0 ? 'report' : 'escalate'}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setUsers(data);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );

        crabFetch(`Wse/GetEngineers?wseId=${wseId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setEngineers(data);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const submit = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`${signOff.docType}/Certify?id=${signOff.id}&action=${level}&reason=${reason}&certifier=${user}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) {
                    show('error', data);
                }
                else {
                    if (level === 1)
                        show('success', `Successfully rejected ${signOff.docType}`);
                    else if (level === 2)
                        show('success', `Successfully escalated ${signOff.docType}`);
                    else
                        show('success', `Successfully certified ${signOff.docType}`);

                    refresh();
                }

                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    return (
        <React.Fragment>

            {!loading &&
                <Grid container spacing={2}>
                    {signOff.certifiedStatus == null || signOff.certifiedStatus !== ApprovalStatus.Approved ?
                        signOff.certifiedById === userId &&
                        <React.Fragment>
                            {signOff.certifiedStatus === ApprovalStatus.Escalated ?
                                <PermissionCheck permission={Permission.SignOffEscalatedDocument}>
                                    <Grid item xs={12}>
                                        <Typography>Certification</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <TextField variant="outlined" fullWidth select size="small"
                                            placeholder="Select outcome..."
                                            value={level}
                                            onChange={(e) => setLevel(e.target.value)}
                                        >
                                            <MenuItem value={0}>Certified</MenuItem>
                                            <MenuItem value={1}>Reject</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs>
                                        <TextField variant="outlined" fullWidth size="small"
                                            placeholder="Please provide reasons..."
                                            value={reason}
                                            onChange={(e) => setReason(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs="auto">
                                        <LoadingButton variant="contained" disabled={level === ''} loading={loading} loadingIndicator="Confirming..." onClick={submit}>Confirm Decision</LoadingButton>
                                    </Grid>
                                </PermissionCheck> :
                                <PermissionCheck permission={Permission.SignOffWse}>
                                    <Grid item xs={12}>
                                        <Typography>Certification</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <TextField variant="outlined" fullWidth select size="small"
                                            placeholder="Select outcome..."
                                            value={level}
                                            onChange={(e) => setLevel(e.target.value)}
                                        >
                                            <MenuItem value={0}>Certified</MenuItem>
                                            <MenuItem value={2}>Escalate to Category 1</MenuItem>
                                            <MenuItem value={1}>Reject</MenuItem>
                                        </TextField>
                                    </Grid>
                                    {level === 2 &&
                                        <Grid item xs={6} sm={4}>
                                            <TextField variant="outlined" size="small" fullWidth select value={user} onChange={(e) => setUser(e.target.value)}>
                                                {users.map(item =>
                                                    <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                                                )}
                                            </TextField>
                                        </Grid>
                                    }
                                    {level !== 0 &&
                                        <Grid item xs>
                                            <TextField variant="outlined" fullWidth size="small"
                                                placeholder="Please provide reasons..."
                                                value={reason}
                                                onChange={(e) => setReason(e.target.value)}
                                            />
                                        </Grid>
                                    }

                                    <Grid item xs="auto">
                                        <LoadingButton variant="contained" disabled={level === '' || (level === 2 && user === '')} loading={loading} loadingIndicator="Confirming..." onClick={submit}>Confirm Decision</LoadingButton>
                                    </Grid>
                                </PermissionCheck>
                            }
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Grid item xs={4}>
                                <InputLabel htmlFor="certifiedBy" shrink>Certified By</InputLabel>
                                <TextField variant="outlined" size="small" disabled fullWidth value={signOff.certifiedBy} />
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel htmlFor="certifiedDate" shrink>Certified Date</InputLabel>
                                <TextField variant="outlined" size="small" disabled fullWidth value={new Date(signOff.certifiedDate).toLocaleDateString('en-gb')} />
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel htmlFor="certifiedSig" shrink>Signature</InputLabel>
                                <Box sx={{ width: '100%', height: '37px', backgroundColor: '#F2F4F8', borderRadius: '10px' }}>
                                    <img aria-label="certified by signature" style={{ height: '100%' }} src={signOff.certifiedSig} />
                                </Box>
                            </Grid>
                            {signOff.acknowledgedBy.length !== 0 &&
                                <>
                                    <Grid item xs={6}>
                                        <InputLabel htmlFor="certifiedBy" shrink>Nominated Engineer</InputLabel>
                                        <TextField variant="outlined" size="small" disabled fullWidth value={engineers.nomEngineer} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel htmlFor="certifiedBy" shrink>Deputy Engineer</InputLabel>
                                        <TextField variant="outlined" size="small" disabled fullWidth value={engineers.deptEngineer} />
                                    </Grid>

                                </>

                            }

                        </React.Fragment>
                    }
                </Grid>
            }
            {loading &&
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Alert variant="outlined" severity="warning">
                            Document is saving. Do not refresh the page.
                        </Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3">Saving Certification... Generating Document PDF...</Typography>
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    );
}