import * as React from 'react';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

interface IProps {
    showMore?: boolean;
    setShowMore?: (show: boolean) => void;
    title: string;
    children: React.ReactNode;
    count?: number;
}

export default function CustomCollapse(props: IProps) {
    const { showMore, setShowMore, title, children, count } = props;
    const [toggleSelf, setToggleSelf] = React.useState(false);

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <ButtonBase disabled={(count !== undefined && count === 0)} disableRipple onClick={setShowMore ? () => setShowMore(!showMore) : () => setToggleSelf(!toggleSelf)} sx={{ width: '100%' }}>
                    <Grid container justifyContent="space-between" spacing={2}>
                        <Grid item>
                            <Typography variant={"h2"} >{title}</Typography>
                        </Grid>
                        {(count === undefined || (count !== undefined && count > 0)) &&
                            <Grid item>
                                <ExpandMoreIcon sx={{ transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms', transform: showMore ? 'rotate(180deg)' : '' }} />
                            </Grid>
                        }
                        {(count !== undefined && count === 0) &&
                            <Grid item>
                                <Typography color="gray">[No content Added]</Typography>
                            </Grid>
                        }
                    </Grid>
                </ButtonBase>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={showMore === undefined ? toggleSelf : showMore}>
                    {children}
                </Collapse>
            </Grid>
        </React.Fragment>
    );
}