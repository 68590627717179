import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import authService from '../components/api-authorization/AuthorizeService';
import Button from '@mui/material/Button';
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import Grid from '@mui/material/Grid';
import PersonRounded from '@mui/icons-material/PersonRounded';
import PeopleRounded from '@mui/icons-material/PeopleRounded';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PermissionCheck from './Utilities/PermissionCheck';
import { Permission } from './Permissions/Permissions';
import ManageAccounts from '@mui/icons-material/ManageAccounts';
import BusinessIcon from '@mui/icons-material/Business';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import HomeIcon from '@mui/icons-material/Home';
import { TokenRefreshContext, ResponseType } from './Contexts/TokenRefreshContext';
import { FilterContext } from './Contexts/FilterContext';

const drawerWidth = 240;

const styles = {
	menuItem: {
		justifyContent: 'flex-end'
	},
	logoutMenuItem: {
		justifyContent: 'flex-end',
		color: '#F34519'
	}
}

const StyledListItem = styled(ListItem)(
	({ theme }) => `
            color: ${theme.palette.text.primary};

                :hover { color: ${theme.palette.primary.main} };

            & .MuiListItemText-primary {
                color: inherit !important;
            }

            & .MuiListItemIcon-root {
                color: inherit
            }

            & .MuiSvgIcon-root {
                color: inherit !important
            }

            & .MuiIconButton-root {
                color: inherit
            }
    `);

interface IProps extends ListItemButtonProps {
	to: string;
	label: string;
	icon?: any;
	target?: any;
}

function ListItemLink(props: IProps) {
	const { to, label, icon, target, ...other } = props;
	const navigate = useNavigate();
	const { updateClient, updatePlant } = React.useContext(FilterContext);

	const goTo = () => {
		updateClient(0);
		updatePlant(0);
		navigate(to);
	}

	return (
		<StyledListItem>
			<ListItemButton onClick={goTo} {...other} disableGutters>
				{icon && <ListItemIcon sx={{ minWidth: 44 }}>{icon}</ListItemIcon>}
				<ListItemText primary={label} />
			</ListItemButton>
		</StyledListItem>
	);
}

export default function Navmenu() {
	const navigate = useNavigate();
	const { crabFetch } = React.useContext(TokenRefreshContext);
	const [role, setRole] = React.useState('');
	const [authenticated, setAuthenticated] = React.useState(false);
	const [userName, setUserName] = React.useState('');
	const [userId, setUserId] = React.useState('');
	const [clientLogo, setClientLogo] = React.useState<string | undefined>(undefined);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const authSubscription = authService.subscribe(() => updateAuth());

	React.useEffect(() => {
		updateAuth();

		return () => {
			authService.unsubscribe(authSubscription);
		}
	}, []);

	const updateAuth = async () => {
		const isAuth = await authService.isAuthenticated();
		const user = await authService.getUser();

		if (user && user.sub) {
			setUserId(user.sub);
			setUserName(user ? user.name : '');
			setRole(user.role ? user.role : user.userType ? user.userType : '');

			if (user.userType === "Client") {
				getLogo();
			}
		}
		setAuthenticated(isAuth);
	}

	const getLogo = async () => {
		const token = await authService.getAccessToken();

		crabFetch(`Client/GetClientLogoForUser`, {
			headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
		}, ResponseType.JSON,
			(data) => {
				setClientLogo(data.logo);
			},
			() => {
			}
		);
	}

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const clickProfile = () => {
		handleClose();
		navigate("/Profile", {
			state: {
				userId: userId
			}
		});
	}

	const clickLogout = () => {
		handleClose();
		navigate(`${ApplicationPaths.LogOut}`, {
			state: {
				local: true
			}
		});
	}

	return (
		<React.Fragment>
			<AppBar position="fixed" color="primary" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, padding: 0, border: 'none' }} elevation={0}>
				<Toolbar>
					<Link component={RouterLink} to="/" variant="h1" color="inherit" underline="none" alignItems="center" sx={{ m: 1 }}>
						<Grid container alignItems="center">
							<Grid item pr={2}>
								<img height="48" src={clientLogo ? "data:image/jpeg;base64," + clientLogo : "/testexlogo-white.png"} />
							</Grid>
							<Grid item>
								Asset Integrity Management
							</Grid>
						</Grid>
					</Link>
					<div style={{ flexGrow: 1 }} />
					{authenticated &&
						<Button disabled={!authenticated} color="inherit" size="large" aria-label="open account menu" endIcon={<PersonRounded />} onClick={handleClick}>
							<Grid container style={{ textAlign: "right", paddingRight: 8 }}>
								<Grid item xs={12}>
									<Typography variant="h6">{userName}</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="caption">{role}</Typography>
								</Grid>
							</Grid>
						</Button>
					}
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				anchor="left"
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					[`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', border: 'none', padding: 0 },
				}}
			>
				<Toolbar />
				<Box sx={{ overflow: 'auto' }}>
					<List id="navmenu">
						{authenticated &&
							<React.Fragment>
								<ListItemLink to='/' label='Dashboard' icon={<HomeIcon fontSize="small" />} />
								<PermissionCheck permission={Permission.ViewClients}>
									<ListItemLink to='/Clients' label='Clients' icon={<AccountCircleIcon fontSize="small" />} />
								</PermissionCheck>
								<PermissionCheck permission={Permission.ViewPlants}>
									<ListItemLink to='/Plants' label='Plants' icon={<BusinessIcon fontSize="small" />} />
								</PermissionCheck>
								<PermissionCheck permission={Permission.ViewAssets}>
									<ListItemLink to='/Assets' label='Assets' icon={<ViewStreamIcon fontSize="small" />} />
								</PermissionCheck>
								<PermissionCheck permission={Permission.ManageUsers}>
									<ListItemLink to='/ManageUsers' label='Manage Users' icon={<PeopleRounded fontSize="small" />} />
								</PermissionCheck>
								{(role === 'Admin') &&
									<ListItemLink to='/ManageUserTypes' label='Manage User Types' icon={<ManageAccounts fontSize="small" />} />
								}
								<ListItemLink to='/Profile' label='Profile' icon={<PeopleRounded fontSize="small" />} />
							</React.Fragment>
						}
					</List>
				</Box>
				<Link title="Smashed Crab Software" href="https://www.smashedcrabsoftware.com/" target="_blank" textAlign="center"
					sx={{ mt: 'auto', pb: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
				>Built by <img style={{ paddingLeft: 8 }} alt="Smashed Crab Software" src="/crab.png" /></Link>
			</Drawer>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				{authenticated &&
					<MenuItem onClick={clickProfile} sx={styles.menuItem}>Profile</MenuItem>
				}
				<MenuItem onClick={clickLogout} sx={styles.logoutMenuItem}>Logout</MenuItem>

			</Menu>
		</React.Fragment>
	);
}
