import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { DatePickerElement, FormContainer, SelectElement, TextFieldElement, useForm } from 'react-hook-form-mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import { AlertContext } from '../Contexts/AlertContext';
import FileUpload from '../Utilities/FileUpload';
import { UtilityMethods } from '../Utilities/UtilityMethods';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';
import CloseDialogConfirmation from '../Utilities/CloseDialogConfimation';
import CreateTechLibViewModel from './Viewmodels/CreateTechLibViewModel';
import Checkbox from '@mui/material/Checkbox';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import LoadingButton from '@mui/lab/LoadingButton';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    assetId: number;
}

export default function UploadTechLibFile(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose, assetId } = props;
    const [file, setFile] = React.useState<File[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [openConfimation, setOpenConfimation] = React.useState(false);
    const [newClient] = React.useState<CreateTechLibViewModel>(new CreateTechLibViewModel());
    const [dropdown, setDropdown] = React.useState<DropdownViewModel[]>([]);
    const [openWseSection, setOpenWseSection] = React.useState(false);

    const [liveWse, setLiveWse] = React.useState(false);
    React.useEffect(() => {
        reset(newClient);
    }, [newClient]);

    React.useEffect(() => {
        getData();
    }, []);

    React.useEffect(() => {
        if (open === false) {
            setOpenWseSection(false);
            setFile([]);
        }
    }, [open]);

    const formContext = useForm<CreateTechLibViewModel>({
        defaultValues: new CreateTechLibViewModel()
    });

    const { reset } = formContext;

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        await crabFetch(`Asset/GetFileTypesDropDown?id=${assetId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setDropdown(data);
                setLoading(false);
            }
        );
    }

    const submit = async (form: any, event?: React.BaseSyntheticEvent) => {
        event?.preventDefault();

        const token = await authService.getAccessToken();

        setLoading(true);

        if (file.length > 0)
            form.file = file[0];

        if (form.approvalDate) {
            const offset = form.approvalDate.utcOffset();
            var vp = form.approvalDate.add(offset, 'm');
            form.approvalDate = vp;
        }

        if (openWseSection) {
            form.liveWse = liveWse;
        }
        form.assetId = assetId;

        let formData = new FormData();
        UtilityMethods.convertModelToFormData(form, formData);

        crabFetch(`Asset/AddFile`, {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            body: formData
        }, ResponseType.Response,
            (response: Response) => {
                if (response.ok) {
                    show('success', 'Successfully created');
                    onClose(true);
                }
                else {
                    response.text()
                        .then((data: string) => {
                            show('error', data);
                        });
                }

                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    const onFileDrop = (newFiles: File[]) => {
        let existingFiles = [...file];
        existingFiles = existingFiles.concat(newFiles);
        setFile(existingFiles);
    }

    const onFileDelete = (index: number) => {
        const existingFiles = [...file];
        existingFiles.splice(index, 1);
        setFile(existingFiles);
    }

    const closeDialogs = (refresh: boolean) => {
        if (refresh) {
            onClose(false);
        }
        setOpenConfimation(false);
    }

    const handleClose = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        onClose(false);
    }

    return (
        <Dialog
            open={open}
            onClose={() => handleClose}
            maxWidth="xs"
            fullWidth
        >
            <FormContainer
                onSuccess={submit}
            >
                <DialogTitle>{"Upload File"}</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-gb'}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="logo" shrink>File</InputLabel>
                                <FileUpload onDelete={onFileDelete} onDrop={onFileDrop} files={file} />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="fileName" shrink>File Name</InputLabel>
                                <TextFieldElement name="fileName" required fullWidth size="small" />
                            </Grid>
                            {dropdown.length > 0 &&
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="fileType" shrink>File Type</InputLabel>
                                    <SelectElement size="small" defaultValue={0} onChange={(e) => setOpenWseSection(dropdown[e-1].label === "WSE")} fullWidth name={'fileType'} options={dropdown} />
                                </Grid>
                            }
                            {openWseSection &&
                                <>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="wseReferenceNumber" shrink>WSE Reference Number</InputLabel>
                                    <TextFieldElement required={openWseSection} name="wseReferenceNumber" fullWidth size="small" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="wseReferenceNumber" shrink>Live WSE</InputLabel>
                                        <Checkbox
                                            checked={liveWse}
                                            onChange={() => setLiveWse(!liveWse)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel htmlFor="approvalDate" shrink>Approval Date</InputLabel>
                                        <DatePickerElement name="approvalDate" inputProps={{ fullWidth: true, size: "small" }} />
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </LocalizationProvider>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setOpenConfimation(true)}>Cancel</Button>
                        <LoadingButton loading={loading} loadingIndicator="Saving..." variant="contained" type="submit"><span>Save Changes</span></LoadingButton>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
            <CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'Are you sure you want to discard any changes?'} open={openConfimation} onClose={closeDialogs}></CloseDialogConfirmation>
        </Dialog>
    );
}

