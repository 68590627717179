import moment from "moment";

export class UtilityMethods {
    public static round2Decimals(value: number): number {
        return Math.round((value + Number.EPSILON) * 100) / 100;
    }

    public static setProperty<T, K extends keyof T>(obj: T, key: K, value: T[K]) {
        obj[key] = value;
    }

    public static convertModelToFormData(model: any, form: FormData, namespace = ''): FormData {
        let formData = form || new FormData();
        let formKey: string;

        if (model instanceof Array) {
            model.forEach((element, index) => {
                const tempFormKey = `${namespace}[${index}]`;

                if (element instanceof File)
                    formData.append(formKey, element);
                else
                    this.convertModelToFormData(element, formData, tempFormKey);
            });
        } else {
            for (let propertyName in model) {
                if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;

                formKey = namespace ? `${namespace}.${propertyName}` : propertyName;
                if (model[propertyName] instanceof Date || model[propertyName] instanceof moment)
                    formData.append(formKey, model[propertyName].toISOString());
                else if (model[propertyName] instanceof File)
                    formData.append(formKey, model[propertyName]);
                else if (model[propertyName] instanceof Array) {
                    model[propertyName].forEach((element: any, index: number) => {
                        const tempFormKey = `${formKey}[${index}]`;

                        if (element instanceof File || !(element instanceof Object))
                            formData.append(formKey, element);
                        else
                            this.convertModelToFormData(element, formData, tempFormKey);
                    });
                }
                else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File))
                    this.convertModelToFormData(model[propertyName], formData, formKey);
                else {
                    formData.append(formKey, model[propertyName].toString());
                }
            }
        }

        return formData;
    }

    static calculatePoint(i: number, intervalSize: number, colourStart: number) {
        return colourStart + (i * intervalSize);
    }

    public static interpolateColours(dataLength: number, colourScale: any, colourStart: number) {
        const intervalSize = 1 / dataLength;
        let i, colourPoint;
        const colourArray = [];

        for (i = 0; i < dataLength; i++) {
            colourPoint = UtilityMethods.calculatePoint(i, intervalSize, colourStart);
            colourArray.push(colourScale(colourPoint));
        }

        return colourArray;
    }
}