import * as React from 'react';
import SortableTableHead, { HeadCell, Order } from '../Utilities/SortableTableHead';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import ClientsTableRow from './ClientsTableRow';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import MenuItem from '@mui/material/MenuItem';
import ClientListViewmodel from './Viewmodels/ClientListViewmodel';
import SearchIcon from '@mui/icons-material/Search';
import PermissionCheck from '../Utilities/PermissionCheck';
import { Permission } from '../Permissions/Permissions';
import CreateClient from './CreateClient';
import EditClient from './EditClient';
import { AlertContext } from '../Contexts/AlertContext';
import { debounce } from 'lodash';

const headCells: HeadCell<ClientListViewmodel>[] = [
    { id: 'name', property: 'Name', align: "left", disablePadding: false, label: 'Name' },
    { id: 'plants', property: 'Plants', align: "right", disablePadding: false, label: 'Plants' },
    { id: 'assets', property: 'Assets', align: "right", disablePadding: false, label: 'Assets' },
    { id: 'alert', property: 'Alert', align: "right", disablePadding: false, label: 'Amber Alert Threshold' }
];

export default function ClientsTable() {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof ClientListViewmodel>('name');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [results, setResults] = React.useState<ClientListViewmodel[]>([]);
    const [search, setSearch] = React.useState('');
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [activeClients, setActiveClients] = React.useState<number>(0);
    const [editClient, setEditClient] = React.useState<number | null>(null);
    const [openAdd, setOpenAdd] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, [page, rowsPerPage, order, orderBy]);

    React.useEffect(() => {
        if (editClient !== null) {
            setOpenEdit(true);
            setEditClient(null);
        }
    }, [editClient]);

    React.useEffect(() => {
        searchData();
    }, [activeClients, search]);

    const closeDialogs = (refresh: boolean) => {
        if (refresh)
            getData();

        setOpenAdd(false);
        setOpenEdit(false);
    }

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        await crabFetch(`Client/GetClientsTable?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&active=${activeClients === 2 ? '' : activeClients === 0 ? false : true}&search=${search}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setResults(data.rows);
                setCount(data.count);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const archive = async (clientId: number) => {
        const token = await authService.getAccessToken();

        let url = `Client/Archive?id=${clientId}`;

        crabFetch(url, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully updated client status");
                    searchData();
                }
            },
            (error: any) => {
                show('error', error);
            }
        );
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ClientListViewmodel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(event.target.value);
    }

    const handleChangeClientType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setActiveClients(parseInt(event.target.value))
    }

    const searchData = () => {
        if (page === 0) {
            getData();
        }
        setPage(0);
    }
        
    const debouncedhandleSearch =
        React.useCallback!(
            debounce(handleSearch, 600),
            []
        );

    const emptyRows = page > 0 ? Math.max(0, rowsPerPage - results.length) : 0;

    return (
        <PermissionCheck permission={Permission.ViewClients}>
            <Grid container spacing={2}>
                <Grid item>
                    <Typography variant="h1">Clients</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item>
                                                <TextField
                                                    color="primary"
                                                    size="small"
                                                    variant="outlined"
                                                    select
                                                    onChange={handleChangeClientType}
                                                    value={activeClients}
                                                >
                                                    <MenuItem value={2}>All Clients</MenuItem>
                                                    <MenuItem value={0}>Active Clients</MenuItem>
                                                    <MenuItem value={1}>Archived Clients</MenuItem>
                                                </TextField>
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    sx={{ minWidth: 300 }}
                                                    color="primary"
                                                    size="small"
                                                    variant="outlined"
                                                    onChange={debouncedhandleSearch}
                                                    placeholder="Search..."
                                                    InputProps={{ endAdornment: <SearchIcon /> }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <PermissionCheck permission={Permission.CreateClients}>
                                        <Grid item>
                                            <Button aria-label={"Add Client"} onClick={() => setOpenAdd(true)} variant="contained" color="primary">Add New Client</Button>
                                        </Grid>
                                    </PermissionCheck>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer>
                                    <ResponsiveTable
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                        aria-label="client table"
                                    >
                                        <SortableTableHead
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                            headCells={headCells}
                                            lastCells={<TableCell />}
                                        />
                                        <TableBody>
                                            {(!loading && results) && results.map((row: ClientListViewmodel) => {
                                                return (<ClientsTableRow key={row.id} row={row} edit={setEditClient} archive={archive} />);
                                            })
                                            }
                                            {count <= 0 && !loading &&
                                                <TableRow
                                                    sx={{
                                                        height: 53 * rowsPerPage,
                                                    }}
                                                >
                                                    <TableCell colSpan={headCells.length + 1} align="center">No Results Found</TableCell>
                                                </TableRow>
                                            }
                                            {count > 0 && emptyRows > 0 && (
                                                <TableRow
                                                    style={{
                                                        height: 53 * emptyRows,
                                                    }}
                                                >
                                                    <TableCell colSpan={headCells.length + 1} />
                                                </TableRow>
                                            )}
                                            {loading &&
                                                <TableRow
                                                    sx={{
                                                        height: 53 * rowsPerPage,
                                                    }}
                                                >
                                                    <TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="primary" /></TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </ResponsiveTable>
                                </TableContainer>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={count}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <CreateClient open={openAdd} onClose={closeDialogs} />
                    <EditClient open={openEdit} onClose={closeDialogs} clientId={editClient} />
                </Grid>
            </Grid>
        </PermissionCheck>
    );
}