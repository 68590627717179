import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, TextFieldElement, SelectElement } from 'react-hook-form-mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import { AlertContext } from '../../Contexts/AlertContext';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import DropdownViewModel from '../../Utilities/ViewModels/DropdownViewModel';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import PermissionCheck from '../../Utilities/PermissionCheck';
import CreateUserViewmodel from '../Viewmodels/CreateUserViewmodel';
import FileUpload from '../../Utilities/FileUpload';
import CloseDialogConfirmation from '../../Utilities/CloseDialogConfimation';
import { UtilityMethods } from '../../Utilities/UtilityMethods';
import { Permission } from '../../Permissions/Permissions';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    userId?: string | null;
}

export default function EditUser(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose, userId } = props;
    const [loading, setLoading] = React.useState(false);
    const [user, setUser] = React.useState<CreateUserViewmodel>(new CreateUserViewmodel());
    const [userTypes, setUserTypes] = React.useState<DropdownViewModel[]>([]);
    const [clients, setClients] = React.useState<DropdownViewModel[]>([]);
    const [signature, setSignature] = React.useState<File[]>([]);
    const [openConfimation, setOpenConfimation] = React.useState(false);
    const [showSignature, setShowSignature] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, []);

    React.useEffect(() => {
        if (userId !== null) {
            getUser();
        }
    }, [userId]);

    const formContext = useForm<CreateUserViewmodel>({
        defaultValues: new CreateUserViewmodel()
    });

    const { reset, watch } = formContext;

    const watchUserType = watch('userTypeId', 0);

    React.useEffect(() => {
        reset({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            userTypeId: user.userTypeId ?? 0,
            clientId: user.clientId ?? 0
        });
    }, [user]);

    const getUser = async () => {
        const token = await authService.getAccessToken();
        const authUser = await authService.getUser();
        setLoading(true);

        crabFetch(`User/GetUser?id=${userId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: CreateUserViewmodel) => {
                setLoading(false);
                setShowSignature(authUser.sub === data.id);
                setUser(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const getData = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);
        
        crabFetch(`Permissions/GetUserTypesDropdown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setUserTypes(data);
            },
            () => {
                setLoading(false);
            }
        );

        crabFetch(`Client/GetClientsDropdown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setClients(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();
        const authUser = await authService.getUser();
        setLoading(true);

        if (form.userTypeId !== 4) {
            form.clientId = null;
        }

        if (signature.length > 0)
            form.signature = signature[0];

        let formData = new FormData();
        UtilityMethods.convertModelToFormData(form, formData);


        crabFetch('User/UpdateUser', {
            method: 'POST',
            headers: !token ? {  } : { 'Authorization': `Bearer ${token}`,  },
            body: formData
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0)
                    show('error', data);
                else {
                    if (authUser.sub === user.id)
                        updateAuthUserState();

                    show('success', "Successfully updated User Infomation");
                    onClose(true);
                    setSignature([]);
                }

                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        ); 
    }


    const updateAuthUserState = async () => {
        await authService.updateUser();
    }

    const onFileDrop = (newFiles: File[]) => {
        let existingFiles = [...signature];
        existingFiles = existingFiles.concat(newFiles);
        setSignature(existingFiles);
    }

    const onFileDelete = (index: number) => {
        const existingFiles = [...signature];
        existingFiles.splice(index, 1);
        setSignature(existingFiles);
    }

    const closeDialogs = (refresh: boolean) => {
        if (refresh)
            onClose(false);
        setOpenConfimation(false);
        setSignature([]);
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="sm"
            fullWidth
        >
            <FormContainer
                formContext={formContext}
                onSuccess={submit}
            >
                <DialogTitle>{"Edit User"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid container spacing={2} item xs={12}>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="firstName" shrink>Forename</InputLabel>
                                <TextFieldElement name="firstName" required fullWidth size="small" />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="lastName" shrink>Surname</InputLabel>
                                <TextFieldElement name="lastName" required fullWidth size="small" />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} item xs={12}>
                            <Grid item xs={6}>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="email" shrink>Email</InputLabel>
                                    <TextFieldElement parseError={() => "Please enter a valid email address"} type={'email'} name="email" required fullWidth size="small" />
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="phoneNumber" shrink>Phone Number</InputLabel>
                                <TextFieldElement name="phoneNumber" required fullWidth size="small" />
                            </Grid>

                        </Grid>
                        <PermissionCheck permission={Permission.ManageUsers}>
                            <Grid container spacing={2} item xs={12}>
                                <Grid item xs={6}>
                                    <InputLabel htmlFor="userTypeId" shrink>User Level</InputLabel>
                                    <SelectElement
                                        name="userTypeId"
                                        required
                                        fullWidth
                                        size="small"
                                        options={userTypes}
                                        InputProps={{
                                            disabled: loading,
                                            startAdornment: (
                                                !loading ? null : <CircularProgress size={20} />
                                            )
                                        }}
                                    />
                                </Grid>
                                {(watchUserType === 4) &&
                                    <Grid item xs={6}>
                                        <InputLabel htmlFor="clientId" shrink>Client</InputLabel>
                                        <SelectElement
                                            name="clientId"
                                            required
                                            fullWidth
                                            size="small"
                                            options={clients}
                                            InputProps={{
                                                disabled: loading,
                                                startAdornment: (
                                                    !loading ? null : <CircularProgress size={20} />
                                                )
                                            }}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </PermissionCheck>
                        {showSignature &&
                            <Grid item xs={12}>
                                <InputLabel htmlFor="logo" shrink>Signature</InputLabel>
                                <FileUpload onDelete={onFileDelete} onDrop={onFileDrop} files={signature} acceptedTypes={"image/*"} />
                            </Grid>
                        }
                    </Grid>
                    <DialogActions>
                        <Button onClick={() => setOpenConfimation(true)} color="error" disabled={loading}>Cancel</Button>
                        <LoadingButton variant="contained" type="submit" disabled={loading} loading={loading}>{"Save Changes"}</LoadingButton>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
            <CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'Are you sure you want to discard any changes?'} open={openConfimation} onClose={closeDialogs}></CloseDialogConfirmation>
        </Dialog>
    );
}