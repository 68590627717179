import * as React from 'react';
import SortableTableHead, { HeadCell, Order } from '../Utilities/SortableTableHead';
import UserViewmodel from './Viewmodels/UserViewmodel';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import UserTableRow from './UserManagementTableRow';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import EditUser from './User/EditUser';
import CreateUser from './User/CreateUser';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton/IconButton';
import PermissionCheck from '../Utilities/PermissionCheck';
import { Permission } from '../Permissions/Permissions';

const headCells: HeadCell<UserViewmodel>[] = [
    { id: 'firstName', property: 'FirstName', align: "left", disablePadding: false, label: 'Forename' },
    { id: 'lastName', property: 'Lastname', align: "left", disablePadding: false, label: 'Surname' },
    { id: 'email', property: 'Email', align: "left", disablePadding: false, label: 'Email' },
    { id: 'role', property: 'Role', align: "left", disablePadding: false, label: 'Role' },
    { id: 'clientName', property: 'Client', align: "left", disablePadding: false, label: 'Client' },
    { id: 'status', property: 'Status', align: "left", disablePadding: false, label: 'Status' },
    { id: 'date', property: 'Date', align: "left", disablePadding: false, label: 'Created' },
];

export default function UserManagementTable() {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof UserViewmodel>('firstName');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [results, setResults] = React.useState<UserViewmodel[]>([]);
    const [search, setSearch] = React.useState('');
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [openAdd, setOpenAdd] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState<string | null>(null);

    React.useEffect(() => {
        getData();
    }, [page, rowsPerPage, order, orderBy]);

    const toggleOpenAdd = () => setOpenAdd((prev) => !prev);

    const closeDialogs = (refresh: boolean) => {
        if (refresh)
            getData();

        setOpenAdd(false);
        setSelectedRow(null);
    }

    const closeAdd = (refresh: boolean) => {
        if (refresh)
            getData();

        setOpenAdd(false);
    }

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        await crabFetch(`User/GetUserTable?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setResults(data.rows);
                setCount(data.count);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const clearSearch = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        await crabFetch(`User/GetUserTable?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${''}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setResults(data.rows);
                setCount(data.count);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof UserViewmodel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(event.target.value);
        if (page === 0 && event.target.value.length === 0) {
            setPage(0);
            clearSearch();
        }
    }

    const searchData = () => {
        if (page === 0) {
            getData();
        }
        setPage(0);
    }

    const keyPress = (event: any) => {
        if (event.keyCode === 13) {
            getData();
        }
    }
    const cancelSearch = () => {
        setSearch('');
        setPage(0);
        clearSearch();
    }


    const emptyRows = page > 0 ? Math.max(0, rowsPerPage - results.length) : 0;

    return (
        <Paper sx={{ padding: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h5">User Management</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <TextField
                                        id="outlined-search"
                                        type="text"
                                        color="primary"
                                        size="small"
                                        onChange={handleSearch}
                                        value={search}
                                        placeholder="Search..."
                                        onKeyDown={keyPress}
                                        InputProps={{
                                            endAdornment: search && (
                                                <IconButton
                                                    size="small"
                                                    aria-label="clear search"
                                                    onClick={() => cancelSearch()}
                                                >
                                                    <CancelIcon fontSize="small" />
                                                </IconButton>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button aria-label={"Search Users"} onClick={searchData} variant="contained">Search</Button>
                                </Grid>
                                <PermissionCheck permission={Permission.ManageUsers}>
                                    <Grid item>
                                        <Button aria-label={"Add User"} onClick={toggleOpenAdd} variant="contained">Add User</Button>
                                    </Grid>
                                </PermissionCheck>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer>
                        <ResponsiveTable
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="user table"
                        >
                            <SortableTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                headCells={headCells}
                                lastCells={<TableCell colSpan={2} />}
                            />
                            <TableBody>
                                {(!loading && results) && results.map((row: UserViewmodel) => {
                                    return (<UserTableRow key={row.id} user={row} editRow={setSelectedRow} refresh={getData} />);
                                })
                                }
                                {count <= 0 && !loading &&
                                    <TableRow
                                        sx={{
                                            height: 53 * rowsPerPage,
                                        }}
                                    >
                                        <TableCell colSpan={headCells.length + 1} align="center">No Results Found</TableCell>
                                    </TableRow>
                                }
                                {count > 0 && emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: 53 * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={headCells.length + 1} />
                                    </TableRow>
                                )}
                                {loading &&
                                    <TableRow
                                        sx={{
                                            height: 53 * rowsPerPage,
                                        }}
                                    >
                                        <TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="primary" /></TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </ResponsiveTable>
                    </TableContainer>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <CreateUser open={openAdd} onClose={closeAdd} />
            <EditUser open={selectedRow !== null} onClose={closeDialogs} userId={selectedRow} />
        </Paper>
    );
}