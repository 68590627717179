import * as React from 'react';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import SafetyDeviceViewmodel from '../Viewmodels/SafetyDeviceViewmodel';
import InputLabel from '@mui/material/InputLabel';

interface IProps {
    safetyDevice: SafetyDeviceViewmodel;
}

export default function ViewSafetyDevice(props: IProps) {
    const { safetyDevice } = props;
    const formContext = useForm<SafetyDeviceViewmodel>({
        defaultValues: new SafetyDeviceViewmodel()
    });

    const { reset } = formContext;

    React.useEffect(() => {
        if (safetyDevice.id! > 0)
            reset(safetyDevice);
    }, [safetyDevice]);

    return (
        <FormContainer
            formContext={formContext}
        >
            <Grid container spacing={2} sx={{ pt: 1, pb: 1 }}>
                <Grid item xs={12} md={4}>
                    <InputLabel htmlFor="name" shrink>Name</InputLabel>
                    <TextFieldElement name="name" required fullWidth size="small" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputLabel htmlFor="typeName" shrink>Type</InputLabel>
                    <TextFieldElement name="typeName" required fullWidth size="small" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputLabel htmlFor="equipmentNumber" shrink>Equipment Number</InputLabel>
                    <TextFieldElement name="equipmentNumber" required fullWidth size="small" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputLabel htmlFor="coldDifferential" shrink>Cold Differential Set Pressure</InputLabel>
                    <TextFieldElement name="coldDifferential" required fullWidth size="small" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputLabel htmlFor="reliefSystemWse" shrink>Relief System WSE No.</InputLabel>
                    <TextFieldElement name="reliefSystemWse" required fullWidth size="small" />
                </Grid>
            </Grid>
        </FormContainer>
    );
}