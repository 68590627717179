import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from 'react';
import Button from "@mui/material/Button";
import DraftsViewmodel from "./ViewModels/DraftsViewModel";
import { useNavigate } from "react-router-dom";
import PermissionCheck from "../Utilities/PermissionCheck";
import { Permission } from "../Permissions/Permissions";

interface IProps {
    row: DraftsViewmodel;
}
export default function AwaitingApprovalTableRow({ row }: IProps) {
    const navigate = useNavigate();

    const view = () => {
        if (row.documentType === 'WSE') {
            navigate(
                `/Wse/${row.assetId}/${row.id}`
            );
        } else {
            navigate(
                `/InspectionReport/${row.assetId}/${row.id}`
            );
        }
    }

    return (
        <TableRow>
            <TableCell>{row.documentType}</TableCell>
            <TableCell>{row.reference}</TableCell>
            <TableCell>{row.assetType}</TableCell>
            <TableCell>{row.description}</TableCell>
            <TableCell>{row.client}</TableCell>
            <TableCell>{row.owner}</TableCell>
            <TableCell align="right">
                <PermissionCheck permission={[Permission.ViewDocs, row.documentType === 'WSE' ? Permission.SignOffWse : Permission.SignOffReport, row.documentType === 'WSE' ? Permission.CreateWse : Permission.CreateReport, Permission.AcknowledgeDocument, Permission.SignOffEscalatedDocument]}>
                    <Button variant="contained" onClick={view}>{row.status !== "Draft" ? "Review" : "View"}</Button>
                </PermissionCheck>
            </TableCell>
        </TableRow>
    );
}