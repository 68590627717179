import * as React from 'react';
import Grid from '@mui/material/Grid';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { useLocation, useNavigate, useParams } from 'react-router';
import AssetViewmodel from '../Assets/Viewmodels/AssetViewmodel';
import AssetDetails from '../Assets/AssetDetails/AssetDetails';
import Button from '@mui/material/Button';
import NavigateBackIcon from '@mui/icons-material/NavigateBefore';
import SignOff, { SignOffDocType } from '../SignOff/SignOff';
import Typography from '@mui/material/Typography';
import CustomCollapse from '../Utilities/CustomCollapse';
import InputLabel from '@mui/material/InputLabel';
import { Controller, FormContainer, useFieldArray, useForm } from 'react-hook-form-mui';
import InspectionReportViewmodel from './Viewmodels/InspectionReportViewmodel';
import ExtentLimitations from './ExtentLimitations';
import InspectionFindings from './InspectionFindings';
import SummaryStatement from './SummaryStatement';
import RequiredActions from './RequiredActions';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/en-gb';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import AttachedPhotos from './AttachedPhotos';
import AttachedFiles from './AttachedFiles';
import { UtilityMethods } from '../Utilities/UtilityMethods';
import { AlertContext } from '../Contexts/AlertContext';
import PermissionCheck from '../Utilities/PermissionCheck';
import { Permission } from '../Permissions/Permissions';
import ReactQuill from 'react-quill';
import { formats, modules } from '../Utilities/RichTextConfig';
import moment from 'moment';
import EqupimentData from '../Assets/AssetDetails/EquipmentData';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert/Alert';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';

interface LocationState {
    inspections: number[];
}

export default function InspectionReport() {
    const navigate = useNavigate();
    const location = useLocation();
    const { inspections } = location.state as LocationState || {
        inspections: []
    }
    const { assetId, id } = useParams();
    const { show } = React.useContext(AlertContext);
    const { crabFetch, save } = React.useContext(TokenRefreshContext);
    const [asset, setAsset] = React.useState(new AssetViewmodel());
    const [report, setReport] = React.useState(new InspectionReportViewmodel());

    const [loading, setLoading] = React.useState(true);
    const [downloading, setDownloading] = React.useState(false);
    const [editing, setEditing] = React.useState(true);
    const [viewPermission, setViewPermission] = React.useState(false);

    const formContext = useForm<InspectionReportViewmodel>({
        defaultValues: new InspectionReportViewmodel()
    });

    const actionArray = useFieldArray({ name: "actions", control: formContext.control });
    const photoArray = useFieldArray({ name: "photos", control: formContext.control });
    const fileArray = useFieldArray({ name: "files", control: formContext.control });
    const { reset } = formContext;


    React.useEffect(() => {
        editing ? checkWsePermissions() : setViewPermission(true);
    }, [editing]);

    React.useEffect(() => {
        if (save === true && report.viewingUserCanEdit) {
            submit();
        }
    }, [save]);

    const checkWsePermissions = async () => {
        var p1 = await CheckHasPermission(Permission.CreateReport);
        var p2 = await CheckHasPermission(Permission.SignOffReport);
        var p3 = await CheckHasPermission(Permission.SignOffEscalatedDocument);
        var p4 = await CheckHasPermission(Permission.AcknowledgeDocument);

        setViewPermission(p1 || p2 || p3 || p4);
    }

    React.useEffect(() => {
        const form = { ...report } as any;

        // Sort out radios
        if (form.equipmentSuitable !== undefined) {
            form.equipmentSuitable = form.equipmentSuitable ? 'true' : 'false';
        }

        if (form.wseRequireAmending !== undefined) {
            form.wseRequireAmending = form.wseRequireAmending ? 'true' : 'false';
        }

        if (form.reliefSystemInspected !== undefined) {
            form.reliefSystemInspected = form.reliefSystemInspected ? 'true' : 'false';
        }

        if (form.reliefSystemInDate !== undefined) {
            form.reliefSystemInDate = form.reliefSystemInDate ? 'true' : 'false';
        }

        // Sort out dates
        if (form.carriedOutOn) {
            form.carriedOutOn = moment(form.carriedOutOn);
        }
        if (form.thoroughDueBefore) {
            form.thoroughDueBefore = moment(form.thoroughDueBefore);
        }
        if (form.intermediateADueBefore) {
            form.intermediateADueBefore = moment(form.intermediateADueBefore);
        }
        if (form.intermediateBDueBefore) {
            form.intermediateBDueBefore = moment(form.intermediateBDueBefore);
        }

        reset(form);
    }, [report]);

    React.useEffect(() => {
        getReport();
    }, [id]);

    React.useEffect(() => {
        if (report.assetDetailId > 0)
            getAsset();
    }, [report.assetDetailId]);

    const download = async (filename: string) => {
        const token = await authService.getAccessToken();
        setDownloading(true);

        crabFetch(`InspectionReport/GetPdf?reportId=${report.id}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Blob,
            ((blob: Blob) => {
                const url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.download = filename;
                a.href = url;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
                setDownloading(false);
            })
        );
    }

    const getFileName = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`InspectionReport/GetReportName?reportId=${report.id}`, {
            headers: !token ? { 'Content-Type': 'text/plain; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain; charset=utf-8' },
        }, ResponseType.Text,
            ((filename: string) => {
                download(filename);
            })
        );
    }

    const getReport = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`InspectionReport/GetInspectionReport?id=${id ? id : 0}&assetId=${assetId}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(inspections)
        }, ResponseType.JSON,
            (data: InspectionReportViewmodel) => {
                setReport(data);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const getAsset = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Asset/GetAsset?id=${assetId}&detailsId=${report.assetDetailId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: AssetViewmodel) => {
                setAsset(data);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const submit = async () => {

        let form: any = formContext.getValues()
        const token = await authService.getAccessToken();
        setLoading(true);

        if (form.equipmentSuitable) {
            form.equipmentSuitable = form.equipmentSuitable === 'true';
        }

        if (form.wseRequireAmending) {
            form.wseRequireAmending = form.wseRequireAmending === 'true';
        }

        if (form.reliefSystemInspected) {
            form.reliefSystemInspected = form.reliefSystemInspected === 'true';
        }

        if (form.reliefSystemInDate) {
            form.reliefSystemInDate = form.reliefSystemInDate === 'true';
        }

        if (!id || parseInt(id) === 0) {
            form.inspections = inspections;
        }

        // Sort out dates
        if (form.carriedOutOn) {
            const offset = form.carriedOutOn.utcOffset();
            var vp = form.carriedOutOn.add(offset, 'm');
            form.carriedOutOn = vp;
        }
        if (form.thoroughDueBefore) {
            const offset = form.thoroughDueBefore.utcOffset();
            var vp = form.thoroughDueBefore.add(offset, 'm');
            form.thoroughDueBefore = vp;
        }
        if (form.intermediateADueBefore) {
            const offset = form.intermediateADueBefore.utcOffset();
            var vp = form.intermediateADueBefore.add(offset, 'm');
            form.intermediateADueBefore = vp;
        }
        if (form.intermediateBDueBefore) {
            const offset = form.intermediateBDueBefore.utcOffset();
            var vp = form.intermediateBDueBefore.add(offset, 'm');
            form.intermediateBDueBefore = vp;
        }

        let formData = new FormData();
        UtilityMethods.convertModelToFormData(form, formData);

        await crabFetch(`InspectionReport/CreateUpdateReport`, {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            body: formData
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) {
                    if (isNaN(parseInt(data))) {
                        show('error', data);
                    }
                    else {
                        if (id && parseInt(id) > 0) {
                            show('success', "Successfully updated inspection report");
                            getReport();
                        }
                        else {
                            show('success', "Successfully created inspection report");
                            navigate(`/InspectionReport/${assetId}/${data}`);
                        }
                    }
                }
                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    const backToAsset = () => {
        navigate(
            '/Asset',
            {
                state: {
                    id: assetId
                }
            }
        );
    }

    return (
        <FormContainer
            formContext={formContext}
            onSuccess={() => submit()}
        >
            {viewPermission &&
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="enGB" >
                    <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Button variant="outlined" onClick={backToAsset} startIcon={<NavigateBackIcon />}>Back to Asset</Button>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h1">Inspection Report</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2} alignItems="center">
                                {report.ownerName && report.ownerName.length > 0 &&
                                    <Grid item>

                                        <Alert severity="warning">This report is owned by {report ? report.ownerName : ''}</Alert>
                                    </Grid>
                                }
                                <PermissionCheck permission={Permission.CreateReport}>
                                    {report.viewingUserCanEdit &&
                                        <Grid item>
                                            <LoadingButton variant="contained" type="submit" disabled={!editing} loading={loading} loadingIndicator="Saving...">Save Changes</LoadingButton>
                                        </Grid>
                                    }
                                </PermissionCheck>
                                <PermissionCheck permission={Permission.ViewDocs}>
                                    <Grid item>
                                        <LoadingButton disabled={(!id || parseInt(id) === 0)} loading={loading || downloading} loadingIndicator="Generating..." variant="contained" onClick={getFileName}>Download PDF</LoadingButton>
                                    </Grid>
                                </PermissionCheck>
                            </Grid>
                        </Grid>
                        {!loading &&
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <InputLabel htmlFor="plantItemNumber" shrink>Plant Item Number</InputLabel>
                                                <TextField variant="outlined" required fullWidth size="small" disabled value={asset.plantItemNumber} />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <InputLabel htmlFor="wseNumber" shrink>WSE Number</InputLabel>
                                                <TextField variant="outlined" required fullWidth size="small" disabled value={report.wseNumber} />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <InputLabel htmlFor="reportNumber" shrink>Report Number</InputLabel>
                                                <TextField variant="outlined" required fullWidth size="small" disabled value={report.reportNumber} />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <CustomCollapse title="Design Information">
                                            <AssetDetails asset={asset} assetDetailId={report.assetDetailId} showButtons={false} />
                                        </CustomCollapse>
                                    </Paper>
                                </Grid>
                                {!loading &&
                                    <EqupimentData asset={asset} showButtons={false} />
                                }
                                <Grid item xs={12}>
                                    <Paper>
                                        <CustomCollapse title="History">
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sx={{ pt: 1 }}>
                                                    <Typography variant="h3">Brief History</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="briefHistory" shrink>Significant aspects & repairs</InputLabel>
                                                    <Controller
                                                        control={formContext.control}
                                                        name="briefHistory"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <ReactQuill
                                                                modules={modules}
                                                                formats={formats}
                                                                onChange={onChange} // send value to hook form
                                                                onBlur={onBlur} // notify when input is touched/blur
                                                                readOnly={!editing}
                                                                value={value}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CustomCollapse>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <CustomCollapse title="Extent & Limitations">
                                            <ExtentLimitations editing={editing} control={formContext.control} />
                                        </CustomCollapse>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <CustomCollapse title="Inspection Findings">
                                            <InspectionFindings editing={editing} showReliefQuestions={report.associatedReliefDevices} control={formContext.control} />
                                        </CustomCollapse>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <CustomCollapse title="Required Actions">
                                            <RequiredActions editing={editing} fields={actionArray.fields} remove={actionArray.remove} append={actionArray.append} />
                                        </CustomCollapse>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <CustomCollapse title="Attached Files">
                                            <AttachedFiles editing={editing} fields={fileArray.fields} remove={fileArray.remove} append={fileArray.append} />
                                        </CustomCollapse>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <CustomCollapse title="Attached Photographs">
                                            <AttachedPhotos editing={editing} fields={photoArray.fields} remove={photoArray.remove} append={photoArray.append} />
                                        </CustomCollapse>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <CustomCollapse title="Summary Statement">
                                            <SummaryStatement editing={editing} />
                                        </CustomCollapse>
                                    </Paper>
                                </Grid>
                                {id && parseInt(id) > 0 &&
                                    <Grid item xs={12}>
                                        <SignOff id={parseInt(id)} type={SignOffDocType.InspectionReport} canEdit={setEditing} saveReport={submit} ownerId={report.ownerId} />
                                    </Grid>
                                }
                                <PermissionCheck permission={Permission.CreateReport}>
                                    {report.viewingUserCanEdit &&
                                        <Grid item>
                                            <LoadingButton variant="contained" type="submit" disabled={!editing} loading={loading} loadingIndicator="Saving...">Save Changes</LoadingButton>
                                        </Grid>
                                    }
                                </PermissionCheck>
                            </React.Fragment>
                        }
                        {loading &&
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={12}>
                                                <Alert variant="outlined" severity="warning">
                                                    Document is saving. Do not refresh the page.
                                                </Alert>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <LinearProgress />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>
                </LocalizationProvider>
            }
        </FormContainer >
    );
}

export const CheckHasPermission = async (permission: string | string[]) => {
    const user = await authService.getUser();

    if ((user && user.role && user.role === "Admin")) {
        return true;
    }
    else if (user && user.permission) {
        if (Array.isArray(permission) && user.permission.some((item: string) => permission.includes(item))) {
            return true;
        } else if (user.permission.includes(permission)) {
            return true;
        } else {
            return false;
        }
    }

    return false;
}