import * as React from 'react';
import { useSnackbar, VariantType } from 'notistack';

type ContextType = {
    show: (type: VariantType, message: string) => void;
};

type AlertProviderProps = { children: React.ReactNode };

export const AlertContext = React.createContext<ContextType>({ show: () => { throw Error('Cannot use a context without a provider'); } });

export default function AlertProvider({ children }: AlertProviderProps) {
    const { enqueueSnackbar } = useSnackbar();

    const showMessage = (type: VariantType, message: string) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            variant: type
        })
    }

    return (
        <AlertContext.Provider value={{ show: showMessage }}>
            {children}
        </AlertContext.Provider>
    );
}