import * as React from 'react';
import Navmenu from './Navmenu';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import authService from './api-authorization/AuthorizeService';

interface IProps {
    children: any;
}

export default function Layout(props: IProps) {
    const [authenticated, setAuthenticated] = React.useState(false);

    React.useEffect(() => {
        checkAuth();
    }, []);

    const checkAuth = async () => {
        setAuthenticated(await authService.isAuthenticated());
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {authenticated &&
                <Navmenu />
            }
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                {props.children}
            </Box>
        </Box>
    );
}
