import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from 'react';
import Button from "@mui/material/Button";
import DraftsViewmodel from "./ViewModels/DraftsViewModel";
import { useNavigate } from "react-router-dom";
import PermissionCheck from "../Utilities/PermissionCheck";
import { Permission } from "../Permissions/Permissions";

interface IProps {
    row: DraftsViewmodel;
}
export default function AwaitingAcknowledgementTableRow({ row }: IProps) {
    const navigate = useNavigate();

    const view = () => {
        if (row.documentType === 'WSE') {
            navigate(
                `/Wse/${row.assetId}/${row.id}`
            );
        } else {
            navigate(
                `/InspectionReport/${row.assetId}/${row.id}`
            );
        }
    }

    return (
        <TableRow>
            <TableCell>{row.asset}</TableCell>
            <TableCell>{row.documentType}</TableCell>
            <TableCell>{row.reference}</TableCell>
            <TableCell>{row.status}</TableCell>
            <TableCell align="right">
                {row.status !== 'Draft' &&
                    <PermissionCheck permission={Permission.AcknowledgeDocument}>
                        <Button variant="contained" onClick={view}>Review</Button>
                    </PermissionCheck>
                }
            </TableCell>
        </TableRow>
    );
}