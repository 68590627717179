import * as React from "react";
import authService from '../api-authorization/AuthorizeService';
import { useNavigate } from "react-router";

interface IProps {
    permission?: string | string[];
    userType?: string | string[];
    children?: React.ReactNode;
    redirectOnFail?: boolean;
}

export const CheckHasPermission = async (permission: string | string[]) => {
    const user = await authService.getUser();

    if ((user && user.role && user.role === "Admin")) {
        return true;
    }
    else if (user && user.permission) {
        if (Array.isArray(permission) && user.permission.some((item: string) => permission.includes(item))) {
            return true;
        } else if (user.permission.includes(permission)) {
            return true;
        } else {
            return false;
        }
    }

    return false;
}

export default function PermissionCheck(props: IProps) {
    const { permission, userType, children, redirectOnFail } = props;
    const [hasAccess, setHasAccess] = React.useState(false);
    const navigate = useNavigate();


    React.useEffect(() => {
        if (permission && permission.length > 0) {
            checkPermission();
        }
    }, [permission]);

    React.useEffect(() => {
        if (userType && userType.length > 0) {
            checkUserType();
        }
    }, [userType]);

    const checkPermission = async () => {
        const user = await authService.getUser();

        if ((user && user.role && user.role === "Admin")) {
            setHasAccess(true);
        }
        else if (user && user.permission) {
            if (Array.isArray(permission) && user.permission.some((item: string) => permission.includes(item))) {
                setHasAccess(true);
            } else if (user.permission.includes(permission)) {
                setHasAccess(true);
            } else {
                setHasAccess(false);
            }
        } else {
            setHasAccess(false);
        }
    }

    const checkUserType = async () => {
        const user = await authService.getUser();

        if ((user && user.role && user.role === "Admin")) {
            setHasAccess(true);
        }
        else if (user && user.userType && Array.isArray(userType) && userType.includes(user.userType)) {
            setHasAccess(true);
        }
        else if (user && user.userType && user.userType === userType) {
            setHasAccess(true);

        }
        else {
            setHasAccess(false);
            if (redirectOnFail) { navigate(-1) }
        }
    }

    return (
        <React.Fragment>
            {hasAccess && children}
        </React.Fragment>
    );
}