export const modules = {
    toolbar: [
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean']
    ],
    clipboard: {
        matchVisual: false
    }
};

export const formats = [
    'bold', 'italic', 'underline',
    'list', 'bullet',
];