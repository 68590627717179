export default class EditPlantVieModel {
    public id: number = 0;

    public clientId: number = 0;

    public name: string = '';
    public address: string = '';

    public siteName: string = '';
    public siteAddress: string = '';

    public deptEngineer: string | null = null;
    public nomEngineer: string | null = null;
}