export default class SubmitInspectionsAndPreparationsViewModel {
    public notes: string = '';
    public interval: string = '';
    public aInspection: string = '';
    public aInterval: string = '';
    public bInspection: string = '';
    public bInterval: string = '';
    public oneOffInspection: string = '';
    public dueDate: Date | null = null;
    public ownerName: string = '';
    public ownerId: string = '';
}