import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useParams, useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Bugsnag from '@bugsnag/js';
import Paper from '@mui/material/Paper';
import { FormContainer, PasswordElement, PasswordRepeatElement } from 'react-hook-form-mui';
import InputLabel from '@mui/material/InputLabel';

export default function RegisterConfirmation() {
    const { userId, code } = useParams();
    const navigate = useNavigate();
    const [redirect, setRedirect] = React.useState(false);
    const [exists, setExists] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [currentError, setCurrentError] = React.useState('');

    React.useEffect(() => {
        checkExists();
    }, []);

    React.useEffect(() => {
        if (redirect)
            navigate('/');
    }, [redirect]);

    const checkExists = async () => {
        fetch(`User/CheckUserExists?id=${userId}`, {
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
        })
            .then(response => response.json())
            .then((data: any) => {
                setExists(data);
            })
            .catch((error: any) => {
                Bugsnag.notify(error);
            });
    }

    const createUser = async (form: any) => {
        fetch('User/SetPassword', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({ userId: userId, code: code, password: form.password })
        })
            .then(response => response.text())
            .then((data: any) => {
                if (data.length > 0) {
                    setCurrentError(data);
                    setShowAlert(true);
                } else {
                    setRedirect(true);
                    setShowAlert(false);
                }
            })
            .then((error: any) => {
                Bugsnag.notify(error);
            });
    }




    return (
        <React.Fragment>
            {exists ?
                <FormContainer
                    defaultValues={{
                        password: '', confirmPassword: ''
                    }}
                    onSuccess={createUser}
                >
                    <Grid container>
                        <Grid item xs={6}>
                            <Paper sx={{ padding: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h2">Create Password</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="password" shrink>Password</InputLabel>
                                        {/*Failed regex now letting the form submit, password feedback comes from serverside*/}
                                        <PasswordElement name="password" fullWidth size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="confirmPassword" shrink>Confirm Password</InputLabel>
                                        <PasswordRepeatElement name="confirmPassword" fullWidth size="small" passwordFieldName="password" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button fullWidth variant="contained" color="primary" type="submit">Confirm</Button>
                                    </Grid>
                                </Grid>
                                {showAlert &&
                                    <Alert severity="warning">{currentError}</Alert>
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                </FormContainer>
                :
                <Alert severity="warning">Invalid link, user does not exist.</Alert>
            }
        </React.Fragment>
    );
}