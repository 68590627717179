import * as React from 'react';
import Grid from '@mui/material/Grid';
import UserManagementTable from './UserManagementTable';
import PermissionCheck from '../Utilities/PermissionCheck';
import { Permission } from '../Permissions/Permissions';

export default function UserDashboard() {
    return (
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
                <PermissionCheck permission={Permission.ManageUsers}>
                    <UserManagementTable />
                </PermissionCheck>
            </Grid>
        </Grid>
    );
}