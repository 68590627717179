import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import PlantListViewmodel from './Viewmodels/PlantListViewmodel';
import { useNavigate } from 'react-router-dom';
import PermissionCheck from '../Utilities/PermissionCheck';
import { Permission } from '../Permissions/Permissions';
import Grid from '@mui/material/Grid';
import CloseDialogConfirmation from '../Utilities/CloseDialogConfimation';
import { FilterContext } from '../Contexts/FilterContext';

interface IProps {
	row: PlantListViewmodel;
	edit: (id: number) => void;
	archive: (id: number) => void;
}

export default function PlantsTableRow({ row, edit, archive }: IProps) {
	const { plant, updatePlant } = React.useContext(FilterContext);
	const navigate = useNavigate();
	const [openConfirmation, setOpenConfirmation] = React.useState(false);
	const [clicked, setClicked] = React.useState(false);

	React.useEffect(() => {
		if (plant === row.id && clicked) {
			navigate(
				'/Assets',
				{
					state: {
						plantId: row.id
					}
				}
			);
		}
	}, [plant]);

	React.useEffect(() => {
		if (row.id === plant) {
			navigate(
				'/Assets',
				{
					state: {
						plantId: row.id
					}
				}
			);
		}
		else {
			updatePlant(row.id);
		}
	}, [clicked]);

	const viewAssets = () => {
		setClicked(true);
	}

	const close = (refresh: boolean) => {
		setOpenConfirmation(false);
		if (refresh) {
			archive(row.id)
		}
	}

	return (
		<TableRow>
			<TableCell>{row.name}</TableCell>
			<TableCell>{row.clientName}</TableCell>
			<TableCell>{row.address}</TableCell>
			<TableCell align="right">{row.assetCount}</TableCell>
			<TableCell align="right">{row.nomEngineer}</TableCell>
			<TableCell align="right">{row.deptEngineer}</TableCell>
			<TableCell align="right">
				<Grid container spacing={1} justifyContent="flex-end">
					<PermissionCheck permission={Permission.CreatePlants}>
						<Grid item>
							<Button variant="contained" size="small" onClick={() => edit(row.id)}>Edit Details</Button>
						</Grid>
					</PermissionCheck>
					<PermissionCheck permission={Permission.DeleteClients}>
						<Grid item>
							<Button variant="contained" size="small" onClick={() => setOpenConfirmation(true)}>{!row.status ? "Archive" : "Unarchive"}</Button>
						</Grid>
					</PermissionCheck>
					<Grid item>
						<Button variant="contained" size="small" onClick={viewAssets}>View Assets</Button>
					</Grid>
				</Grid>
			</TableCell>
			<CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'Are you sure you want to discard ' + row.name + ' ?'} open={openConfirmation} onClose={close}></CloseDialogConfirmation>

		</TableRow>
	);
}