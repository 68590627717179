export default class PermissionsListViewModel {
    public id: number = 0;
    public name: string = '';
    public enabled: boolean = false;
    public group: string = '';
    public action: string = '';

    constructor(id: number, name: string, group: string, action: string, enabled: boolean) {
        this.id = id;
        this.name = name;
        this.group = group;
        this.action = action;
        this.enabled = enabled;
    }
}