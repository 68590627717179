import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import { useNavigate } from 'react-router-dom';
import authService from '../../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../../Contexts/TokenRefreshContext';
import { AlertContext } from '../../Contexts/AlertContext';

interface IProps {
    open: boolean;
    close: () => void;
    selected: number[];
    assetId: number;
}

export default function StartReportDialog(props: IProps) {
    const { open, close, selected, assetId } = props;
    const navigate = useNavigate();
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);

    const startReport = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`InspectionReport/CheckInspections`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(selected)
        }, ResponseType.Text,
            ((data: any) => {
                if (data === 'true') {
                    navigate(
                        `/InspectionReport/${assetId}`,
                        {
                            state: {
                                inspections: selected,
                            }
                        }
                    );
                }
                else if (data === 'false') {
                    show('error', "Another Inspection report has been started for some of your inspections. Refresh your page and try again");
                }
                else {
                    show('error', data)
                }
            })
        );

    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={close}
            aria-labelledby="start inspection report dialog"
        >
            <DialogTitle>Start New Inspection Report</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You have selected <b>{selected.length} inspection(s)</b> to include in the report.
                </DialogContentText>
                <DialogActions>
                    <Button onClick={close} variant="outlined" color="error">Cancel</Button>
                    <Button variant="contained" color="primary" onClick={startReport}>Start Report</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}