import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from 'react';
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import MyAssetsViewModel from "./ViewModels/MyAssetsViewModel";
import { useNavigate } from "react-router-dom";
import { AlertColours } from "../Utilities/AlertColours";

interface IProps {
    row: MyAssetsViewModel;
}
export default function MyAssetsTableRow({ row }: IProps) {
    const navigate = useNavigate();

    const viewAsset = () => {
        navigate(
            '/Asset',
            {
                state: {
                    id: row.id
                }
            }
        );
    }

    const chipColour = React.useMemo(() => {
        if (row.nextInspection) {
            var date = new Date(row.nextInspection);
            var today = new Date();
            today.setHours(0, 0, 0, 0);

            var alertDate = new Date(today);
            alertDate.setDate(alertDate.getDate() + row.alertDays);

            if ((date >= today && date >= alertDate) || row.inspectionStatus === 'Reporting')
                return AlertColours.low;
            else if (date <= alertDate && date >= today)
                return AlertColours.medium;
            else
                return AlertColours.high;
        }
        else {
            return '';
        }
    }, [row.nextInspection]);

    return (
        <TableRow>
            <TableCell>{row.plantItemNo}</TableCell>
            <TableCell>{row.assetType}</TableCell>
            <TableCell>{row.description}</TableCell>
            <TableCell>{row.client}</TableCell>
            <TableCell>{row.plant}</TableCell>
            <TableCell>{(row.inspectionStatus && row.nextInspection) ? <Chip label={new Date(row.nextInspection).toLocaleDateString('en-gb')} sx={{ backgroundColor: chipColour }} /> : ''}</TableCell>
            <TableCell align="right">
                <Button variant="contained" size="small" onClick={viewAsset}>View</Button>
            </TableCell>
        </TableRow>
    );
}