import * as React from 'react';
import SortableTableHead, { HeadCell, Order } from '../../Utilities/SortableTableHead';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../../Utilities/ResponsiveTable';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import authService from '../../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../../Contexts/TokenRefreshContext';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import DropdownViewModel from '../../Utilities/ViewModels/DropdownViewModel';
import PermissionCheck from '../../Utilities/PermissionCheck';
import { Permission } from '../../Permissions/Permissions';
import { debounce } from 'lodash';
import InspectionViewmodel from '../Viewmodels/InspectionViewmodel';
import InspectionsTableRow from './InspectionsTableRow';
import StartReportDialog from './StartReportDialog';

const headCells: HeadCell<InspectionViewmodel>[] = [
    { id: 'dueDate', property: 'DueDate', align: "left", disablePadding: false, label: 'Due Date' },
    { id: 'inspectionType', property: 'InspectionType', align: "left", disablePadding: false, label: 'Inspection Type' },
    { id: 'status', property: 'Status', align: "left", disablePadding: false, label: 'Status' },
];

interface IProps {
    assetId: number;
    clientId: number;
}

export default function InspectionsTable(props: IProps) {
    const { assetId, clientId } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof InspectionViewmodel>('dueDate');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [results, setResults] = React.useState<InspectionViewmodel[]>([]);
    const [search, setSearch] = React.useState('');
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [type, setType] = React.useState<string>('All');
    const [typeList, setTypeList] = React.useState<DropdownViewModel[]>([]);
    const [openAdd, setOpenAdd] = React.useState(false);
    const [alertDays, setAlertDays] = React.useState(0);
    const [selected, setSelected] = React.useState<number[]>([]);
    const [isLiveWse, setIsLiveWse] = React.useState(false);

    React.useEffect(() => {
        if(clientId > 0)
            getDropdowns();
    }, [clientId]);

    React.useEffect(() => {
        getData();
    }, [page, rowsPerPage, order, orderBy]);

    React.useEffect(() => {
        searchData();
    }, [type, search]);

    const getDropdowns = async () => {
        const token = await authService.getAccessToken();

        await crabFetch(`Inspection/GetInspectionTypesDropdown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setTypeList(data);
            }
        );

        await crabFetch(`Client/GetClientAlertDays?id=${clientId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setAlertDays(data);
            }
        );

        await crabFetch(`Wse/CheckForLiveWse?assetId=${assetId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setIsLiveWse(data);
            }
        );
    }

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        await crabFetch(`Inspection/GetInspectionsTable?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&assetId=${assetId > 0 ? assetId : ''}&type=${type === 'All' ? '' : type}&search=${search}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setResults(data.rows);
                setCount(data.count);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const handleClick = (id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof InspectionViewmodel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const searchData = () => {
        if (page === 0) {
            getData();
        }
        setPage(0);
    }

    const keyPress = (event: any) => {
        if (event.keyCode === 13) {
            searchData();
        }
    }

    const closeAdd = () => {
        setOpenAdd(false);
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(event.target.value);
    }

    const debouncedhandleSearch =
        React.useCallback!(
            debounce(handleSearch, 600),
            []
        );

    const emptyRows = page > 0 ? Math.max(0, rowsPerPage - results.length) : 0;

    return (
        <Paper>
            <Grid container spacing={2}>
                <Grid item>
                    <Typography variant="h2">Scheduled Inspections</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <TextField
                                                color="primary"
                                                size="small"
                                                variant="outlined"
                                                select
                                                onChange={(value) => setType(value.target.value)}
                                                value={type}
                                            >
                                                <MenuItem value={'All'}>All Types</MenuItem>
                                                {typeList.map(item =>
                                                    <MenuItem key={'type' + item.id} value={item.id}>{item.label}</MenuItem>
                                                )}
                                            </TextField>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                sx={{ minWidth: 300 }}
                                                color="primary"
                                                size="small"
                                                variant="outlined"
                                                onChange={debouncedhandleSearch}
                                                placeholder="Search..."
                                                InputProps={{ endAdornment: <SearchIcon /> }}
                                                onKeyDown={keyPress}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <PermissionCheck permission={Permission.CreateReport}>
                                    <Grid item>
                                        <Button aria-label={"New Inspection Report"} onClick={() => setOpenAdd(true)} variant="contained" color="primary" disabled={!isLiveWse}>Start New Inspection Report</Button>
                                    </Grid>
                                </PermissionCheck>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer>
                                <ResponsiveTable
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    aria-label="inspections table"
                                >
                                    <SortableTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        headCells={headCells}
                                        firstCells={<TableCell />}
                                        lastCells={<TableCell />}
                                    />
                                    <TableBody>
                                        {(!loading && results) && results.map((row: InspectionViewmodel) => {
                                            return (<InspectionsTableRow key={row.id} row={row} amberAlertDays={alertDays} selectRow={handleClick} isSelected={selected.indexOf(row.id) !== -1} refresh={searchData} />);
                                        })
                                        }
                                        {count <= 0 && !loading &&
                                            <TableRow
                                                sx={{
                                                    height: 53 * rowsPerPage,
                                                }}
                                            >
                                                <TableCell colSpan={headCells.length + 1} align="center">No Results Found</TableCell>
                                            </TableRow>
                                        }
                                        {count > 0 && emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: 53 * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={headCells.length + 1} />
                                            </TableRow>
                                        )}
                                        {loading &&
                                            <TableRow
                                                sx={{
                                                    height: 53 * rowsPerPage,
                                                }}
                                            >
                                                <TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="primary" /></TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </ResponsiveTable>
                            </TableContainer>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {openAdd &&
                    <PermissionCheck permission={Permission.CreateReport}>
                        <StartReportDialog open={openAdd} close={closeAdd} assetId={assetId} selected={selected} />
                    </PermissionCheck>
                }
            </Grid>
        </Paper>
    );
}