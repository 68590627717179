import * as React from 'react';

type ContextType = {
    plant: number;
    client: number;
    updatePlant: (plant: number) => void;
    updateClient: (client: number) => void;
};

type FilterProviderProps = { children: React.ReactNode };

export const FilterContext = React.createContext<ContextType>({
    plant: 0,
    client: 0,
    updateClient: () => {
        throw Error('Cannot use a context without a provider');
    },
    updatePlant: () => {
        throw Error('Cannot use a context without a provider');
    }
});

export default function FilterProvider({ children }: FilterProviderProps) {
    const [plant, setPlant] = React.useState(0);
    const [client, setClient] = React.useState(0);

    return (
        <FilterContext.Provider value={{ plant, client, updateClient: setClient, updatePlant: setPlant }}>
            {children}
        </FilterContext.Provider>
    );
}