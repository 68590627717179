import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ChangeLogViewmodel from '../Viewmodels/ChangeLogViewmodel';

interface IProps {
    row: ChangeLogViewmodel;
}

export default function ChangeHistoryTableRow(props: IProps) {
    const { row } = props;
    
    return (
        <TableRow>
            <TableCell>{new Date(row.date).toLocaleDateString('en-gb')}</TableCell>
            <TableCell>{row.loggedBy}</TableCell>
            <TableCell>{row.description}</TableCell>
        </TableRow>
    );
}