import * as React from 'react';
import SortableTableHead, { HeadCell, Order } from '../Utilities/SortableTableHead';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import PlantsTableRow from './PlantsTableRow';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import PlantListViewmodel from './Viewmodels/PlantListViewmodel';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';
import { useLocation } from 'react-router';
import PermissionCheck from '../Utilities/PermissionCheck';
import { Permission } from '../Permissions/Permissions';
import { FilterContext } from '../Contexts/FilterContext';
import CreatePlant from './CreatePlant';
import EditPlant from './EditPlant';
import { AlertContext } from '../Contexts/AlertContext';
import { debounce } from 'lodash';
import Autocomplete from '@mui/material/Autocomplete';

const headCells: HeadCell<PlantListViewmodel>[] = [
    { id: 'name', property: 'Name', align: "left", disablePadding: false, label: 'Plant Name' },
    { id: 'clientName', property: 'ClientName', align: "left", disablePadding: false, label: 'Client' },
    { id: 'address', property: 'Address', align: "left", disablePadding: false, label: 'Address' },
    { id: 'assetCount', property: 'AssetCount', align: "right", disablePadding: false, label: 'No. of Assets' },
    { id: 'nomEngineer', property: 'NomEngineer', align: "right", disablePadding: false, label: 'Nom. Engineer' },
    { id: 'deptEngineer', property: 'DeptEngineer', align: "right", disablePadding: false, label: 'Dept. Engineer' },
];

interface LocationState {
    clientId: number;
}

export default function PlantsTable() {
    const location = useLocation();
    const { show } = React.useContext(AlertContext);
    const { clientId } = location.state as LocationState || {
        clientId: 0
    }
    const { client, updateClient } = React.useContext(FilterContext);

    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof PlantListViewmodel>('name');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [results, setResults] = React.useState<PlantListViewmodel[]>([]);
    const [search, setSearch] = React.useState('');
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [clientList, setClientList] = React.useState<DropdownViewModel[]>([]);
    const [activePlants, setActivePlants] = React.useState<number>(0);
    const [editPlant, setEditPlant] = React.useState<number | null>(null);
    const [openAdd, setOpenAdd] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);

    React.useEffect(() => {
        getClients();
    }, []);

    React.useEffect(() => {
        if (clientId > 0) {
            updateClient(clientId);
        }
    }, [clientId]);

    React.useEffect(() => {
        if (editPlant !== null) {
            setOpenEdit(true);
            setEditPlant(null);
        }
    }, [editPlant]);

    React.useEffect(() => {
        getData();
    }, [page, rowsPerPage, order, orderBy]);

    React.useEffect(() => {
        searchData();
    }, [activePlants, client, search]);

    const getClients = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        await crabFetch(`Client/GetClientsDropDown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                const newDropdownItem = new DropdownViewModel();
                newDropdownItem.id = 0;
                newDropdownItem.label = 'All Clients';
                data.unshift(newDropdownItem);
                setClientList(data);
            }
        );
    }

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        await crabFetch(`Plant/GetPlantsTable?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&client=${client > 0 ? client : ''}&active=${activePlants === 2 ? '' : activePlants === 0 ? false : true}&search=${search}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setResults(data.rows);
                setCount(data.count);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const archive = async (clientId: number) => {
        const token = await authService.getAccessToken();

        let url = `Plant/Archive?id=${clientId}`;

        crabFetch(url, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully updated plant status");
                    searchData();
                }
            },
            (error: any) => {
                show('error', error);
            }
        );
    }

    const closeDialogs = (refresh: boolean) => {
        if (refresh)
            searchData();

        setOpenAdd(false);
        setOpenEdit(false);
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof PlantListViewmodel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(event.target.value);
    }

    const handleChangePlantType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setActivePlants(parseInt(event.target.value))
    }

    const searchData = () => {
        if (page === 0) {
            getData();
        }

        setPage(0);
    }

    const debouncedhandleSearch =
        React.useCallback!(
            debounce(handleSearch, 600),
            []
        );

    const setClient = (input: string) => {
        const idString = clientList.find(c => c.label === input)?.id;
        const parsedId = parseInt(idString as string ?? '0', 10);

        if (!isNaN(parsedId)) {
            updateClient(parsedId);
        }
    }

    const emptyRows = Math.max(0, rowsPerPage - results.length);

    return (
        <PermissionCheck permission={Permission.ViewPlants}>
            <Grid container spacing={2}>
                <Grid item>
                    <Typography variant="h1">Plants</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item>
                                                {clientList.length > 0 &&
                                                    <Autocomplete
                                                        disablePortal
                                                        size="small"
                                                        options={clientList}
                                                        sx={{ width: 150 }}
                                                        onInputChange={(value, newInputValue) => setClient(newInputValue)}
                                                        value={clientList.find(f => f.id === client)}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                }
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    color="primary"
                                                    size="small"
                                                    variant="outlined"
                                                    select
                                                    onChange={handleChangePlantType}
                                                    value={activePlants}
                                                >
                                                    <MenuItem value={2}>All Plants</MenuItem>
                                                    <MenuItem value={0}>Active Plants</MenuItem>
                                                    <MenuItem value={1}>Archived Plants</MenuItem>
                                                </TextField>
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    sx={{ minWidth: 300 }}
                                                    color="primary"
                                                    size="small"
                                                    variant="outlined"
                                                    onChange={debouncedhandleSearch}
                                                    placeholder="Search..."
                                                    InputProps={{ endAdornment: <SearchIcon /> }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <PermissionCheck permission={Permission.CreatePlants}>
                                        <Grid item>
                                            <Button aria-label={"Add Plant"} onClick={() => setOpenAdd(true)} variant="contained" color="primary">Add New Plant</Button>
                                        </Grid>
                                    </PermissionCheck>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer>
                                    <ResponsiveTable
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                        aria-label="plant table"
                                    >
                                        <SortableTableHead
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                            headCells={headCells}
                                            lastCells={<TableCell />}
                                        />
                                        <TableBody>
                                            {(!loading && results) && results.map((row: PlantListViewmodel) => {
                                                return (<PlantsTableRow key={row.id} row={row} edit={setEditPlant} archive={archive} />);
                                            })
                                            }
                                            {count <= 0 && !loading &&
                                                <TableRow
                                                    sx={{
                                                        height: 53 * rowsPerPage,
                                                    }}
                                                >
                                                    <TableCell colSpan={headCells.length + 1} align="center">No Results Found</TableCell>
                                                </TableRow>
                                            }
                                            {count > 0 && emptyRows > 0 && (
                                                <TableRow
                                                    style={{
                                                        height: 53 * emptyRows,
                                                    }}
                                                >
                                                    <TableCell colSpan={headCells.length + 1} />
                                                </TableRow>
                                            )}
                                            {loading &&
                                                <TableRow
                                                    sx={{
                                                        height: 53 * rowsPerPage,
                                                    }}
                                                >
                                                    <TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="primary" /></TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </ResponsiveTable>
                                </TableContainer>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={count}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <EditPlant open={openEdit} onClose={closeDialogs} id={editPlant!}></EditPlant>
                    <CreatePlant open={openAdd} onClose={closeDialogs} id={client}></CreatePlant>
                </Grid>
            </Grid>
        </PermissionCheck>
    );
}