import * as React from 'react';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid';
import ComponentViewmodel from '../Viewmodels/ComponentViewmodel';
import CreatePressureComponent from './CreatePressureComponent';
import CreateReliefComponent from './CreateReliefComponent';
import InputLabel from '@mui/material/InputLabel';

interface IProps {
    component: ComponentViewmodel;
}

export default function ViewComponent(props: IProps) {
    const { component } = props;
    const formContext = useForm<ComponentViewmodel>({
        defaultValues: new ComponentViewmodel()
    });

    const { reset } = formContext;

    React.useEffect(() => {
        if (component.id! > 0)
            reset(component);
    }, [component]);

    return (
        <FormContainer
            formContext={formContext}
        >
            <Grid container spacing={2} sx={{ pt: 1, pb: 1 }} >
                <Grid item xs={12} md={4}>
                    <InputLabel htmlFor="name" shrink>Name</InputLabel>
                    <TextFieldElement name="name" required fullWidth size="small" disabled />
                </Grid>
                {/* pressure piping or vessel */}
                {(component.assetType === 1 || component.assetType === 2) &&
                    <CreatePressureComponent assetType={component.assetType} editing={false} />
                }
                {/* 6 = relief system */}
                {component.assetType === 6 &&
                    <CreateReliefComponent editing={false} />
                }
            </Grid>
        </FormContainer>
    );
}