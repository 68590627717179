import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, TextFieldElement, SelectElement } from 'react-hook-form-mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import { AlertContext } from '../Contexts/AlertContext';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';
import EditClientViewModel from './Viewmodels/EditClientViewModel';
import FileUpload from '../Utilities/FileUpload';
import { AlertThresholds } from '../Utilities/AlertThresholds';
import CloseDialogConfirmation from '../Utilities/CloseDialogConfimation';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    clientId: number | null;
}

export default function EditClient(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose, clientId } = props;
    const [loading, setLoading] = React.useState(false);
    const [client, setClient] = React.useState<EditClientViewModel>(new EditClientViewModel());
    const [logo, setLogo] = React.useState<File[]>([]);
    const [openConfimation, setOpenConfimation] = React.useState(false);

    React.useEffect(() => {
        if (clientId !== null) {
            getData()
        }
    }, [clientId]);

    const formContext = useForm<EditClientViewModel>({
        defaultValues: new EditClientViewModel()
    });

    const { reset } = formContext;

    React.useEffect(() => {
        reset({
            id: client.id,
            name: client.name,
            //logo: client.logo,
            alertThreshold: client.alertThreshold,
        });
    }, [client]);

    const getData = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Client/GetClient?Id=${clientId!}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON, 
            (data: any) => {
                //data.logo = data.logo ? Uint8Array.from(atob(data.logo), char => char.charCodeAt(0)) : null;
                setLoading(false);
                setClient(data);
            },
            () => {
                setLoading(false);
            });
    }

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch('Client/UpdateClient', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0)
                    show('error', data);
                else {
                    show('success', "Successfully updated Client Information");
                    onClose(true);
                }

                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    const onFileDrop = (newFiles: File[]) => {
        let existingFiles = [...logo];
        existingFiles = existingFiles.concat(newFiles);
        setLogo(existingFiles);
    }

    const onFileDelete = (index: number) => {
        const existingFiles = [...logo];
        existingFiles.splice(index, 1);
        setLogo(existingFiles);
    }

    const closeDialogs = (refresh: boolean) => {
        if (refresh)
            onClose(false);
        setOpenConfimation(false);
    }
    const dropdownOptions = enumToDropdownViewModel(AlertThresholds);

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="xs"
            fullWidth
        >
            <FormContainer
                formContext={formContext}
                onSuccess={submit}
            >
                <DialogTitle>{"Edit Client"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="name" shrink>Client Name</InputLabel>
                            <TextFieldElement name="name" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="logo" shrink>Client Logo</InputLabel>
                            <FileUpload onDelete={onFileDelete} onDrop={onFileDrop} files={logo} acceptedTypes={"image/*"} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="alertThreshold" shrink>Alert Threshold</InputLabel>
                            <SelectElement fullWidth name={'alertThreshold'} options={dropdownOptions}></SelectElement>
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button onClick={() => setOpenConfimation(true)} color="error" disabled={loading}>Cancel</Button>
                        <LoadingButton variant="contained" type="submit" disabled={loading} loading={loading}>{"Save Changes"}</LoadingButton>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
            <CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'Are you sure you want to discard any changes?'} open={openConfimation} onClose={closeDialogs}></CloseDialogConfirmation>
        </Dialog>
    );
}

function enumToDropdownViewModel(enumObj: any): DropdownViewModel[] {
    const dropdownOptions: DropdownViewModel[] = [];

    for (const key in enumObj) {
        if (enumObj.hasOwnProperty(key)) {
            const viewModel = new DropdownViewModel();
            viewModel.id = enumObj[key];
            viewModel.label = key;
            dropdownOptions.push(viewModel);
        }
    }

    return dropdownOptions.slice(7);
}