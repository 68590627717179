import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, SelectElement, TextFieldElement, useForm } from 'react-hook-form-mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import { AlertContext } from '../Contexts/AlertContext';
import FileUpload from '../Utilities/FileUpload';
import { UtilityMethods } from '../Utilities/UtilityMethods';
import CreateClientViewmodel from './Viewmodels/CreateClientViewModel';
import { AlertThresholds } from '../Utilities/AlertThresholds';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';
import CloseDialogConfirmation from '../Utilities/CloseDialogConfimation';
import LoadingButton from '@mui/lab/LoadingButton';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
}

export default function CreateClient(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose } = props;
    const [logo, setLogo] = React.useState<File[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [openConfimation, setOpenConfimation] = React.useState(false);
    const [newClient] = React.useState<CreateClientViewmodel>(new CreateClientViewmodel());

    React.useEffect(() => {
        reset(newClient);
    }, [newClient]);

    const formContext = useForm<CreateClientViewmodel>({
        defaultValues: new CreateClientViewmodel()
    });

    const { reset } = formContext;

    const submit = async (form: any, event?: React.BaseSyntheticEvent) => {
        event?.preventDefault();

        const token = await authService.getAccessToken();

        setLoading(true);

        if (logo.length > 0)
            form.logo = logo[0];

        let formData = new FormData();
        UtilityMethods.convertModelToFormData(form, formData);

        crabFetch('Client/CreateClient', {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            body: formData
        }, ResponseType.Response,
            (response: Response) => {
                if (response.ok) {
                    show('success', 'Successfully created ' + form.name + '');
                    onClose(true);
                }
                else {
                    response.text()
                        .then((data: string) => {
                            show('error', data);
                        });
                }

                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }
    const onFileDrop = (newFiles: File[]) => {
        let existingFiles = [...logo];
        existingFiles = existingFiles.concat(newFiles);
        setLogo(existingFiles);
    }

    const onFileDelete = (index: number) => {
        const existingFiles = [...logo];
        existingFiles.splice(index, 1);
        setLogo(existingFiles);
    }

    const closeDialogs = (refresh: boolean) => {
        onClose(refresh);
        setOpenConfimation(false);
    }

    const dropdownOptions = enumToDropdownViewModel(AlertThresholds);

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="xs"
            fullWidth
        >
            <FormContainer
                onSuccess={submit}
            >
                <DialogTitle>{"Create Client"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="name" shrink>Client Name</InputLabel>
                            <TextFieldElement name="name" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="logo" shrink>Client Logo</InputLabel>
                            <FileUpload onDelete={onFileDelete} onDrop={onFileDrop} files={logo} acceptedTypes={"image/*"} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="alertThreshold" shrink>Alert Threshold</InputLabel>
                            <SelectElement size="small" fullWidth name={'alertThreshold'} options={dropdownOptions}></SelectElement>
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setOpenConfimation(true)}>Cancel</Button>
                        <LoadingButton loading={loading} loadingIndicator="Saving..." variant="contained" type="submit"><span>Save Client</span></LoadingButton>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
            <CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'Are you sure you want to discard any changes?'} open={openConfimation} onClose={closeDialogs}></CloseDialogConfirmation>
        </Dialog>
    );
}

function enumToDropdownViewModel(enumObj: any): DropdownViewModel[] {
    const dropdownOptions: DropdownViewModel[] = [];

    for (const key in enumObj) {
        if (enumObj.hasOwnProperty(key)) {
            const viewModel = new DropdownViewModel();
            viewModel.id = enumObj[key];
            viewModel.label = key;
            dropdownOptions.push(viewModel);
        }
    }

    return dropdownOptions.slice(7);
}