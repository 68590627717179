import * as React from 'react';
import { TextFieldElement } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';

interface IProps {
    editing: boolean;
}

export default function CreateReliefComponent({ editing }: IProps) {
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="h6">General</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="manufacturer" shrink>Manufacturer</InputLabel>
                <TextFieldElement name="manufacturer" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="model" shrink>Model</InputLabel>
                <TextFieldElement name="model" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="serialNumber" shrink>Serial Number</InputLabel>
                <TextFieldElement name="serialNumber" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="conventionalBellowsPilot" shrink>Conventional/Bellows/Pilot</InputLabel>
                <TextFieldElement name="conventionalBellowsPilot" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="safetyReliefThermal" shrink>Safety/Relief/Thermal Relief</InputLabel>
                <TextFieldElement name="safetyReliefThermal" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="inletDiameter" shrink>Inlet Diameter</InputLabel>
                <TextFieldElement name="inletDiameter" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="outletDiameter" shrink>Outlet Diameter</InputLabel>
                <TextFieldElement name="outletDiameter" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Equipment Data</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="setPressure" shrink>Set Pressure</InputLabel>
                <TextFieldElement name="setPressure" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="lineVesselNumber" shrink>Line/Vessel Number</InputLabel>
                <TextFieldElement name="lineVesselNumber" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="designPressure" shrink>Design Pressure</InputLabel>
                <TextFieldElement name="designPressure" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="designTemperatureRange" shrink>Design Temperature</InputLabel>
                <TextFieldElement name="designTemperatureRange" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="operatingPressure" shrink>Operating Pressure</InputLabel>
                <TextFieldElement name="operatingPressure" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="operatingTemperature" shrink>Operating Temperature</InputLabel>
                <TextFieldElement name="operatingTemperature" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="service" shrink>Service</InputLabel>
                <TextFieldElement name="service" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="reliefFrom" shrink>Relief From</InputLabel>
                <TextFieldElement name="reliefFrom" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="reliefTo" shrink>Relief To</InputLabel>
                <TextFieldElement name="reliefTo" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="equipmentProtected" shrink>Equipment Protected</InputLabel>
                <TextFieldElement name="equipmentProtected" required fullWidth size="small" disabled={!editing} />
            </Grid>
        </React.Fragment>
    );
}