import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DraftsTable from './DraftsTable';
import authService from '../api-authorization/AuthorizeService';
import AssetActionsTable from './AssetActionsTable';
import AssetInspectionsTable from './AssetInspectionsTable';
import MyAssetsTable from './MyAssetsTable';
import AwaitingAcknowledgementTable from './AwaitingAcknowledgementTable';
import DashboardApprovalsTable from './DashboardApprovalsTable';

export default function Dashboard() {
    const [userName, setUserName] = React.useState('');
    const [userType, setUserType] = React.useState('');

    React.useEffect(() => {
        updateAuth();
    }, []);

    const updateAuth = async () => {
        const user = await authService.getUser();
        if (user && user.sub) {
            setUserName(user ? user.name : '');
            setUserType(user.role ? user.role : user.userType ? user.userType : '');
        }
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h1">Welcome to AIMS, {userName}.</Typography>
            </Grid>
            {userType === "Client" ?
                <React.Fragment>
                    <Grid item xs={12}>
                        <MyAssetsTable />
                    </Grid>
                    <Grid item xs={12}>
                        <AwaitingAcknowledgementTable />
                    </Grid>
                    <Grid item xs={12}>
                        <AssetActionsTable isClient={true} />
                    </Grid>
                </React.Fragment>
                :
                <React.Fragment>
                    <Grid item xs={12}>
                        <DraftsTable />
                    </Grid>
                    <Grid item xs={12}>
                        <DashboardApprovalsTable />
                    </Grid>
                    <Grid item xs={12}>
                        <AssetActionsTable isClient={false} />
                    </Grid>
                    <Grid item xs={12}>
                        <AssetInspectionsTable />
                    </Grid>
                </React.Fragment>
            }
        </Grid>
    );
}
