import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from 'react';
import Button from "@mui/material/Button";
import AssetInspectionsViewModel from "./ViewModels/AssetInspectionsViewModel";
import Chip from "@mui/material/Chip";
import { AlertColours } from "../Utilities/AlertColours";
import { useNavigate } from "react-router-dom";

interface IProps {
    row: AssetInspectionsViewModel;
}
export default function AssetInspectionsTableRow({ row }: IProps) {
    const navigate = useNavigate();

    const chipColour = React.useMemo(() => {
        var date = new Date(row.dueDate);
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        var alertDate = new Date(today);
        alertDate.setDate(alertDate.getDate() + row.alertDays);

        if ((date >= today && date >= alertDate) || row.status === 'Reporting')
            return AlertColours.low;
        else if (date <= alertDate && date >= today)
            return AlertColours.medium;
        else
            return AlertColours.high;
    }, [row.dueDate]);

    const view = () => {
        navigate(
            '/Asset',
            {
                state: {
                    id: row.assetId
                }
            }
        );
    }

    return (
        <TableRow>
            <TableCell>{row.asset}</TableCell>
            <TableCell><Chip label={new Date(row.dueDate).toLocaleDateString('en-gb')} sx={{ backgroundColor: chipColour }} /></TableCell>
            <TableCell align="left">{row.inspectionType}</TableCell>
            <TableCell align="left">{row.status}</TableCell>
            <TableCell align="right">
                <Button variant="contained" size="small" onClick={view}>Go To Asset</Button>
            </TableCell>
        </TableRow>
    );
}