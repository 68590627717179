export enum Permission {
    ViewClients = "Permissions.Clients.View",
    CreateClients = "Permissions.Clients.Create",
    DeleteClients = "Permissions.Clients.Delete",
    ViewPlants = "Permissions.Plants.View",
    CreatePlants = "Permissions.Plants.Create",
    ViewAssets = "Permissions.Assets.View",
    CreateAssets = "Permissions.Assets.Create",
    ViewDocs = "Permissions.Documents.View",
    UploadDocs = "Permissions.Documents.Upload",
    DeleteDocs = "Permissions.Documents.Delete",
    DeleteDrafts = "Permissions.Documents.DeleteDraft",
    CreateReport = "Permissions.Documents.CreateReport",
    CreateWse = "Permissions.Documents.CreateWSE",
    SignOffReport = "Permissions.Documents.SignOffReport",
    SignOffWse = "Permissions.Documents.SignOffWSE",
    SignOffEscalatedDocument = "Permissions.Documents.SignOffEscalatedDocument",
    AcknowledgeDocument = "Permissions.Documents.AcknowledgeDocument",
    ManageUsers = "Permissions.Users.Manage"
}