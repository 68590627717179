import * as React from 'react';
import { SelectElement, TextFieldElement } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import DropdownViewModel from '../../Utilities/ViewModels/DropdownViewModel';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

interface IProps {
    editing: boolean;
}

export default function CreateStorage(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { editing } = props;
    const [loading, setLoading] = React.useState(false);
    const [tankClasses, setTankClasses] = React.useState<DropdownViewModel[]>([]);
    const [shellTypes, setShellTypes] = React.useState<DropdownViewModel[]>([]);
    const [floorTypes, setFloorTypes] = React.useState<DropdownViewModel[]>([]);
    const [roofTypes, setRoofTypes] = React.useState<DropdownViewModel[]>([]);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Base/GetDropdown?lookup=TankClass`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setTankClasses(data);
            },
            () => {
                setLoading(false);
            }
        );

        crabFetch(`Base/GetDropdown?lookup=ShellType`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setShellTypes(data);
            },
            () => {
                setLoading(false);
            }
        );

        crabFetch(`Base/GetDropdown?lookup=FloorType`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setFloorTypes(data);
            },
            () => {
                setLoading(false);
            }
        );

        crabFetch(`Base/GetDropdown?lookup=RoofType`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setRoofTypes(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="h6">Design Data</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="designPressure" shrink>Design Pressure</InputLabel>
                <TextFieldElement name="designPressure" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="designTemp" shrink>Design Temperature</InputLabel>
                <TextFieldElement name="designTemp" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="fluidService" shrink>Fluid Service</InputLabel>
                <TextFieldElement name="fluidService" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="specificGravity" shrink>Specific Gravity</InputLabel>
                <TextFieldElement name="specificGravity" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="operatingTemp" shrink>Operating Temperature</InputLabel>
                <TextFieldElement name="operatingTemp" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="maxFillHeight" shrink>Maximum Fill Height</InputLabel>
                <TextFieldElement name="maxFillHeight" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Construction</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="tankClass" shrink>Tank Class</InputLabel>
                <SelectElement
                    name="tankClass"
                    required
                    fullWidth
                    size="small"
                    options={tankClasses}
                    InputProps={{
                        disabled: loading || !editing,
                        startAdornment: (
                            !loading ? null : <CircularProgress size={20} />
                        )
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="shellType" shrink>Shell Type</InputLabel>
                <SelectElement
                    name="shellType"
                    required
                    fullWidth
                    size="small"
                    options={shellTypes}
                    InputProps={{
                        disabled: loading || !editing,
                        startAdornment: (
                            !loading ? null : <CircularProgress size={20} />
                        )
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="shellMaterial" shrink>Shell Material</InputLabel>
                <TextFieldElement name="shellMaterial" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="floorType" shrink>Floor Type</InputLabel>
                <SelectElement
                    name="floorType"
                    required
                    fullWidth
                    size="small"
                    options={floorTypes}
                    InputProps={{
                        disabled: loading || !editing,
                        startAdornment: (
                            !loading ? null : <CircularProgress size={20} />
                        )
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="floorMaterial" shrink>Floor Material</InputLabel>
                <TextFieldElement name="floorMaterial" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="roofType" shrink>Roof Type</InputLabel>
                <SelectElement
                    name="roofType"
                    required
                    fullWidth
                    size="small"
                    options={roofTypes}
                    InputProps={{
                        disabled: loading || !editing,
                        startAdornment: (
                            !loading ? null : <CircularProgress size={20} />
                        )
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="roofMaterial" shrink>Roof Material</InputLabel>
                <TextFieldElement name="roofMaterial" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="internalFloatingRoof" shrink>Internal Floating Roof</InputLabel>
                <SelectElement
                    name="internalFloatingRoof"
                    fullWidth
                    size="small"
                    options={[
                        { id: true, label: "Yes" },
                        { id: false, label: "No" }
                    ]}
                    InputProps={{
                        disabled: loading || !editing,
                        startAdornment: (
                            !loading ? null : <CircularProgress size={20} />
                        )
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="diameterTank" shrink>Diameter of Tank</InputLabel>
                <TextFieldElement name="diameterTank" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="heightTank" shrink>Height of Tank</InputLabel>
                <TextFieldElement name="heightTank" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Relief Valve Protection</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="deadWeightHatch" shrink>Dead Weight Hatch</InputLabel>
                <TextFieldElement name="deadWeightHatch" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="pvValve" shrink>P/V Valve</InputLabel>
                <TextFieldElement name="pvValve" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="atmosphericVent" shrink>Atmospheric Vent</InputLabel>
                <TextFieldElement name="atmosphericVent" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="other" shrink>Other</InputLabel>
                <TextFieldElement name="other" required fullWidth size="small" disabled={!editing} />
            </Grid>
        </React.Fragment>
    );
}