import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import SortableTableHead, { HeadCell, Order } from '../Utilities/SortableTableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import MyAssetsViewModel from './ViewModels/MyAssetsViewModel';
import MyAssetsTableRow from './MyAssetsTableRow';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';
import { debounce } from 'lodash';
import Autocomplete from '@mui/material/Autocomplete';

const headCells: HeadCell<MyAssetsViewModel>[] = [
    { id: 'plantItemNo', property: 'PlantItemNo', align: "left", disablePadding: false, label: 'Plant Item Number' },
    { id: 'assetType', property: 'AssetType', align: "left", disablePadding: false, label: 'Asset Type' },
    { id: 'description', property: 'Description', align: "left", disablePadding: false, label: 'Description' },
    { id: 'client', property: 'Client', align: "left", disablePadding: false, label: 'Client' },
    { id: 'plant', property: 'Plant', align: "left", disablePadding: false, label: 'Plant' },
    { id: 'nextInspection', property: 'NextInspection', align: "left", disablePadding: false, label: 'Next Inspection Due' },
];

export default function MyAssetsTable() {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [search, setSearch] = React.useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof MyAssetsViewModel>('plantItemNo');
    const [results, setResults] = React.useState<MyAssetsViewModel[]>([]);
    const [plant, setPlant] = React.useState<number>(0);
    const [plantsList, setPlantsList] = React.useState<DropdownViewModel[]>([]);
    const [type, setType] = React.useState<number>(0);
    const [typeList, setTypeList] = React.useState<DropdownViewModel[]>([]);

    React.useEffect(() => {
        getDropdowns();
    }, []);

    React.useEffect(() => {
        getData();
    }, [page, rowsPerPage, order, orderBy]);

    React.useEffect(() => {
        if (!loading || (loading && plant > 0)) {
            searchData();
        }
    }, [plant, type, search]);

    const getDropdowns = async () => {
        const token = await authService.getAccessToken();

        await crabFetch(`Plant/GetPlantsDropdown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                const newDropdownItem = new DropdownViewModel();
                newDropdownItem.id = 0;
                newDropdownItem.label = 'All Plants';
                data.unshift(newDropdownItem);
                setPlantsList(data);
            }
        );

        await crabFetch(`Asset/GetAssetTypesDropDown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setTypeList(data);
            }
        );
    }

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        await crabFetch(`Dashboard/GetMyAssetsTable?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&plant=${plant > 0 ? plant : ''}&type=${type > 0 ? type : ''}&search=${search}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setResults(data.rows);
                setCount(data.count);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof MyAssetsViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const changePlant = (input: string) => {
        const idString = plantsList.find(c => c.label === input)?.id;
        const parsedId = parseInt(idString as string ?? '0', 10);

        if (!isNaN(parsedId)) {
            setPlant(parsedId);
        }
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(event.target.value);
    }

    const debouncedhandleSearch = React.useCallback!(debounce(handleSearch, 600), []);

    const emptyRows = page > 0 ? Math.max(0, rowsPerPage - results.length) : 0;

    const searchData = () => {
        if (page === 0) {
            getData();
        }
        setPage(0);
    }

    const keyPress = (event: any) => {
        if (event.keyCode === 13) {
            searchData();
        }
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Paper>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12}>
                                    <Typography variant="h2">My Assets</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">Assets at the plants you manage</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        color="primary"
                                        size="small"
                                        variant="outlined"
                                        select
                                        onChange={(value) => setType(parseInt(value.target.value))}
                                        value={type}
                                    >
                                        <MenuItem value={0}>All Types</MenuItem>
                                        {typeList.map(item =>
                                            <MenuItem key={'type' + item.id} value={item.id}>{item.label}</MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                {plantsList.length > 0 &&
                                    <Grid item>
                                        <Autocomplete
                                            disablePortal
                                            fullWidth
                                            size="small"
                                            options={plantsList}
                                            sx={{ minWidth: 150 }}
                                            onInputChange={(value, newInputValue) => changePlant(newInputValue)}
                                            value={plantsList.find(f => f.id === plant)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Grid>
                                }
                                <Grid item>
                                    <TextField
                                        sx={{ minWidth: 300 }}
                                        color="primary"
                                        size="small"
                                        variant="outlined"
                                        onChange={debouncedhandleSearch}
                                        value={search}
                                        placeholder="Search..."
                                        InputProps={{ endAdornment: <SearchIcon /> }}
                                        onKeyDown={keyPress}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer>
                                <ResponsiveTable
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    aria-label="assets table"
                                >
                                    <SortableTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        headCells={headCells}
                                        lastCells={<TableCell />}
                                    />
                                    <TableBody>
                                        {(!loading && results) && results.map((row: MyAssetsViewModel) => {
                                            return (<MyAssetsTableRow key={row.id} row={row} />);
                                        })
                                        }
                                        {count <= 0 && !loading &&
                                            <TableRow
                                                sx={{
                                                    height: 53 * rowsPerPage,
                                                }}
                                            >
                                                <TableCell colSpan={headCells.length + 1} align="center">No Results Found</TableCell>
                                            </TableRow>
                                        }
                                        {count > 0 && emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: 53 * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={headCells.length + 1} />
                                            </TableRow>
                                        )}
                                        {loading &&
                                            <TableRow
                                                sx={{
                                                    height: 53 * rowsPerPage,
                                                }}
                                            >
                                                <TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="primary" /></TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </ResponsiveTable>
                            </TableContainer>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}