import * as React from 'react';
import SortableTableHead, { HeadCell, Order } from '../Utilities/SortableTableHead';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import AssetsTableRow from './AssetsTableRow';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import AssetListViewmodel from './Viewmodels/AssetListViewmodel';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';
import { useLocation } from 'react-router';
import PermissionCheck from '../Utilities/PermissionCheck';
import { Permission } from '../Permissions/Permissions';
import { FilterContext } from '../Contexts/FilterContext';
import CreateAsset from './AssetDetails/CreateAsset';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from 'lodash';

const headCells: HeadCell<AssetListViewmodel>[] = [
	{ id: 'plantItemNumber', property: 'PlantItemNumber', align: "left", disablePadding: false, label: 'Plant Item Number' },
	{ id: 'assetType', property: 'AssetType', align: "left", disablePadding: false, label: 'Asset Type' },
	{ id: 'description', property: 'Description', align: "left", disablePadding: false, label: 'Description' },
	{ id: 'client', property: 'Client', align: "left", disablePadding: false, label: 'Client' },
	{ id: 'plant', property: 'Plant', align: "left", disablePadding: false, label: 'Plant' },
	{ id: 'nextInspectionDue', property: 'NextInspectionDue', align: "left", disablePadding: false, label: 'Next Inspection Due' },
];

interface LocationState {
	plantId: number;
}

export default function AssetsTable() {
	const location = useLocation();
	const { plantId } = location.state as LocationState || {
		plantId: 0
	}
	const { client, plant, updateClient, updatePlant } = React.useContext(FilterContext);
	const { crabFetch } = React.useContext(TokenRefreshContext);
	const [order, setOrder] = React.useState<Order>('asc');
	const [orderBy, setOrderBy] = React.useState<keyof AssetListViewmodel>('plantItemNumber');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [results, setResults] = React.useState<AssetListViewmodel[]>([]);
	const [search, setSearch] = React.useState('');
	const [count, setCount] = React.useState(0);
	const [loading, setLoading] = React.useState(true);
	const [clientList, setClientList] = React.useState<DropdownViewModel[]>([]);
	const [plantsList, setPlantsList] = React.useState<DropdownViewModel[]>([]);
	const [type, setType] = React.useState<number>(0);
	const [typeList, setTypeList] = React.useState<DropdownViewModel[]>([]);
	const [openAdd, setOpenAdd] = React.useState(false);
	const [activeAssets, setActiveAssets] = React.useState<number>(0);

	React.useEffect(() => {
		getDropdowns();
	}, []);

	React.useEffect(() => {
		if (plantId > 0)
			updatePlant(plantId);
	}, [plantId]);

	React.useEffect(() => {
		getPlants();
	}, [client]);

	React.useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		
		getData(signal);

		return () => {
			controller.abort();
		}
	}, [page, rowsPerPage, order, orderBy]);

	React.useEffect(() => {
		searchData();
	}, [plant, client, type, search, activeAssets]);

	const getDropdowns = async () => {
		const token = await authService.getAccessToken();

		await crabFetch(`Client/GetClientsDropDown`, {
			headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
		}, ResponseType.JSON,
			(data: DropdownViewModel[]) => {
				const newDropdownItem = new DropdownViewModel();
				newDropdownItem.id = 0;
				newDropdownItem.label = 'All Clients';
				data.unshift(newDropdownItem);
				setClientList(data);
			}
		);

		await crabFetch(`Asset/GetAssetTypesDropDown`, {
			headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
		}, ResponseType.JSON,
			(data: any) => {
				setTypeList(data);
			}
		);

		getPlants();
	}

	const getPlants = async () => {
		const token = await authService.getAccessToken();

		await crabFetch(`Plant/GetPlantsDropdown?client=${client > 0 ? client : ''}`, {
			headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
		}, ResponseType.JSON,
			(data: any) => {
				const newDropdownItem = new DropdownViewModel();
				newDropdownItem.id = 0;
				newDropdownItem.label = 'All Plants';
				data.unshift(newDropdownItem);
				setPlantsList(data);
			}
		);
	}

	const getData = async (signal?: AbortSignal) => {
		setLoading(true);
		const token = await authService.getAccessToken();
		const orderByProp = headCells.find(f => f.id === orderBy)!.property;

		await crabFetch(`Asset/GetAssetsTable?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&client=${client > 0 ? client : ''}&plant=${plant > 0 ? plant : ''}&type=${type > 0 ? type : ''}&active=${activeAssets === 2 ? '' : activeAssets === 0 ? false : true}&search=${search}`, {
			signal: signal,
			headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
		}, ResponseType.JSON,
			(data: any) => {
				setResults(data.rows);
				setCount(data.count);
				setLoading(false);
			},
			() => {
				setLoading(false);
			}
		);
	}

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof AssetListViewmodel) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const searchData = () => {
		if (page === 0) {
			getData();
		}

		setPage(0);
	}

	const closeAdd = (refresh: boolean) => {
		setOpenAdd(false);

		if (refresh)
			searchData();
	}

	const setClient = (input: string) => {
		const idString = clientList.find(c => c.label === input)?.id;
		const parsedId = parseInt(idString as string ?? '0', 10);

		if (!isNaN(parsedId)) {
			updateClient(parsedId);
		}
	}

	const setPlant = (input: string) => {
		const idString = plantsList.find(c => c.label === input)?.id;
		const parsedId = parseInt(idString as string ?? '0', 10);

		if (!isNaN(parsedId)) {
			updatePlant(parsedId);
		}
	}

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setSearch(event.target.value);
	}

	const debouncedhandleSearch =
		React.useCallback!(
			debounce(handleSearch, 600),
			[]
		);

	const handleChangePlantType = (event: React.ChangeEvent<HTMLInputElement>) => {
		setActiveAssets(parseInt(event.target.value))
	}

	const emptyRows = page > 0 ? Math.max(0, rowsPerPage - results.length) : 0;

	return (
		<PermissionCheck permission={Permission.ViewAssets}>
			<Grid container spacing={2}>
				<Grid item>
					<Typography variant="h1">Assets</Typography>
				</Grid>
				<Grid item xs={12}>
					<Paper>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Grid container spacing={1} alignItems="center" justifyContent="space-between">
									<Grid item>
										<Grid container spacing={1} alignItems="center">
											<Grid item>
												<TextField
													color="primary"
													size="small"
													variant="outlined"
													select
													onChange={(value) => setType(parseInt(value.target.value))}
													value={type}
												>
													<MenuItem value={0}>All Types</MenuItem>
													{typeList.map(item =>
														<MenuItem key={'type' + item.id} value={item.id}>{item.label}</MenuItem>
													)}
												</TextField>
											</Grid>
											{clientList.length > 0 &&
												<Grid item>
													<Autocomplete
														disablePortal
														size="small"
														options={clientList}
														sx={{ width: 150 }}
														onInputChange={(value, newInputValue) => setClient(newInputValue)}
														value={clientList.find(f => f.id === client)}
														renderInput={(params) => <TextField {...params} />}
													/>
												</Grid>
											}
											{plantsList.length > 0 &&
												<Grid item>
													<Autocomplete
														disablePortal
														fullWidth
														size="small"
														options={plantsList}
														sx={{ width: 150 }}
														onInputChange={(value, newInputValue) => setPlant(newInputValue)}
														value={plantsList.find(f => f.id === plant)}
														renderInput={(params) => <TextField {...params} />}
													/>
												</Grid>
											}
											<Grid item>
												<TextField
													color="primary"
													size="small"
													variant="outlined"
													select
													onChange={handleChangePlantType}
													value={activeAssets}
												>
													<MenuItem value={2}>All Assets</MenuItem>
													<MenuItem value={0}>Active Assets</MenuItem>
													<MenuItem value={1}>Archived Assets</MenuItem>
												</TextField>
											</Grid>
											<Grid item>
												<TextField
													sx={{ minWidth: 300 }}
													color="primary"
													size="small"
													variant="outlined"
													onChange={debouncedhandleSearch}
													placeholder="Search..."
													InputProps={{ endAdornment: <SearchIcon /> }}
												/>
											</Grid>
										</Grid>
									</Grid>
									<PermissionCheck permission={Permission.CreateAssets}>
										<Grid item>
											<Button aria-label={"Add Asset"} onClick={() => setOpenAdd(true)} variant="contained" color="primary">Add New Asset</Button>
										</Grid>
									</PermissionCheck>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<TableContainer>
									<ResponsiveTable
										aria-labelledby="tableTitle"
										size={'medium'}
										aria-label="asset table"
									>
										<SortableTableHead
											order={order}
											orderBy={orderBy}
											onRequestSort={handleRequestSort}
											headCells={headCells}
											lastCells={<TableCell />}
										/>
										<TableBody>
											{(!loading && results) && results.map((row: AssetListViewmodel) => {
												return (<AssetsTableRow key={row.id} row={row} refresh={searchData} />);
											})
											}
											{count <= 0 && !loading &&
												<TableRow
													sx={{
														height: 53 * rowsPerPage,
													}}
												>
													<TableCell colSpan={headCells.length + 1} align="center">No Results Found</TableCell>
												</TableRow>
											}
											{count > 0 && emptyRows > 0 && (
												<TableRow
													style={{
														height: 53 * emptyRows,
													}}
												>
													<TableCell colSpan={headCells.length + 1} />
												</TableRow>
											)}
											{loading &&
												<TableRow
													sx={{
														height: 53 * rowsPerPage,
													}}
												>
													<TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="primary" /></TableCell>
												</TableRow>
											}
										</TableBody>
									</ResponsiveTable>
								</TableContainer>
								<Grid container justifyContent="space-between" alignItems="center">
									<Grid item>
										<TablePagination
											rowsPerPageOptions={[5, 10, 25]}
											component="div"
											count={count}
											rowsPerPage={rowsPerPage}
											page={page}
											onPageChange={handleChangePage}
											onRowsPerPageChange={handleChangeRowsPerPage}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				{openAdd &&
					<PermissionCheck permission={Permission.CreateAssets}>
						<CreateAsset open={openAdd} onClose={closeAdd} />
					</PermissionCheck>
				}
			</Grid>
		</PermissionCheck>
	);
}