import * as React from 'react';
import SortableTableHead, { HeadCell, Order } from '../../Utilities/SortableTableHead';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../../Utilities/ResponsiveTable';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import authService from '../../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../../Contexts/TokenRefreshContext';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import ActionsTableRow from './ActionsTableRow';
import ActionViewmodel from '../Viewmodels/ActionViewmodel';

const headCells: HeadCell<ActionViewmodel>[] = [
    { id: 'type', property: 'Type', align: "left", disablePadding: false, label: 'Action Type' },
    { id: 'description', property: 'Description', align: "left", disablePadding: false, label: 'Description' },
    { id: 'date', property: 'Date', align: "left", disablePadding: false, label: 'Due Date' },
    { id: 'complete', property: 'Complete', align: "left", disablePadding: false, label: 'Status' },
    { id: 'outcome', property: 'Outcome', align: "left", disablePadding: false, label: 'Outcome' },
];

interface IProps {
    assetId: number;
}

export default function ActionsTable(props: IProps) {
    const { assetId } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof ActionViewmodel>('date');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [results, setResults] = React.useState<ActionViewmodel[]>([]);
    const [search, setSearch] = React.useState('');
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [type, setType] = React.useState<string>('All');
    const [status, setStatus] = React.useState<string>('All');

    React.useEffect(() => {
        if(assetId > 0)
            getData();
    }, [page, rowsPerPage, order, orderBy, assetId]);

    React.useEffect(() => {
        searchData();
    }, [type, status, search]);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        await crabFetch(`Action/GetActionsTable?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&assetId=${assetId > 0 ? assetId : ''}&type=${type === 'All' ? '' : type}&status=${status === 'All' ? '' : status === 'true' ? true : false}&search=${search}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setResults(data.rows);
                setCount(data.count);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ActionViewmodel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const searchData = () => {
        if (page === 0) {
            getData();
        }
        setPage(0);
    }

    const keyPress = (event: any) => {
        if (event.keyCode === 13) {
            searchData();
        }
    }
    
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(event.target.value);
    }

    const debouncedhandleSearch =
        React.useCallback!(
            debounce(handleSearch, 600),
            []
        );

    const emptyRows = page > 0 ? Math.max(0, rowsPerPage - results.length) : 0;

    return (
        <Paper>
            <Grid container spacing={2}>
                <Grid item>
                    <Typography variant="h2">Actions</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item>
                                    <TextField
                                        color="primary"
                                        size="small"
                                        variant="outlined"
                                        select
                                        onChange={(value) => setType(value.target.value)}
                                        value={type}
                                    >
                                        <MenuItem value={'All'}>All Action Types</MenuItem>
                                        <MenuItem value={'Mandatory'}>Mandatory</MenuItem>
                                        <MenuItem value={'Recommendation'}>Recommended</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        color="primary"
                                        size="small"
                                        variant="outlined"
                                        select
                                        onChange={(value) => setStatus(value.target.value)}
                                        value={status}
                                    >
                                        <MenuItem value={'All'}>All Statuses</MenuItem>
                                        <MenuItem value={'false'}>Outstanding</MenuItem>
                                        <MenuItem value={'true'}>Completed</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        sx={{ minWidth: 300 }}
                                        color="primary"
                                        size="small"
                                        variant="outlined"
                                        onChange={debouncedhandleSearch}
                                        placeholder="Search..."
                                        InputProps={{ endAdornment: <SearchIcon /> }}
                                        onKeyDown={keyPress}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer>
                                <ResponsiveTable
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    aria-label="actions table"
                                >
                                    <SortableTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        headCells={headCells}
                                        lastCells={<TableCell />}
                                    />
                                    <TableBody>
                                        {(!loading && results) && results.map((row: ActionViewmodel) => {
                                            return (<ActionsTableRow key={row.id} row={row} refresh={getData} />);
                                        })
                                        }
                                        {count <= 0 && !loading &&
                                            <TableRow
                                                sx={{
                                                    height: 53 * rowsPerPage,
                                                }}
                                            >
                                                <TableCell colSpan={headCells.length + 1} align="center">No Results Found</TableCell>
                                            </TableRow>
                                        }
                                        {count > 0 && emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: 53 * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={headCells.length + 1} />
                                            </TableRow>
                                        )}
                                        {loading &&
                                            <TableRow
                                                sx={{
                                                    height: 53 * rowsPerPage,
                                                }}
                                            >
                                                <TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="primary" /></TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </ResponsiveTable>
                            </TableContainer>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}