import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import FileUpload from '../Utilities/FileUpload';
import CloseDialogConfirmation from '../Utilities/CloseDialogConfimation';
import FileViewModel from './Viewmodels/FileViewModel';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    returnFile: (file: FileViewModel) => void;
}

export default function UploadFileDialog(props: IProps) {
    const { open, onClose, returnFile } = props;
    const [logo, setLogo] = React.useState<File[]>([]);
    const [openConfimation, setOpenConfimation] = React.useState(false);

    const submit = async (form: any, event?: React.BaseSyntheticEvent) => {
        event?.preventDefault();
     
        var file = new FileViewModel()
        file.description = form.description;
        if (logo.length > 0) {
            file.attachment = logo[0];
        }
        returnFile(file);
        setLogo([]);
        onClose(true);
    }

    const onFileDrop = (newFiles: File[]) => {
        let existingFiles = [...logo];
        existingFiles = existingFiles.concat(newFiles);
        setLogo(existingFiles);
    }

    const onFileDelete = (index: number) => {
        const existingFiles = [...logo];
        existingFiles.splice(index, 1);
        setLogo(existingFiles);
    }

    const closeDialogs = (refresh: boolean) => {
        onClose(refresh);
        setOpenConfimation(false);
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="xs"
            fullWidth
        >
            <FormContainer onSuccess={submit}>
                <DialogTitle>{"Upload Attachment"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} spacing={2}>
                            <InputLabel htmlFor="logo" shrink>File</InputLabel>
                            <FileUpload onDelete={onFileDelete} onDrop={onFileDrop} files={logo}/>
                        </Grid>
                        <Grid item xs={12} spacing={2}>
                            <InputLabel htmlFor="description" shrink>Description</InputLabel>
                            <TextFieldElement name="description" required fullWidth size="small" />
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button onClick={() => setOpenConfimation(true)}>Cancel</Button>
                        <Button variant="contained" type="submit">Save Attachment</Button>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
            <CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'Are you sure you want to discard any changes?'} open={openConfimation} onClose={closeDialogs}></CloseDialogConfirmation>
        </Dialog>
    );
}
