import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import { AlertContext } from '../../Contexts/AlertContext';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseDialogConfirmation from '../../Utilities/CloseDialogConfimation';
import ComponentViewmodel from '../Viewmodels/ComponentViewmodel';
import CreatePressureComponent from './CreatePressureComponent';
import CreateReliefComponent from './CreateReliefComponent';
import InputLabel from '@mui/material/InputLabel';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    id: number;
    assetType: number;
}

export default function EditComponent(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose, id, assetType } = props;
    const [loading, setLoading] = React.useState(false);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [component, setComponent] = React.useState(new ComponentViewmodel());

    const formContext = useForm<ComponentViewmodel>({
        defaultValues: new ComponentViewmodel()
    });

    const { reset } = formContext;

    React.useEffect(() => {
        getData();
    }, [id]);

    React.useEffect(() => {
        if (component.id! > 0)
            reset(component);
    }, [component]);

    React.useEffect(() => {
        const newComp = new ComponentViewmodel();
        reset(newComp);
    }, [open]);

    const getData = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Component/GetComponent?Id=${id}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setLoading(false);
                setComponent(data);
            },
            () => {
                setLoading(false);
            });
    }

    const submit = async (form: any, event?: React.BaseSyntheticEvent<object, any, any>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch('Component/EditComponent', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully updated component");
                    onClose(true);
                }

                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    const closeDialogs = (refresh: boolean) => {
        if (refresh) {
            onClose(refresh);
        }

        setOpenConfirmation(false);
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="md"
            fullWidth
        >
            <FormContainer
                formContext={formContext}
                onSuccess={submit}
                FormProps={{ id: 'createComponent' }}
            >
                <DialogTitle>{"Edit Component"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <InputLabel htmlFor="name" shrink>Name</InputLabel>
                            <TextFieldElement name="name" required fullWidth size="small" />
                        </Grid>
                        {/* pressure piping or vessel */}
                        {(assetType === 1 || assetType === 2) &&
                            <CreatePressureComponent assetType={assetType} editing={true} />
                        }
                        {/* 6 = relief system */}
                        {assetType === 6 &&
                            <CreateReliefComponent editing={true} />
                        }
                    </Grid>
                    <DialogActions>
                        <Button onClick={() => setOpenConfirmation(true)} color="error" disabled={loading}>Cancel</Button>
                        <LoadingButton variant="contained" type="submit" disabled={loading} loading={loading}>{"Update"}</LoadingButton>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
            <CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'Are you sure you want to discard any changes?'} open={openConfirmation} onClose={closeDialogs}></CloseDialogConfirmation>
        </Dialog>
    );
}