import * as React from 'react';
import { FieldArrayWithId, TextFieldElement, UseFieldArrayRemove, UseFieldArrayAppend } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid';
import InspectionReportViewmodel from './Viewmodels/InspectionReportViewmodel';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import SingleFileUpload from '../Utilities/SingleFileUpload';
import InputLabel from '@mui/material/InputLabel';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import FileViewmodel from '../Utilities/ViewModels/FileViewmodel';
import PermissionCheck from '../Utilities/PermissionCheck';
import { Permission } from '../Permissions/Permissions';

interface IProps {
    editing: boolean;
    fields: FieldArrayWithId<InspectionReportViewmodel, 'photos', 'id'>[];
    remove: UseFieldArrayRemove;
    append: UseFieldArrayAppend<InspectionReportViewmodel, 'photos'>;
}

export default function AttachedPhotos(props: IProps) {
    const { editing, fields, remove, append } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [open, setOpen] = React.useState(false);
    const [newPhoto, setNewPhoto] = React.useState<File | null>(null);
    const [caption, setCaption] = React.useState('');

    const download = async (field: FieldArrayWithId<InspectionReportViewmodel, "photos", 'id'>) => {
        if (field.fileId) {
            const token = await authService.getAccessToken();

            crabFetch(`InspectionReport/DownloadFile?id=${field.fileId}`, {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            }, ResponseType.Blob,
                ((blob: Blob) => {
                    const url = URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.download = field.fileName;
                    a.href = url;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                })
            );
        } else if (field.file) {
            const url = URL.createObjectURL(field.file);
            var a = document.createElement('a');
            a.download = field.file.name;
            a.href = url;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        }
    }

    const upload = () => {
        const vm = new FileViewmodel();
        vm.file = newPhoto;
        vm.description = caption;
        vm.fileName = newPhoto?.name ?? '';
        append(vm);
        closeUpload();
    }

    const closeUpload = () => {
        setNewPhoto(null);
        setCaption('');
        setOpen(false);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TableContainer>
                    <ResponsiveTable
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="photos table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>File Name</TableCell>
                                <TableCell />
                                <TableCell>Caption</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fields.map((field, index) => {
                                return (
                                    <TableRow key={field.id}>
                                        <TableCell sx={{ backgroundColor: '#fff !important' }}>
                                            <TextField fullWidth size="small" disabled value={field.fileId ? field.fileName : field.file ? field.file.name : ''} />
                                        </TableCell>
                                        <TableCell align="right" sx={{ backgroundColor: '#fff !important', width: 64 }}>
                                            <IconButton size="small" onClick={() => download(field)}><DownloadIcon /></IconButton>
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: '#fff !important' }}>
                                            <TextFieldElement name={`photos.${index}.description`} fullWidth size="small" disabled={!editing} />
                                        </TableCell>
                                        <TableCell align="right" sx={{ backgroundColor: '#fff !important', width: 64 }}>
                                            <IconButton size="small" onClick={() => remove(index)} disabled={!editing}><DeleteIcon /></IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </ResponsiveTable>
                </TableContainer>
            </Grid>
            {editing &&
                <PermissionCheck permission={Permission.CreateReport}>
                    <Grid item>
                        <Button variant="contained" size="small" onClick={() => setOpen(true)}>Upload Another Photo</Button>
                    </Grid>
                </PermissionCheck>
            }
            <Dialog
                open={open}
                onClose={closeUpload}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>{"Add Photo"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="photo" shrink>Photo</InputLabel>
                            <SingleFileUpload onDelete={() => setNewPhoto(null)} onDrop={setNewPhoto} file={newPhoto} acceptedTypes={"image/*"} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="caption" shrink>Caption</InputLabel>
                            <TextField name="caption" value={caption} onChange={(e) => setCaption(e.target.value)} required fullWidth size="small" />
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button onClick={closeUpload}>Cancel</Button>
                        <Button variant="contained" onClick={upload}>Add Photo</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </Grid>
    );
}