import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import SortableTableHead, { HeadCell, Order } from '../Utilities/SortableTableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import AwaitingApprovalTableRow from './AwaitingApprovalTableRow';
import DraftsViewmodel from './ViewModels/DraftsViewModel';
import { debounce } from 'lodash';

const headCells: HeadCell<DraftsViewmodel>[] = [
    { id: 'documentType', property: 'DocumentType', align: "left", disablePadding: false, label: 'Document Type' },
    { id: 'reference', property: 'Reference', align: "left", disablePadding: false, label: 'Reference' },
    { id: 'assetType', property: 'AssetType', align: "left", disablePadding: false, label: 'Asset Type' },
    { id: 'description', property: 'Description', align: "left", disablePadding: false, label: 'Description' },
    { id: 'client', property: 'Client', align: "left", disablePadding: false, label: 'Client' },
    { id: 'owner', property: 'Owner', align: "left", disablePadding: false, label: 'Owner' },
];

export default function DashboardApprovalsTable() {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [search, setSearch] = React.useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof DraftsViewmodel>('reference');
    const [results, setResults] = React.useState<DraftsViewmodel[]>([]);
    const [type, setType] = React.useState<string>('All');

    React.useEffect(() => {
        getData();
    }, [page, rowsPerPage, order, orderBy]);

    React.useEffect(() => {
        searchData();
    }, [type, search]);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        await crabFetch(`Dashboard/GetAwaitingApprovalTable?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&type=${type === 'All' ? '' : type}&search=${search}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setResults(data.rows);
                setCount(data.count);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof DraftsViewmodel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(event.target.value);
    }

    const debouncedhandleSearch = React.useCallback!(debounce(handleSearch, 600), []);

    const emptyRows = page > 0 ? Math.max(0, rowsPerPage - results.length) : 0;

    const searchData = () => {
        if (page === 0) {
            getData();
        }
        setPage(0);
    }

    const keyPress = (event: any) => {
        if (event.keyCode === 13) {
            searchData();
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Paper>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography variant="h2">Documents Awaiting Approval</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1">These documents are awaiting your approval.</Typography>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                color="primary"
                                                size="small"
                                                variant="outlined"
                                                select
                                                onChange={(value) => setType(value.target.value)}
                                                value={type}
                                            >
                                                <MenuItem value={'All'}>All Documents</MenuItem>
                                                <MenuItem value={'Wse'}>WSEs</MenuItem>
                                                <MenuItem value={'Report'}>Inspection Reports</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                sx={{ minWidth: 300 }}
                                                color="primary"
                                                size="small"
                                                variant="outlined"
                                                onChange={debouncedhandleSearch}
                                                placeholder="Search..."
                                                InputProps={{ endAdornment: <SearchIcon /> }}
                                                onKeyDown={keyPress}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer>
                                <ResponsiveTable
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    aria-label="awaiting approval table"
                                >
                                    <SortableTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        headCells={headCells}
                                        lastCells={<TableCell />}
                                    />
                                    <TableBody>
                                        {(!loading && results) && results.map((row: DraftsViewmodel) => {
                                            return (<AwaitingApprovalTableRow key={row.id} row={row} />);
                                        })
                                        }
                                        {count <= 0 && !loading &&
                                            <TableRow
                                                sx={{
                                                    height: 53 * rowsPerPage,
                                                }}
                                            >
                                                <TableCell colSpan={headCells.length + 1} align="center">No Results Found</TableCell>
                                            </TableRow>
                                        }
                                        {count > 0 && emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: 53 * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={headCells.length + 1} />
                                            </TableRow>
                                        )}
                                        {loading &&
                                            <TableRow
                                                sx={{
                                                    height: 53 * rowsPerPage,
                                                }}
                                            >
                                                <TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="primary" /></TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </ResponsiveTable>
                            </TableContainer>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}