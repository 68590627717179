import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DraftListViewmodel from '../Viewmodels/DraftListViewmodel';
import PermissionCheck from '../../Utilities/PermissionCheck';
import { Permission } from '../../Permissions/Permissions';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

interface IProps {
    assetId: number;
    row: DraftListViewmodel;
    refresh: () => void;
}

export default function DocHistoryTableRow(props: IProps) {
    const { assetId, row } = props;
    const navigate = useNavigate();

    const view = () => {
        if (row.documentType === 'WSE') {
            navigate(
                `/Wse/${assetId}/${row.id}`
            );
        } else {
            navigate(
                `/InspectionReport/${assetId}/${row.id}`
            );
        }
    }

    return (
        <TableRow>
            <TableCell>{row.documentType}</TableCell>
            <TableCell>{row.reference}</TableCell>
            <TableCell>{row.status}</TableCell>
            <TableCell>{row.lastActivityDate ? new Date(row.lastActivityDate).toLocaleDateString() : ''}</TableCell>
            <TableCell align="right">
                <PermissionCheck permission={Permission.ViewDocs}>
                    <Button variant="contained" onClick={view}>View</Button>
                </PermissionCheck>
            </TableCell>
        </TableRow>
    );
}