import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import { AlertContext } from '../../Contexts/AlertContext';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseDialogConfirmation from '../../Utilities/CloseDialogConfimation';
import SafetyDeviceViewmodel from '../Viewmodels/SafetyDeviceViewmodel';
import InputLabel from '@mui/material/InputLabel';
import DropdownViewModel from '../../Utilities/ViewModels/DropdownViewModel';
import CircularProgress from '@mui/material/CircularProgress';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    assetDetailId: number;
    assetType: number;
}

export default function CreateSafetyDevice(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose, assetDetailId, assetType } = props;
    const [types, setTypes] = React.useState<DropdownViewModel[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);

    const formContext = useForm<SafetyDeviceViewmodel>({
        defaultValues: new SafetyDeviceViewmodel()
    });

    const { reset } = formContext;

    React.useEffect(() => {
        const newComp = new SafetyDeviceViewmodel();
        newComp.assetDetailId = assetDetailId;
        newComp.assetType = assetType;

        reset(newComp);
    }, [open, assetDetailId, assetType]);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Base/GetDropdown?lookup=EquipmentType`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setTypes(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const submit = async (form: any, event?: React.BaseSyntheticEvent<object, any, any>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch('SafetyDevice/CreateSafetyDevice', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully created safety device");
                    onClose(true);
                }

                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    const closeDialogs = (refresh: boolean) => {
        if (refresh)
            onClose(refresh);

        setOpenConfirmation(false);
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="md"
            fullWidth
        >
            <FormContainer
                formContext={formContext}
                onSuccess={submit}
                FormProps={{ id: 'createDevice' }}
            >
                <DialogTitle>{"Add New Safety Device"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <InputLabel htmlFor="name" shrink>Name</InputLabel>
                            <TextFieldElement name="name" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <InputLabel htmlFor="type" shrink>Type</InputLabel>
                            <SelectElement
                                name="type"
                                required
                                fullWidth
                                size="small"
                                options={types}
                                InputProps={{
                                    disabled: loading,
                                    startAdornment: (
                                        !loading ? null : <CircularProgress size={20} />
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <InputLabel htmlFor="equipmentNumber" shrink>Equipment Number</InputLabel>
                            <TextFieldElement name="equipmentNumber" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <InputLabel htmlFor="coldDifferential" shrink>Cold Differential Set Pressure</InputLabel>
                            <TextFieldElement name="coldDifferential" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <InputLabel htmlFor="reliefSystemWse" shrink>Relief System WSE No.</InputLabel>
                            <TextFieldElement name="reliefSystemWse" required fullWidth size="small" />
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button onClick={() => setOpenConfirmation(true)} color="error" disabled={loading}>Cancel</Button>
                        <LoadingButton variant="contained" type="submit" disabled={loading} loading={loading}>{"Create"}</LoadingButton>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
            <CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'Are you sure you want to discard any changes?'} open={openConfirmation} onClose={closeDialogs}></CloseDialogConfirmation>
        </Dialog>
    );
}