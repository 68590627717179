import FileViewmodel from "../../Utilities/ViewModels/FileViewmodel";
import ActionViewmodel from "./ActionViewmodel";

export default class InspectionReportViewmodel {
    public id: number | null = null;
    public ownerName: string = "";
    public ownerId: string = "";
    public viewingUserCanEdit: boolean = false;
    public assetId: number = 0;
    public assetDetailId: number = 0;
    public wseId: number = 0;
    public wseNumber: string = '';
    public reportNumber: string = '';
    public associatedReliefDevices: boolean = false;
    public briefHistory: string | undefined = undefined;
    public description: string | undefined = undefined;
    public limitations: string | undefined = undefined;
    public reliefSystemInspected: boolean | undefined = undefined;
    public reliefSystemInDate: boolean | undefined = undefined;
    public detailedReport: string | undefined = undefined;
    public ndtSummary: string | undefined = undefined;
    public wseChanges: string | undefined = undefined;
    public previousReportReviewed: number | undefined = undefined;
    public equipmentSuitable: boolean | undefined = undefined;
    public wseRequireAmending: boolean | undefined = undefined;
    public carriedOutOn: Date | undefined = undefined;
    public thoroughIntervalChange: number | undefined = undefined;
    public thoroughInterval: string | undefined = undefined;
    public thoroughDueBefore: Date | undefined = undefined;
    public intermediateAInterval: string | undefined = undefined;
    public intermediateADueBefore: Date | undefined = undefined;
    public intermediateBInterval: string | undefined = undefined;
    public intermediateBDueBefore: Date | undefined = undefined;
    public actions: ActionViewmodel[] | undefined = undefined;
    public photos: FileViewmodel[] | undefined = undefined;
    public files: FileViewmodel[] | undefined = undefined;
    public inspections: number[] | undefined = undefined;
}