import * as React from 'react';
import PermissionsListViewModel from './Viewmodels/PermissionsListViewModel';
import authService from '../api-authorization/AuthorizeService';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import { AlertContext } from '../Contexts/AlertContext';
import LinearProgress from '@mui/material/LinearProgress';
import Collapse from '@mui/material/Collapse';
import UserTypeListViewModel from './Viewmodels/UserTypeListViewModel';
import PermissionList from './PermissionList';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Bugsnag from '@bugsnag/js';
import Paper from '@mui/material/Paper';

export default function ManageRolePermissions() {
    const { show } = React.useContext(AlertContext);
    const [list, setList] = React.useState<PermissionsListViewModel[]>([]);
    const [typeList, setTypeList] = React.useState<UserTypeListViewModel[]>([]);
    const [currentType, setCurrentType] = React.useState(1);
    const [loading, setLoading] = React.useState(true);
    const [saving, setSaving] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, []);

    React.useEffect(() => {
        if ((typeList && typeList.length > 0) && (list && list.length > 0)) {
            const newList = list.map((item) => {
                const newItem = { ...item };

                if (typeList.find(f => f.id === currentType)?.permissions.some(s => s.name === item.name)) {
                    newItem.enabled = true;
                } else {
                    newItem.enabled = false;
                }

                return newItem;
            });

            setList(newList);
        }
    }, [currentType, typeList]);

    const getData = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        fetch(`Permissions/GetPermissions`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.json())
            .then(data => {
                const list = data.map((item: PermissionsListViewModel, index: number) => {
                    const splitName = item.name.split('.');
                    const splitAction = splitName[2].split(/(?=[A-Z])/).join(" ");
                    return new PermissionsListViewModel(index, item.name, splitName[1], splitAction, item.enabled);
                });
                setList(list);
                getTypeData();
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const getTypeData = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        fetch(`Permissions/GetUserTypes`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.json())
            .then(data => {
                setTypeList(data);
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const save = async () => {
        const token = await authService.getAccessToken();
        setSaving(true);

        fetch(`Permissions/UpdateUserTypePermissions`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': `Bearer ${token}` },
            method: 'POST',
            body: JSON.stringify(typeList)
        })
            .then(response => response.text())
            .then(data => {
                setSaving(false);

                if (data.length > 0) {
                    show('error', data);
                } else {
                    show('success', `Permissions successfully updated.`);
                }
            })
            .catch(error => {
                Bugsnag.notify(error);
                setSaving(false);
                show('error', 'A server error has occurred, please try again.');
            });
    }

    const handleToggle = (index: number) => {
        const newList = [...typeList];
        const name = list[index].name;
        const typeIndex = newList.findIndex(f => f.id === currentType);

        const itemIndex = newList[typeIndex].permissions.findIndex(f => f.name === name);

        if (itemIndex > -1) {
            newList[typeIndex].permissions.splice(itemIndex, 1);
        } else {
            newList[typeIndex].permissions.push({ id: 0, name: name });
        }

        setTypeList(newList);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        setCurrentType(value);
    };

    return (
        <Paper>
            {loading ?
                <LinearProgress />
                :
                <Collapse in={!loading}>
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item xs={6}>
                            <TextField
                                select
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={currentType}
                                onChange={handleChange}
                            >
                                {typeList.map(item => (
                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item>
                            <Button onClick={save} disabled={saving || list.length <= 0} color="secondary" variant="contained" aria-label="update permissions" startIcon={<SaveIcon />}>Save Changes</Button>
                        </Grid>
                        <PermissionList list={list} togglePermission={handleToggle} />
                    </Grid>
                </Collapse>
            }
        </Paper>
    );
}