import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DraftListViewmodel from '../Viewmodels/DraftListViewmodel';
import PermissionCheck from '../../Utilities/PermissionCheck';
import { Permission } from '../../Permissions/Permissions';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { ResponseType, TokenRefreshContext } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import CloseDialogConfirmation from '../../Utilities/CloseDialogConfimation';

interface IProps {
    assetId: number;
    row: DraftListViewmodel;
    refresh: () => void;
    userId: string;
}

export default function DraftsTableRow(props: IProps) {
    const { assetId, row, refresh, userId } = props;
    const navigate = useNavigate();
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);


    const discard = async () => {
        const token = await authService.getAccessToken();
        const controller = row.documentType === "WSE" ? "Wse" : "InspectionReport";

        await crabFetch(`${controller}/DiscardDraft?id=${row.id}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                refresh();
            },
            () => {
            }
        );
    }

    const view = () => {
        if (row.documentType === 'WSE') {
            navigate(
                `/Wse/${assetId}/${row.id}`
            );
        } else {
            navigate(
                `/InspectionReport/${assetId}/${row.id}`
            );
        }
    }


    const close = (refresh: boolean) => {
        setOpenConfirmation(false);
        if (refresh) {
            discard();
        }
    }

    return (
        <TableRow>
            <TableCell>{row.documentType}</TableCell>
            <TableCell>{row.reference}</TableCell>
            <TableCell>{row.status}</TableCell>
            <TableCell>{row.lastChangedBy}</TableCell>
            <TableCell>{row.owner}</TableCell>
            <TableCell align="right">
                {row.status === 'Draft' &&
                    <Grid container spacing={2} justifyContent="flex-end">
                        <PermissionCheck permission={Permission.DeleteDrafts}>
                            {row.draftedById === userId &&
                                <Grid item>
                                    <Button variant="outlined" onClick={() => setOpenConfirmation(true)}>Discard Draft</Button>
                                </Grid>
                            }
                        </PermissionCheck>
                        <PermissionCheck permission={row.documentType === 'WSE' ? [Permission.CreateWse, Permission.SignOffWse] : [Permission.CreateReport, Permission.SignOffReport]}>
                            {row.draftedById === userId ?
                                <Grid item>
                                    <Button variant="contained" onClick={view}>Continue</Button>
                                </Grid>
                                :
                                <Grid item>
                                    <Button variant="contained" onClick={view}>View</Button>
                                </Grid>
                            }
                        </PermissionCheck>
                    </Grid>
                }
                {row.status !== 'Draft' &&
                    <PermissionCheck permission={[Permission.ViewDocs, row.documentType === 'WSE' ? Permission.SignOffWse : Permission.SignOffReport, row.documentType === 'WSE' ? Permission.CreateWse : Permission.CreateReport, Permission.AcknowledgeDocument, Permission.SignOffEscalatedDocument]}>
                        <Button variant="contained" onClick={view}>View</Button>
                    </PermissionCheck>
                }
            </TableCell>
            <CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'Are you sure you want to discard ' + row.reference + ' ?'} open={openConfirmation} onClose={close}></CloseDialogConfirmation>
        </TableRow>
    );
}