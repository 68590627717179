import * as React from 'react';
import PermissionsListViewModel from './Viewmodels/PermissionsListViewModel';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import List from '@mui/material/List';
import Checkbox from '@mui/material/Checkbox';
import ListSubheader from '@mui/material/ListSubheader';
import Grid from '@mui/material/Grid';

interface IProps {
    list: PermissionsListViewModel[];
    togglePermission?: (index: number) => void;
    readonly?: boolean;
}

export default function PermissionList(props: IProps) {
    const { list, togglePermission, readonly } = props;

    const handleToggle = (index: number) => () => {
        if (togglePermission) {
            togglePermission(index);
        }
    };

    const groupedList = React.useMemo(() => {
        return Array.from(list.reduce((groups, item) =>
            groups.set(item.group, [...groups.get(item.group) || [], item])
            , new Map()));
    }, [list]);

    return (
        <Grid item xs={12}>
            {groupedList.map((item, index) => {
                return <List key={index} subheader={<ListSubheader>{item[0]}</ListSubheader>}>
                    {item[1].map((subItem: PermissionsListViewModel, subIndex: number) =>
                        <ListItem key={subItem.group + subIndex}>
                            <ListItemText primary={subItem.action} />
                            <ListItemSecondaryAction>
                                <Checkbox
                                    edge="end"
                                    onChange={handleToggle(subItem.id)}
                                    checked={subItem.enabled}
                                    readOnly={readonly && readonly === true}
                                    disableRipple={readonly && readonly === true}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    )}
                </List>;
            })}
        </Grid>
    );
}