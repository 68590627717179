import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from 'react';

interface IProps {
    confirmClosureMessage: string;
    preventClosureMessage: string;
    title: string;
    open: boolean;
    onClose: (refresh: boolean) => void;
}

export default function CloseDialogConfirmation(props: IProps) {
    const { title, confirmClosureMessage, preventClosureMessage, open, onClose } = props;

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="xs"
            fullWidth
        >
            <DialogContent>
                <Grid container>
                    <Typography>{title}</Typography>
                </Grid>
                <DialogActions>
                    <Button onClick={() => onClose(true)}>{confirmClosureMessage}</Button>
                    <Button onClick={() => onClose(false)} variant="contained">{preventClosureMessage}</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}