import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import PermissionCheck from '../../Utilities/PermissionCheck';
import { Permission } from '../../Permissions/Permissions';
import Button from '@mui/material/Button';
import { ResponseType, TokenRefreshContext } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import ActionViewmodel from '../Viewmodels/ActionViewmodel';
import ChangeDialog from '../../Utilities/ChangeDialog';

interface IProps {
    row: ActionViewmodel;
    refresh: () => void;
}

export default function ActionsTableRow(props: IProps) {
    const { row, refresh } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [outcome, setOutcome] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const markComplete = async () => {
        const token = await authService.getAccessToken();

        await crabFetch(`Action/MarkComplete?id=${row.id}&outcome=${outcome}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                refresh();
            },
            () => {
            }
        );
    }

    return (
        <TableRow>
            <TableCell>{row.type}</TableCell>
            <TableCell>{row.description}</TableCell>
            <TableCell>{new Date(row.date).toLocaleDateString('en-gb')}</TableCell>
            <TableCell>{row.complete ? 'Completed' : 'Outstanding'}</TableCell>
            <TableCell>{row.outcome}</TableCell>
            <TableCell align="right">
                {!row.complete &&
                    <PermissionCheck permission={Permission.CreateReport}>
                        <Button variant="contained" onClick={() => setOpen(true)}>Mark as Complete</Button>
                    </PermissionCheck>
                }
            </TableCell>
            <ChangeDialog
                buttonText='Save'
                text='Mark action as complete'
                open={open}
                close={() => { setOpen(false); setOutcome(''); }}
                changeDescription={outcome}
                setDescription={setOutcome}
                okClick={markComplete}
                placeholder="Please provide details."
            />
        </TableRow>
    );
}