import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { Divider, TextField, Typography } from '@mui/material';
import CredibleDamageViewModel from './Viewmodels/CredibleDamageViewModel';
import CustomCollapse from '../Utilities/CustomCollapse';
import SubmitInspectionsAndPreparationsViewModel from './Viewmodels/SubmitInspectionsAndPreparationsViewModel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ReactQuill from 'react-quill';
import { formats, modules } from '../Utilities/RichTextConfig';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface IProps {
    wseId: number;
    setInspections: (history: SubmitInspectionsAndPreparationsViewModel) => void;
    inspections: SubmitInspectionsAndPreparationsViewModel;
    setDamageMechanisms: (history: CredibleDamageViewModel[]) => void;
    damageMechanisms: CredibleDamageViewModel[];
    disableFields: boolean;
}

export default function InspectionAndPreparation(props: IProps) {
    const { wseId, setInspections, inspections, setDamageMechanisms, damageMechanisms, disableFields } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [loading, setLoading] = React.useState(false);
    const [collapse, setCollapse] = React.useState(false);

    React.useEffect(() => {
        if (wseId > 0)
            getData();
    }, [wseId]);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        await crabFetch(`Wse/GetWseInspectionPreparation?wseId=${wseId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: SubmitInspectionsAndPreparationsViewModel) => {
                setInspections(data);
                setLoading(false);
            }
        );
    }

    const updateDamageMechanismLocation = (index: number, e: any) => {
        var newArr = [...damageMechanisms];

        newArr[index]['location'] = e.target.value;

        setDamageMechanisms(newArr);
    }

    const updateDamageMechanismPreparation = (index: number, e: any) => {
        var newArr = [...damageMechanisms];

        newArr[index][`preparation`] = e.target.value;

        setDamageMechanisms(newArr);
    }

    const updateDamageMechanismInspectionRequirement = (index: number, e: any) => {
        var newArr = [...damageMechanisms];

        newArr[index]['inspectionRequirement'] = e.target.value;

        setDamageMechanisms(newArr);
    }

    const handleDate = (e: any) => {
        setInspections({
            ...inspections,
            dueDate: e === null ? null : e._d
        })
    }

    const handleChange = (e: any) => {
        setInspections({
            ...inspections,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Paper sx={{ padding: 2 }}>
            <CustomCollapse showMore={collapse} setShowMore={setCollapse} title={'Inspection & Preparation'} >
                <Grid container spacing={1} sx={{ pb: 2 }}>
                    <Grid item xs={12}>
                        <Typography variant='h2'>Thorough Examination Requirments</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h5'>The examination shall include a careful and critical scrutiny of those parts of the equipment that are exposed and accessible (as defined below), as well the insulation for water ingress, poor sealing and deterioration. All surfaces exposed by the following dismantling shall be clean and well lit.</Typography>
                    </Grid>
                </Grid>
                {!loading && damageMechanisms.filter(f => f.status).length > 0 &&
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Typography>Damage Mechanism</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>Location</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>Preparation</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>Inspection Requirement</Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} sx={{ pt: 1 }}>
                            {damageMechanisms
                                .map((credibleDamage: CredibleDamageViewModel, index: number) => (
                                    <Grid container item spacing={2} sx={{ pb: 1 }}>
                                        {credibleDamage.status &&
                                            <>
                                                <Grid item xs={3}>
                                                    <Typography>{credibleDamage.name}</Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField multiline disabled={disableFields} onChange={(e) => updateDamageMechanismLocation(index, e)} defaultValue={credibleDamage.location} name="location" fullWidth size="small" />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField multiline disabled={disableFields} onChange={(e) => updateDamageMechanismPreparation(index, e)} defaultValue={credibleDamage.preparation} name="preparation" fullWidth size="small" />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField multiline disabled={disableFields} onChange={(e) => updateDamageMechanismInspectionRequirement(index, e)} defaultValue={credibleDamage.inspectionRequirement} name="inspectionRequirement" fullWidth size="small" />
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                ))}
                        </Grid>
                    </>
                }
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant='h3'>Notes</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {!loading &&
                            <ReactQuill
                                modules={modules}
                                formats={formats}
                                onChange={(e) => {
                                    if (!loading) {
                                        setInspections({ ...inspections, ["notes"]: e })
                                    }
                                }}
                                readOnly={disableFields}
                                value={inspections.notes}
                            />
                        }

                    </Grid>
                    <Grid item container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant='h3'>Interval (months)</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField disabled={disableFields} name="interval" type="number" onChange={handleChange} value={inspections.interval} size="small" fullWidth></TextField>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container spacing={1} sx={{ pb: 2, pt: 2 }}>
                    <Grid item xs={12}>
                        <Typography variant='h2'>Intermediate Inspection Requirements</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h5'>The examination shall include a careful and critical scrutiny of those parts of the equipment that are exposed and accessible (as defined below), as well the insulation for water ingress, poor sealing and deterioration. All surfaces exposed by the following dismantling shall be clean and well lit.</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant='h3'>Requirements for Intermediate 'A' Inspection</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ReactQuill
                                modules={modules}
                                formats={formats}
                                onChange={(e) => setInspections({ ...inspections, ["aInspection"]: e })}
                                readOnly={disableFields}
                                value={inspections.aInspection}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h3'>'A' Inspection Interval (months)</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField disabled={disableFields} fullWidth type="number" onChange={handleChange} value={inspections.aInterval} name="aInterval" size="small"></TextField>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant='h3'>Requirements for Intermediate 'B' Inspection</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ReactQuill
                                modules={modules}
                                formats={formats}
                                onChange={(e) => setInspections({ ...inspections, ["bInspection"]: e })}
                                readOnly={disableFields}
                                value={inspections.bInspection}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h3'>'B' Inspection Interval (months)</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField disabled={disableFields} size="small" type="number" onChange={handleChange} value={inspections.bInterval} name="bInterval" fullWidth></TextField>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant='h3'>Requirements for one-off inspection</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ReactQuill
                                modules={modules}
                                formats={formats}
                                onChange={(e) => setInspections({ ...inspections, ["oneOffInspection"]: e })}
                                readOnly={disableFields}
                                value={inspections.oneOffInspection}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h3'>Due Date (One-off inspection only)</Typography>
                        </Grid>
                        <Grid item xs={4}>

                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="enGB">
                                <DatePicker

                                    disabled={disableFields}
                                    componentsProps={{
                                        actionBar: { actions: ['clear'] },
                                    }}
                                    onChange={handleDate} value={inspections.dueDate} renderInput={(params) => <TextField {...params} />}

                                ></DatePicker>
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Grid>
            </CustomCollapse>
        </Paper>
    );
}