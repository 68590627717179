import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import { ResponseType, TokenRefreshContext } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import { AlertContext } from '../../Contexts/AlertContext';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import PermissionCheck from '../../Utilities/PermissionCheck';
import { Permission } from '../../Permissions/Permissions';
import Collapse from '@mui/material/Collapse';
import ViewSafetyDevice from './ViewSafetyDevice';
import SafetyDeviceViewmodel from '../Viewmodels/SafetyDeviceViewmodel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ChangeDialog from '../../Utilities/ChangeDialog';

interface IProps {
    row: SafetyDeviceViewmodel;
    edit: (id: number) => void;
    refresh: () => void;
    showButton: boolean;
}

export default function SafetyDeviceTableRow(props: IProps) {
    const { row, edit, refresh, showButton } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openArchive, setOpenArchive] = React.useState(false);
    const [archiveDesc, setArchiveDesc] = React.useState("");

    const archive = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        await crabFetch(`SafetyDevice/ArchiveSafetyDevice?id=${row.id}&changeDescription=${archiveDesc}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully removed safety device");
                    refresh();
                }

                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset !important', pt: '8px !important', pb: '8px !important' } }}>
                <TableCell sx={{ backgroundColor: '#fff !important', width: 64 }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell sx={{ backgroundColor: '#fff !important' }}><div style={{ borderRadius: '10px', padding: 16, backgroundColor: '#F2F4F8' }}>{row.name}</div></TableCell>
                <PermissionCheck permission={Permission.CreateAssets}>
                    <TableCell align="right" sx={{ backgroundColor: '#fff !important', width: 240 }}>
                        {showButton &&
                            <>
                                <Button variant="contained" size="small" onClick={() => edit(row.id!)} disabled={loading} sx={{ mr: 2 }}>Edit Safety Device</Button>
                                <IconButton size="small" onClick={() => setOpenArchive(true)} disabled={loading}><DeleteIcon /></IconButton>

                            </>
                        }
                    </TableCell>
                </PermissionCheck>
            </TableRow>
            <TableRow>
                <TableCell colSpan={3} sx={{ pt: 0, pb: 0 }}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <ViewSafetyDevice safetyDevice={row} />
                    </Collapse>
                </TableCell>
            </TableRow>
            <ChangeDialog
                okClick={archive}
                open={openArchive}
                close={() => {
                    setOpenArchive(false);
                    setArchiveDesc("");
                }}
                changeDescription={archiveDesc}
                setDescription={setArchiveDesc}
                buttonText="Remove"
                text="Remove Safety Device"
                placeholder="Please provide a description and reasons for this change."
            />
        </React.Fragment>
    );
}