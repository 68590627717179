import * as React from 'react';
import { DatePickerElement, FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import { AlertContext } from '../../Contexts/AlertContext';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import DropdownViewModel from '../../Utilities/ViewModels/DropdownViewModel';
import CircularProgress from '@mui/material/CircularProgress';
import CloseDialogConfirmation from '../../Utilities/CloseDialogConfimation';
import CreateStorage from './CreateStorage';
import CreateStructure from './CreateStructure';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/en-gb';
import Typography from '@mui/material/Typography';
import AssetViewmodel from '../Viewmodels/AssetViewmodel';
import AssetDetailViewmodel from '../Viewmodels/AssetDetailViewmodel';
import PermissionCheck from '../../Utilities/PermissionCheck';
import { Permission } from '../../Permissions/Permissions';
import ChangeHistoryTable from './ChangeHistoryTable';
import CustomCollapse from '../../Utilities/CustomCollapse';

interface IProps {
    asset: AssetViewmodel;
    showButtons: boolean;
    assetDetailId?: number;
}

export default function AssetDetails(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { asset, assetDetailId, showButtons } = props;
    const [loading, setLoading] = React.useState(false);
    const [assetDetails, setAssetDetails] = React.useState(new AssetDetailViewmodel());
    const [assetTypes, setAssetTypes] = React.useState<DropdownViewModel[]>([]);
    const [equipmentTypes, setEquipmentTypes] = React.useState<DropdownViewModel[]>([]);
    const [editing, setEditing] = React.useState(false);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [showMore, setShowMore] = React.useState(false);
    const [openLog, setOpenLog] = React.useState(false);

    const formContext = useForm<AssetDetailViewmodel>({
        defaultValues: new AssetDetailViewmodel()
    });

    const { reset, watch } = formContext;
    const watchAssetType = watch('assetTypeId', assetDetails.assetTypeId);

    React.useEffect(() => {
        if (asset.id > 0) {
            getAsset();
            getDropdowns();
        }
    }, [asset]);

    React.useEffect(() => {
        if (assetDetailId && assetDetailId > 0)
            getAsset();
    }, [assetDetailId])

    React.useEffect(() => {
        const details = { ...assetDetails };

        // Deal with null value in case of bad data
        if (details.assetTypeId === 6)
            details.equipmentType = details.equipmentType ?? 0;

        reset(details);
    }, [assetDetails]);

    const getAsset = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Asset/GetAssetDetails?asset=${asset.id}&assetType=${asset.assetType}&assetDetailsId=${assetDetailId ? assetDetailId : 0}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: AssetDetailViewmodel) => {
                setLoading(false);
                setAssetDetails(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const getDropdowns = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Asset/GetAssetTypesDropDown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setAssetTypes(data);
            },
            () => {
                setLoading(false);
            }
        );

        crabFetch(`Base/GetDropdown?lookup=EquipmentType`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setEquipmentTypes(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const submit = async (form: any, event?: React.BaseSyntheticEvent) => {
        // prevent nested form submit
        if (event && (event.target.id === 'createComponent' || event.target.id === 'createDevice'))
            return;

        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch('Asset/EditAsset', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully updated asset");
                    getAsset();
                    setEditing(false);
                }

                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    const closeDialogs = () => {
        setEditing(false);
        setOpenConfirmation(false);
        reset(assetDetails);
    }

    return (
        <React.Fragment>
            <FormContainer
                formContext={formContext}
                onSuccess={submit}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item sx={{ flexGrow: 1 }}>
                                <Typography variant={showButtons ? "h2" : "h3"}>Asset Details</Typography>
                            </Grid>
                            {showButtons &&
                                <>
                                    {editing &&
                                        <PermissionCheck permission={Permission.CreateAssets}>
                                            <Grid item>
                                                <Button onClick={() => { setOpenConfirmation(true); setEditing(false); }} color="error" disabled={loading}>Cancel</Button>
                                            </Grid>
                                            <Grid item>
                                                <LoadingButton variant="contained" type="submit" disabled={loading} loading={loading}>{"Save Changes"}</LoadingButton>
                                            </Grid>
                                        </PermissionCheck>
                                    }
                                    {!editing &&
                                        <>
                                            <Grid item>
                                                <Button variant="contained" onClick={() => setOpenLog(true)}>View Details Change Log</Button>
                                            </Grid>
                                            <PermissionCheck permission={Permission.CreateAssets}>
                                                <Grid item>
                                                    <Button variant="contained" onClick={() => setEditing(true)} disabled={editing}>Edit Asset Details</Button>
                                                </Grid>
                                            </PermissionCheck>
                                        </>
                                    }
                                </>
                            }

                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputLabel htmlFor="client" shrink>Client</InputLabel>
                        <TextFieldElement name="client" required fullWidth size="small" disabled value={assetDetails.client} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputLabel htmlFor="plant" shrink>Plant</InputLabel>
                        <TextFieldElement name="plant" required fullWidth size="small" disabled value={assetDetails.plant} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputLabel htmlFor="assetTypeId" shrink>Asset Type</InputLabel>
                        <SelectElement
                            name="assetTypeId"
                            required
                            fullWidth
                            size="small"
                            options={assetTypes}
                            InputProps={{
                                disabled: true,
                                startAdornment: (
                                    !loading ? null : <CircularProgress size={20} />
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputLabel htmlFor="plantItemNumber" shrink>Plant Item Number</InputLabel>
                        <TextFieldElement name="plantItemNumber" required fullWidth size="small" disabled={true} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputLabel htmlFor="serialNumber" shrink>Serial No.</InputLabel>
                        <TextFieldElement name="serialNumber" required fullWidth size="small" disabled={!editing} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputLabel htmlFor="designCode" shrink>Design Code</InputLabel>
                        <TextFieldElement name="designCode" required fullWidth size="small" disabled={!editing} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputLabel htmlFor="dateOfFabrication" shrink>Commissioning Date</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="enGB" >
                            <DatePickerElement name="dateOfFabrication" required InputProps={{ fullWidth: true, size: "small", disabled: !editing }} readOnly={!editing} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="description" shrink>Description</InputLabel>
                        <TextFieldElement name="description" required fullWidth size="small" disabled={!editing} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="service" shrink>Service</InputLabel>
                        <TextFieldElement name="service" required fullWidth size="small" disabled={!editing} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="pids" shrink>P&ID's / Drawings</InputLabel>
                        <TextFieldElement name="pids" required fullWidth size="small" disabled={!editing} />
                    </Grid>
                    {(watchAssetType === 1 || watchAssetType === 2 || watchAssetType === 3 || watchAssetType === 6) &&
                        <Grid item xs={12} md={4}>
                            <InputLabel htmlFor="pssr" shrink>PSSR</InputLabel>
                            <SelectElement
                                name="pssr"
                                fullWidth
                                size="small"
                                options={[
                                    { id: true, label: "Yes" },
                                    { id: false, label: "No" }
                                ]}
                                InputProps={{
                                    disabled: loading || !editing,
                                    startAdornment: (
                                        !loading ? null : <CircularProgress size={20} />
                                    )
                                }}
                            />
                        </Grid>
                    }
                    {(watchAssetType === 1 || watchAssetType === 2) &&
                        <Grid item xs={12} md={4}>
                            <InputLabel htmlFor="associatedReliefDevices" shrink>Associated Relief Devices</InputLabel>
                            <SelectElement
                                name="associatedReliefDevices"
                                fullWidth
                                size="small"
                                options={[
                                    { id: true, label: "Yes" },
                                    { id: false, label: "No" }
                                ]}
                                InputProps={{
                                    disabled: loading || !editing,
                                    startAdornment: (
                                        !loading ? null : <CircularProgress size={20} />
                                    )
                                }}
                            />
                        </Grid>
                    }

                    {/* 6 = relief system */}
                    {watchAssetType === 6 &&
                        <Grid item xs={12} md={4}>
                            <InputLabel htmlFor="equipmentType" shrink>Equipment Type</InputLabel>
                            <SelectElement
                                name="equipmentType"
                                required
                                fullWidth
                                size="small"
                                options={equipmentTypes}
                                InputProps={{
                                    disabled: loading || !editing,
                                    startAdornment: (
                                        !loading ? null : <CircularProgress size={20} />
                                    )
                                }}
                            />
                        </Grid>
                    }
                    {/* 6 = relief system */}
                    {editing &&
                        <Grid item xs={12}>
                            <InputLabel htmlFor="changeDescription" shrink>Description & Reasons for Change</InputLabel>
                            <TextFieldElement name="changeDescription" required fullWidth size="small" disabled={!editing} />
                        </Grid>
                    }
                    {(watchAssetType !== 5 && !((watchAssetType !== 3 && watchAssetType !== 4))) &&
                        <CustomCollapse showMore={showMore} setShowMore={setShowMore} title="Equipment Data">
                            <Grid container spacing={2}>
                                {/* 3 = storage tank */}
                                {watchAssetType === 3 &&
                                    <CreateStorage editing={editing} />
                                }
                                {/* 4 = structure */}
                                {watchAssetType === 4 &&
                                    <CreateStructure editing={editing} />
                                }
                            </Grid>
                        </CustomCollapse>
                    }
                </Grid>
            </FormContainer>
            <CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'Are you sure you want to discard any changes?'} open={openConfirmation} onClose={closeDialogs} />
            {openLog && <ChangeHistoryTable open={openLog} onClose={() => setOpenLog(false)} assetId={assetDetails.assetId} />}
        </React.Fragment>
    );
}