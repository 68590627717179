import * as React from 'react';
import { TextFieldElement } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';

interface IProps {
    assetType: number;
    editing: boolean;
}

export default function CreatePressureComponent(props: IProps) {
    const { assetType, editing } = props;

    return (
        <React.Fragment>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="material" shrink>Material</InputLabel>
                <TextFieldElement name="material" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="nominalThickness" shrink>Nominal Thickness</InputLabel>
                <TextFieldElement name="nominalThickness" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="designPressure" shrink>Design Pressure</InputLabel>
                <TextFieldElement name="designPressure" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="designTemperatureRange" shrink>Design Temperature</InputLabel>
                <TextFieldElement name="designTemperatureRange" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="fluidService" shrink>Fluid Service</InputLabel>
                <TextFieldElement name="fluidService" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="operatingPressure" shrink>Operating Pressure</InputLabel>
                <TextFieldElement name="operatingPressure" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="operatingTemperature" shrink>Operating Temperature</InputLabel>
                <TextFieldElement name="operatingTemperature" required fullWidth size="small" disabled={!editing} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel htmlFor="corrosionAllowance" shrink>Corrosion Allowance</InputLabel>
                <TextFieldElement name="corrosionAllowance" required fullWidth size="small" disabled={!editing} />
            </Grid>
            {/* pressure vessel */}
            {assetType === 1 &&
                <Grid item xs={12} md={4}>
                    <InputLabel htmlFor="otherComments" shrink>Other Comments</InputLabel>
                    <TextFieldElement name="otherComments" required fullWidth size="small" disabled={!editing} />
                </Grid>
            }
        </React.Fragment>
    );
}