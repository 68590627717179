import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import InspectionViewmodel from '../Viewmodels/InspectionViewmodel';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import { AlertColours } from '../../Utilities/AlertColours';
import PermissionCheck from '../../Utilities/PermissionCheck';
import { Permission } from '../../Permissions/Permissions';
import Button from '@mui/material/Button';
import CloseDialogConfirmation from '../../Utilities/CloseDialogConfimation';
import { ResponseType, TokenRefreshContext } from '../../Contexts/TokenRefreshContext';
import { AlertContext } from '../../Contexts/AlertContext';
import authService from '../../api-authorization/AuthorizeService';

interface IProps {
    row: InspectionViewmodel;
    amberAlertDays: number;
    selectRow: (id: number) => void;
    isSelected: boolean;
    refresh: () => void;
}

export default function InspectionsTableRow(props: IProps) {
    const { row, amberAlertDays, selectRow, isSelected, refresh } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);

    const chipColour = React.useMemo(() => {
        var date = new Date(row.dueDate);
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        var alertDate = new Date(today);
        alertDate.setDate(alertDate.getDate() + amberAlertDays);

        if ((date >= today && date >= alertDate) || row.status === 'Reporting')
            return AlertColours.low;
        else if (date <= alertDate && date >= today)
            return AlertColours.medium;
        else
            return AlertColours.high;
    }, [row.dueDate, amberAlertDays]);

    const archive = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Inspection/ArchiveInspection?id=${row.id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully archived inspection`);
                    refresh();
                }
            },
            (error: any) => {
                show('error', error);
            }
        );
    }

    const close = (refresh: boolean) => {
        setOpenConfirmation(false);

        if (refresh) {
            archive();
        }
    }

    return (
        <TableRow
            hover
            onClick={row.status === "Reporting" ? undefined : (event) => selectRow(row.id)}
            role="checkbox"
            sx={{ cursor: 'pointer' }}
            selected={isSelected}
        >
            <TableCell padding="checkbox">
                <PermissionCheck permission={Permission.CreateReport}>
                    {row.status !== "Reporting" &&
                        <Checkbox
                            color="primary"
                            checked={isSelected}
                        />
                    }
                </PermissionCheck>
            </TableCell>
            <TableCell><Chip label={new Date(row.dueDate).toLocaleDateString('en-gb')} sx={{ backgroundColor: chipColour }} /></TableCell>
            <TableCell>{row.inspectionType}</TableCell>
            <TableCell>{row.status}</TableCell>
            <TableCell align="right">
                <PermissionCheck permission={Permission.DeleteClients}>
                    <Button variant="contained" size="small" onClick={() => setOpenConfirmation(true)}>Archive</Button>
                </PermissionCheck>
            </TableCell>
            <CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={`Are you sure you want to archive this ${row.inspectionType}?`} open={openConfirmation} onClose={close} />
        </TableRow>
    );
}