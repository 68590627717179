import * as React from 'react';
import Grid from '@mui/material/Grid';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import SignOffViewmodel, { ApprovalStatus } from './Viewmodels/SignOffViewmodel';
import CustomCollapse from '../Utilities/CustomCollapse';
import Typography from '@mui/material/Typography';
import Compiler from './Compiler';
import Certify from './Certify';
import Acknowledge from './Acknowledge';
import Paper from '@mui/material/Paper';

export enum SignOffDocType {
    Wse,
    InspectionReport
}

interface IProps {
    type: SignOffDocType;
    id: number;
    canEdit?: (editable: boolean) => void;
    updatewseSaveButton?: () => void;
    saveReport: () => void;
    ownerId: string;
}

export default function SignOff(props: IProps) {
    const { type, id, canEdit, updatewseSaveButton, saveReport, ownerId } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [signOff, setSignOff] = React.useState(new SignOffViewmodel());
    const [loading, setLoading] = React.useState(false);
    const [userId, setUserId] = React.useState('');
    const [showMore, setShowMore] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, [id]);

    React.useEffect(() => {
        if (canEdit) {
            canEdit(signOff.compiledBy.length <= 0 && (signOff.certifiedStatus === null || signOff.certifiedStatus === ApprovalStatus.Rejected || signOff.certifiedStatus === ApprovalStatus.None));
        }
        if (updatewseSaveButton) {
            updatewseSaveButton()
        }
    }, [signOff]);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        setUserId(user.sub);
  

        crabFetch(`${SignOffDocType[type]}/GetSignOff?id=${id}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data) => {
                setLoading(false);
                setSignOff(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    return (
        <Paper>
            <CustomCollapse showMore={showMore && !loading} setShowMore={setShowMore} title="Approval">
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={12}>
                        <Typography>Approval & Acceptance</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Compiler signOff={signOff} refresh={getData} saveReport={saveReport} canCompile={userId === ownerId} />
                    </Grid>
                    {signOff.compiledBy.length > 0 &&
                        <Grid item xs={12}>
                            <Certify signOff={signOff} refresh={getData} wseId={id} />
                        </Grid>
                    }
                    {(signOff.certifiedStatus === ApprovalStatus.Approved) &&
                        <Grid item xs={12}>
                            <Acknowledge signOff={signOff} refresh={getData} />
                        </Grid>
                    }
                </Grid>
            </CustomCollapse>
        </Paper>
    );
}