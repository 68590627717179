export default class SignOffViewmodel {
    public id: number = 0;
    public docType: string = '';

    public compiledBy: string = '';
    public compiledSig: string = '';
    public compiledDate: Date = new Date();

    public certifiedById: string = '';
    public certifiedBy: string = '';
    public certifiedSig: string = '';
    public certifiedDate: Date = new Date();
    public certifiedStatus: ApprovalStatus | null = null;
    public reason: string | null = null;

    public acknowledgedBy: string = '';
    public acknowledgedSig: string = '';
    public acknowledgedDate: Date = new Date();
    public ableClients: string[] = [];
}

export enum ApprovalStatus {
    "Approved",
    "Rejected",
    "Escalated",
    "None"
}