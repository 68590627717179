import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import ManageRolePermissions from '../Permissions/ManageRolePermissions';
import PermissionCheck from '../Utilities/PermissionCheck';

export default function UserTypePermissions() {
    return (
        <PermissionCheck userType="Admin">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">Manage User Type Permissions</Typography>
                </Grid>
                <Grid item xs={8}>
                    <ManageRolePermissions />
                </Grid>
            </Grid>
        </PermissionCheck>
    );
}