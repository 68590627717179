export default class ComponentViewmodel {
    public id: number | null = null;
    public assetDetailId: number = 0;
    public assetType: number = 0;
    public name: string = '';
    public material: string | undefined = undefined;
    public nominalThickness: string | undefined = undefined;
    public designPressure: string | undefined = undefined;
    public designTemperatureRange: string | undefined = undefined;
    public fluidService: string | undefined = undefined;
    public operatingPressure: string | undefined = undefined;
    public operatingTemperature: string | undefined = undefined;
    public corrosionAllowance: string | undefined = undefined;

    public changeDescription: string | undefined = undefined;

    // pressure vessel
    public otherComments: string | undefined = undefined;

    // pressure piping
    public pipeNominalDiameter: string | undefined = undefined;

    // relief system
    public manufacturer: string | undefined = undefined;
    public model: string | undefined = undefined;
    public serialNumber: string | undefined = undefined;
    public conventionalBellowsPilot: string | undefined = undefined;
    public safetyReliefThermal: string | undefined = undefined;
    public inletDiameter: string | undefined = undefined;
    public outletDiameter: string | undefined = undefined;
    public setPressure: string | undefined = undefined;
    public lineVesselNumber: string | undefined = undefined;
    public service: string | undefined = undefined;
    public reliefFrom: string | undefined = undefined;
    public reliefTo: string | undefined = undefined;
    public equipmentProtected: string | undefined = undefined;
}