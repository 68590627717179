import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from 'react';
import DraftsViewmodel from "./ViewModels/DraftsViewModel";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import PermissionCheck from "../Utilities/PermissionCheck";
import { Permission } from "../Permissions/Permissions";
import { useNavigate } from "react-router-dom";
import { ResponseType, TokenRefreshContext } from "../Contexts/TokenRefreshContext";
import authService from "../api-authorization/AuthorizeService";
import CloseDialogConfirmation from "../Utilities/CloseDialogConfimation";

interface IProps {
    row: DraftsViewmodel;
    refresh: () => void;
    userId: string;
}
export default function DraftsTableRow(props: IProps) {
    const navigate = useNavigate();
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { row, refresh, userId } = props;
    const [openConfirmation, setOpenConfirmation] = React.useState(false);

    const discard = async () => {
        const token = await authService.getAccessToken();
        const controller = row.documentType === "WSE" ? "Wse" : "InspectionReport";
        await crabFetch(`${controller}/DiscardDraft?id=${row.id}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                refresh();
            },
            () => {
            }
        );
    }

    const view = () => {
        if (row.documentType === 'WSE') {
            navigate(
                `/Wse/${row.assetId}/${row.id}`
            );
        } else {
            navigate(
                `/InspectionReport/${row.assetId}/${row.id}`
            );
        }
    }

    const close = (refresh: boolean) => {
        setOpenConfirmation(false);
        if (refresh) {
            discard();
        }
    }

    return (
        <TableRow>
            <TableCell>{row.asset}</TableCell>
            <TableCell>{row.documentType}</TableCell>
            <TableCell>{row.reference}</TableCell>
            <TableCell>{row.status}</TableCell>
            <TableCell>{row.assetType}</TableCell>
            <TableCell>{row.description}</TableCell>
            <TableCell>{row.client}</TableCell>
            <TableCell>{row.owner}</TableCell>
            <TableCell align="right">
                {row.status === 'Draft' &&
                    <Grid container spacing={2} justifyContent="flex-end">
                        <PermissionCheck permission={Permission.DeleteDrafts}>
                            {row.draftedById === userId &&
                                < Grid item>
                                    <Button variant="outlined" onClick={() => setOpenConfirmation(true)}>Discard Draft</Button>
                                </Grid>
                            }
                        </PermissionCheck>
                        <PermissionCheck permission={row.documentType === 'WSE' ? [Permission.CreateWse, Permission.SignOffWse] : [Permission.CreateReport, Permission.SignOffReport]}>
                            <Grid item>
                                <Button variant="contained" onClick={view}>Continue</Button>
                            </Grid>
                        </PermissionCheck>
                    </Grid>
                }
                {
                    row.status !== 'Draft' &&
                    <PermissionCheck permission={[Permission.ViewDocs, row.documentType === 'WSE' ? Permission.SignOffWse : Permission.SignOffReport, row.documentType === 'WSE' ? Permission.CreateWse : Permission.CreateReport, Permission.AcknowledgeDocument, Permission.SignOffEscalatedDocument]}>
                        <Button variant="contained" onClick={view}>View</Button>
                    </PermissionCheck>
                }
            </TableCell >
            <CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'Are you sure you want to discard ' + row.reference + ' ?'} open={openConfirmation} onClose={close}></CloseDialogConfirmation>
        </TableRow >
    );
}