import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, SelectElement, TextFieldElement, useForm } from 'react-hook-form-mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import { AlertContext } from '../Contexts/AlertContext';
import EditPlantViewModel from './Viewmodels/EditPlantViewModel';
import CloseDialogConfirmation from '../Utilities/CloseDialogConfimation';
import TextField from '@mui/material/TextField';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    id: number;
}

export default function EditPlant(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose, id } = props;
    const [loading, setLoading] = React.useState(false);
    const [plant, setPlant] = React.useState<EditPlantViewModel>(new EditPlantViewModel());
    const [openConfimation, setOpenConfimation] = React.useState(false);
    const [clientUsers, setClientUsers] = React.useState<DropdownViewModel[]>([]);
    const [clients, setClients] = React.useState<DropdownViewModel[]>([]);

    const formContext = useForm<EditPlantViewModel>({
        defaultValues: new EditPlantViewModel()
    });

    const { reset, watch } = formContext;

    const watchClientId = watch('clientId', 0);

    React.useEffect(() => {
        reset({
            id: plant.id,
            siteName: plant.siteName,
            siteAddress: plant.siteAddress,
            clientId: plant.clientId,
            name: plant.name,
            address: plant.address,
            deptEngineer: plant.deptEngineer ?? "",
            nomEngineer: plant.nomEngineer ?? ""
        });
    }, [plant]);

    React.useEffect(() => {
        if (id !== null) {
            getData()
        }
    }, [id]);

    React.useEffect(() => {
        if (watchClientId !== 0) {
            getClientUsers();
        }
    }, [watchClientId]);

    const getClientUsers = async () => {
        const token = await authService.getAccessToken();
        crabFetch(`User/GetClientsDropDown?clientId=${watchClientId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setLoading(false);
                setClientUsers(data);
            },
            () => {
                setLoading(false);
            });
    }

    const getData = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Plant/GetPlant?Id=${id!}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setLoading(false);
                setPlant(data);
            },
            () => {
                setLoading(false);
            });


        crabFetch(`Client/GetClientsDropDown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                //setLoading(false);
                setClients(data);
            },
            () => {
                //setLoading(false);
            });
    }

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();
        setLoading(true);

        let url = 'Plant/UpdatePlant';

        crabFetch(url, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully updated Plant");
                    onClose(true);
                    setLoading(false);
                 
                }
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
        setLoading(false);
    }

    const closeDialogs = (refresh: boolean) => {
        if (refresh)
            onClose(false);
        setOpenConfimation(false);
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="sm"
            fullWidth
        >
            <FormContainer
                onSuccess={submit}
                formContext={formContext}
            >
                <DialogTitle>{"Edit Plant"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid container item xs={12} spacing={2}>
                            {(watchClientId !== null && watchClientId !== 0 && clients.length > 0) &&
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="clientId" shrink>Client</InputLabel>
                                    <SelectElement disabled options={clients} name="clientId" required fullWidth size="small" />
                                </Grid>
                            }
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="name" shrink>Plant Name</InputLabel>
                                <TextFieldElement name="name" required fullWidth size="small" />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="address" shrink>Plant Address</InputLabel>
                                <TextFieldElement name="address" required fullWidth size="small" />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="siteName" shrink>Site Name</InputLabel>
                                <TextFieldElement name="siteName" required fullWidth size="small" />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="siteAddress" shrink>Site Address</InputLabel>
                                <TextFieldElement name="siteAddress" required fullWidth size="small" />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>

                            <Grid item xs={6}>
                                <InputLabel htmlFor="nomEngineer" shrink>Client Nominated Engineer</InputLabel>
                                {watchClientId === 0 || clientUsers.length! > 0 ?
                                    <SelectElement disabled={watchClientId === 0} options={clientUsers} name="nomEngineer" fullWidth size="small" />
                                    :
                                    <TextField disabled={true} name="nomEngineer" defaultValue="No Engineers Assigned To Client" fullWidth size="small" />
                                }
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="deptEngineer" shrink>Client Deputy Engineer</InputLabel>
                                {watchClientId === 0 || clientUsers.length! > 0 ?
                                    <SelectElement disabled={watchClientId === 0} options={clientUsers} name="deptEngineer" fullWidth size="small" />
                                    :
                                    <TextField disabled={true} name="deptEngineer" defaultValue="No Engineers Assigned To Client" fullWidth size="small" />
                                }

                            </Grid>
                        </Grid>

                    </Grid>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setOpenConfimation(true)} color="error">Cancel</Button>
                        <Button disabled={loading} variant="contained" type="submit">Save Plant</Button>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
            <CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'Are you sure you want to discard any changes?'} open={openConfimation} onClose={closeDialogs}></CloseDialogConfirmation>
        </Dialog>
    );
}