import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import Button from '@mui/material/Button';
import ChangePassword from './ChangePassword';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import EditUser from '../Users/User/EditUser';
import ProfileViewModel from './Viewmodels/ProfileViewModel';
import Box from '@mui/material/Box';


interface IProps {
    userId: string;
}

export default function ProfileDetails(props: IProps) {
    const { userId } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [user, setUser] = React.useState(new ProfileViewModel());
    const [loading, setLoading] = React.useState(true);
    const [openDialog, setOpenDialog] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        crabFetch(`Profile/GetProfile?id=${userId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setLoading(false);
                setUser(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const formContext = useForm<ProfileViewModel>({
        defaultValues: new ProfileViewModel()
    });

    const { reset } = formContext;

    React.useEffect(() => {
        reset({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            userType: user.userType,
            client: user.client,
            signature: user.signature
        });
    }, [user]);


    const closeDialog = async (refresh: boolean) => {
        if (refresh) getData();
        setOpenDialog(false);
    }

    const toggleEdit = () => setOpenDialog((prev) => !prev);

    const disabled = true;
    return (
        <>
            <Grid item sx={{pb:2} }>
                <Typography variant="h1">Profile</Typography>
            </Grid>
            <Paper>
                <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
                    {!loading &&
                        <Grid item>
                            <FormContainer formContext={formContext}>
                                <Grid container item spacing={2}>
                                    <Grid container spacing={2} item xs={12}>
                                        <Grid item xs={6}>
                                            <InputLabel htmlFor="firstName" shrink>Forename</InputLabel>
                                            <TextFieldElement disabled={disabled} defaultValue={user.firstName} name="firstName" required fullWidth size="small" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputLabel htmlFor="lastName" shrink>Surname</InputLabel>
                                            <TextFieldElement disabled={disabled} defaultValue={user.lastName} name="lastName" required fullWidth size="small" />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} item xs={12}>
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <InputLabel htmlFor="email" shrink>Email</InputLabel>
                                                <TextFieldElement disabled={disabled} defaultValue={user.email} name="email" required fullWidth size="small" />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputLabel htmlFor="phoneNumber" shrink>Phone Number</InputLabel>
                                            <TextFieldElement disabled={disabled} defaultValue={user.phoneNumber} name="phoneNumber" required fullWidth size="small" />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} item xs={12}>
                                        <Grid item xs={6}>
                                            <InputLabel htmlFor="userType" shrink>User Level</InputLabel>
                                            <TextFieldElement disabled={disabled} defaultValue={user.userType} name="userType" fullWidth size="small" />
                                        </Grid>
                                        {(user.client) &&
                                            <Grid item xs={6}>
                                                <InputLabel htmlFor="clientId" shrink>Client</InputLabel>
                                                <TextFieldElement disabled={disabled} defaultValue={user.client} name="client" fullWidth size="small" />
                                            </Grid>
                                        }
                                    </Grid>
                                    {user.signature.length > 0 &&
                                        <Grid container spacing={2} item xs={12}>
                                            <Grid item>
                                                <InputLabel htmlFor="signature" shrink>Signature</InputLabel>
                                                <Box sx={{ width: '100%', height: '75px' }}>
                                                    <img aria-label="certified by signature" style={{ height: '100%' }} src={"data:image/jpeg;base64," + user.signature} />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </FormContainer>
                           
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <ChangePassword userId={userId} />
                    </Grid>
                    <Grid item xs={12}>
                        <Button size="small" fullWidth variant="contained" onClick={toggleEdit}>Edit Details</Button>
                    </Grid>
                </Grid>
                <EditUser open={openDialog} onClose={closeDialog} userId={user.id}></EditUser>
            </Paper >
        </>

    );
}