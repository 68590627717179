import * as React from 'react';
import styled from '@emotion/styled';
import Table, { TableProps } from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';

const ScrollTable = styled("div")(() => ({
    display: "block",
    overflowX: "auto"
}));

export default function ResponsiveTable(props: TableProps) {

    return (
        <ScrollTable>
            <Table {...props} />
        </ScrollTable>
    );
}

export const SmallTableCell = styled(TableCell)`
        root: {
            padding: 8
        }`;